import { __assign } from "tslib";
import './index.scss';
import $i18n from 'panda-i18n';
import React from 'react';
import { friendlyTips } from '@/components/cn-utils';
import { CnDrawer } from '@/components/cn-drawer';
import { cssPrefix as _cssPrefix } from '../constant/constant';
import JobPanel from '../job-panel';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnResult } from '@/components/cn-result';
import { useCnAsyncJobsProContextState } from '../../hooks/use-cn-async-job-pro-context-state';
var cssPrefix = "".concat(_cssPrefix, "container");
export var JobContainer = function () {
    var _a, _b;
    var _c = useCnAsyncJobsProContextState(), panelVisible = _c.panelVisible, setPanelVisible = _c.setPanelVisible, panelTab = _c.panelTab, setPanelTab = _c.setPanelTab, exportPropsRef = _c.exportPropsRef, importPropsRef = _c.importPropsRef, exportResponse = _c.exportResponse, importResponse = _c.importResponse;
    var importProps = importPropsRef.current;
    var exportProps = exportPropsRef.current;
    if (!importProps && !exportProps) {
        friendlyTips({
            message: $i18n.get({
                id: 'PleaseCheckWhetherTheComponentConfiguration_802152393',
                dm: '请检查组件配置是否正确',
                ns: 'CnAsyncJobsPro',
            }),
            component: 'CnAsyncJobsPro',
        });
    }
    return (React.createElement(CnDrawer, { visible: panelVisible, onClose: function () {
            setPanelVisible(false);
        }, className: cssPrefix, hasMask: false, canCloseByOutSideClick: true, canCloseByEsc: false, embeddable: true },
        React.createElement("div", { className: "".concat(cssPrefix, "-close"), onClick: function () { return setPanelVisible(false); } },
            React.createElement(CnIcon, { type: "close", size: 16 })),
        !importProps && !exportProps ? (React.createElement(CnResult, { type: "empty", title: $i18n.get({
                id: 'NoTaskYet',
                dm: '暂无任务',
                ns: 'CnAsyncJobsPro',
            }) })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat(cssPrefix, "-header") },
                exportProps ? (React.createElement("div", { className: classNames("".concat(cssPrefix, "-header-tab"), (_a = {},
                        _a["".concat(cssPrefix, "-header-tab-selected")] = panelTab === 'export',
                        _a)), onClick: function () { return setPanelTab('export'); } }, exportProps.title ||
                    $i18n.get({
                        id: 'ExportTask',
                        dm: '导出任务',
                        ns: 'CnAsyncJobsPro',
                    }))) : null,
                importProps ? (React.createElement("div", { className: classNames("".concat(cssPrefix, "-header-tab"), (_b = {},
                        _b["".concat(cssPrefix, "-header-tab-selected")] = panelTab === 'import',
                        _b)), onClick: function () { return setPanelTab('import'); } }, importProps.title ||
                    $i18n.get({
                        id: 'ImportTask',
                        dm: '导入任务',
                        ns: 'CnAsyncJobsPro',
                    }))) : null),
            React.createElement("div", { className: "".concat(cssPrefix, "-content") },
                exportProps ? (React.createElement(JobPanel, __assign({ type: "export" }, exportProps, exportResponse))) : null,
                importProps ? (React.createElement(JobPanel, __assign({ type: "import" }, importProps, importResponse))) : null)))));
};
JobContainer.displayName = 'JobContainer';

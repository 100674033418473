import { __assign } from "tslib";
import React, { useCallback, useState, useRef, useEffect, } from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { sendLog } from '@/components/cn-utils';
import { isEmpty, } from '../../utils';
import { useFilterRefsContext } from '../../context';
import { getRenderItems } from './get-render-items';
import { TagItem } from './tag-item';
import { formatRenderItem } from './format-render-item';
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
export var FilterSelectedTags = function (props) {
    var filterSelectedTagsRef = React.useRef(null);
    var values = props.values, innerValues = props.innerValues, onRemove = props.onRemove, extend = props.extend;
    // 缓存上次计算的label，value不变时直接返回上次计算的有效label，当datasource变化时也能得到原先的label，以应对动态datasource的情况
    var selectedCacheRef = useRef(new Map());
    var _a = useState(0), update = _a[1];
    var filterContext = useFilterRefsContext();
    var filterItemColloction = (filterContext === null || filterContext === void 0 ? void 0 : filterContext.itemCollection) || {};
    useEffect(function () {
        filterContext.update = function () { return update(function (i) { return (i + 1) % 32; }); };
        return function () {
            filterContext.update = function () { return undefined; };
        };
    }, [filterContext]);
    filterContext.update = function () { return update(function (i) { return (i + 1) % 32; }); };
    Object.keys(__assign(__assign({}, values), innerValues)).forEach(function (key) {
        var targetValue = values === null || values === void 0 ? void 0 : values[key];
        var targetInnerValue = innerValues === null || innerValues === void 0 ? void 0 : innerValues[key];
        var value = [];
        if (Array.isArray(targetValue)) {
            value.push.apply(value, targetValue);
        }
        else {
            value.push(targetValue);
        }
        if (Array.isArray(targetInnerValue)) {
            value.push.apply(value, targetInnerValue);
        }
        else {
            value.push(targetInnerValue);
        }
        if (value.filter(function (val) { return !isEmpty(val); }).length === 0) {
            selectedCacheRef.current.delete(key);
        }
    });
    // cache innerValues text
    Object.entries(innerValues || {}).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (isEmpty(value) ||
            (Array.isArray(value) && !value.filter(function (val) { return !isEmpty(val); }).length)) {
            return;
        }
        formatRenderItem(key, value, filterItemColloction, selectedCacheRef.current);
    });
    var renderItems = getRenderItems(values, filterItemColloction, selectedCacheRef);
    var handleClose = useCallback(function (item) {
        sendLog({
            id: 'cn-ui.cn-filter.deleteSelectedTag',
            name: 'CnFilter标签删除点击',
        });
        onRemove && onRemove(item.key);
        return true;
    }, [onRemove]);
    if (!extend && renderItems.length === 0) {
        return null;
    }
    return (React.createElement("div", { className: "cn-ui-filter-selected-tags", ref: filterSelectedTagsRef },
        extend,
        renderItems.map(function (item, idx) { return (React.createElement(TagItem, { key: idx, item: item, handleClose: handleClose })); })));
};
FilterSelectedTags.displayName = 'FilterSelectedTags';
export default FilterSelectedTags;

import * as React from 'react';
/**
 * @description 嵌套的数组对象查找
 * @param o 查找对象
 * @param k key值
 * @param v value值
 * @returns 找到的对象值
 */
export var findDeep = function (o, k, v) {
    for (var i in o) {
        if (Object.prototype.hasOwnProperty.call(o, i)) {
            if (typeof o[i] === 'object') {
                var f = findDeep(o[i], k, v);
                if (f) {
                    return f;
                }
            }
            if (k === i && o[i] === v) {
                return o;
            }
        }
    }
};
/**
 * @description 获取全选的树节点
 * @param c 树子节点
 * @returns 所有需要选中的节点
 */
export var getCheckAllValues = function (c) {
    var checkAllValues = [];
    var loop = function (children) {
        React.Children.forEach(children, function (child) {
            var _a, _b;
            if (child.props.children) {
                checkAllValues.concat(loop(child.props.children));
            }
            var shouldNotCheck = ((_b = (_a = child.props) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.display) === 'none' ||
                child.props.disabled ||
                child.props.checkboxDisabled ||
                (typeof child.props.checkable !== 'undefined' &&
                    !child.props.checkable) ||
                (typeof child.props.selectable !== 'undefined' &&
                    !child.props.selectable);
            if (!shouldNotCheck) {
                checkAllValues.push(child.props.value);
            }
        });
    };
    loop(c);
    return checkAllValues;
};
/**
 * Get flatten dataSource
 * @static
 * @param  {any} value structured dataSource
 * @return {String}
 */
export function valueToSelectKey(value) {
    var val;
    if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'value')) {
        val = value.value;
    }
    else {
        val = value;
    }
    return val;
}

import { __assign, __extends, __rest } from "tslib";
// @ts-nocheck
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Input from '@fusion/lib/input';
var TreeNodeInput = /** @class */ (function (_super) {
    __extends(TreeNodeInput, _super);
    function TreeNodeInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputRef = createRef();
        return _this;
    }
    TreeNodeInput.prototype.componentDidMount = function () {
        this.inputRef.current.focus();
    };
    TreeNodeInput.prototype.render = function () {
        var _a = this.props, prefix = _a.prefix, others = __rest(_a, ["prefix"]);
        return (React.createElement(Input, __assign({ ref: this.inputRef, size: "small", className: "".concat(prefix, "tree-node-input") }, others)));
    };
    TreeNodeInput.propTypes = {
        prefix: PropTypes.string,
    };
    return TreeNodeInput;
}(Component));
export default TreeNodeInput;

import { __assign } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { CnReadOnly } from '@/components/cn-read-only';
export var CnCheckboxReadonly = function (props) {
    var value = props.value;
    if (typeof props.value === 'boolean') {
        value = props.value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnCheckbox' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnCheckbox' });
    }
    else if (Array.isArray(value) && !value.length) {
        value = undefined;
    }
    return (React.createElement(CnReadOnly, __assign({ type: "enum", value: value, valueSeparator: ", " }, props.readOnlyProps)));
};

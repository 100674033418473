/**
 * 用于决定是否应该显示文件预览
 * showPreview：一个函数或布尔值，用于确定是否显示预览。如果它是一个函数，它接受一个 IUploadFile 类型的文件对象，
 * 并返回一个布尔值来决定是否显示预览。如果它是一个布尔值，则直接决定是否显示预览。
 * onPreview：一个函数，在文件预览被点击时调用。
 * file：一个 IUploadFile 类型的文件对象，表示要预览的文件。
 * @returns
 */
export var useShowPreview = function (params) {
    var file = params.file, onPreview = params.onPreview;
    if (typeof params.showPreview === 'function') {
        return params.showPreview(file);
    }
    if (!params.showPreview)
        return false;
    var isUploading = ['idle', 'uploading'].includes(file.status);
    if (isUploading)
        return false;
    return (onPreview || file.url) && file.allowPreview !== false;
};

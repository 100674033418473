import { cssPrefix as _cssPrefix } from '../constant/constant';
export var cssPrefix = "".concat(_cssPrefix, "item");
export var classMap = {
    EXECUTING: "".concat(cssPrefix, "-running"),
    SUCCEEDED: "".concat(cssPrefix, "-success"),
    FAILED: "".concat(cssPrefix, "-failed"),
    SOME: "".concat(cssPrefix, "-some"),
};
export var iconMap = {
    EXECUTING: 'pie-chart-fill',
    SUCCEEDED: 'complete-r-fill',
    FAILED: 'error-r-fill',
    SOME: 'exclamation-t-fill',
};

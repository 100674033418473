import isFunction from 'lodash/isFunction';
export default function dfs(list, handlerCallback) {
    if (Array.isArray(list)) {
        list.forEach(function (item) {
            if (isFunction(handlerCallback)) {
                handlerCallback(item);
            }
            if (item && item.children && item.children.length > 0) {
                dfs(item.children, handlerCallback);
            }
        });
    }
}

import React from 'react';
import { FORM_EMPTY_CONTENT, useUploadState } from '@cainiaofe/cn-ui-common';
import { UploadListItem } from './upload-list-item';
export var UploadList = function () {
    var _a;
    var _b = useUploadState(), rootProps = _b.props, readOnly = _b.readOnly, getValue = _b.getValue;
    var fileList = getValue();
    if (!fileList.length) {
        if (readOnly) {
            if ((_a = rootProps.readOnlyProps) === null || _a === void 0 ? void 0 : _a.emptyRender) {
                return React.createElement(React.Fragment, null, rootProps.readOnlyProps.emptyRender());
            }
            return React.createElement(React.Fragment, null, FORM_EMPTY_CONTENT);
        }
        return null;
    }
    return (React.createElement(React.Fragment, null, fileList.map(function (item, index) { return (React.createElement(UploadListItem, { key: item.key || "".concat(item.name, "_").concat(index), fileList: fileList, file: item })); })));
};

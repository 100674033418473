import { useFormLayout } from '@/form/cn-form-layout/hooks';
/**
 * 计算表单项 labelAlign 值
 *
 * 先通过 useFormLayout 获取表单容器下的 labelAlign
 * 1. 如果存在，优先使用表单的 labelAlign，忽略组件内部逻辑
 * 2. 如果不存在，当 labelCol 或 wrapperCol 存在的时候，返回 left，否则返回 top
 */
export var useFormItemLabelAlign = function (props) {
    var layout = useFormLayout();
    if (layout === null || layout === void 0 ? void 0 : layout.labelAlign) {
        return layout.labelAlign;
    }
    return props.wrapperCol || props.labelCol ? 'left' : 'top';
};

import * as React from 'react';
import { observer, RecursionField, useFieldSchema, useField, } from '@formily/react';
import { useFilterPropsContext } from '../../context';
import { isInvisible } from '../../utils';
/**
 * 筛选项flex布局容器
 */
export var CnFilterFlex = observer(function () {
    var _a;
    var schema = useFieldSchema();
    // 从shema中取，解决不同步的问题
    var realSchema = (_a = schema === null || schema === void 0 ? void 0 : schema['x-component-props']) === null || _a === void 0 ? void 0 : _a.realSchema;
    var gridField = useField();
    var rtl = useFilterPropsContext().rtl;
    return (React.createElement("div", { className: "cn-ui-filter-flow" }, schema.mapProperties(function (schemaItem, name) {
        var _a, _b, _c, _d;
        var childFormPath = gridField.address.concat(name);
        var field = gridField.query(childFormPath).take();
        if ((field === null || field === void 0 ? void 0 : field.display) === 'none' ||
            (field === null || field === void 0 ? void 0 : field.display) === 'hidden' ||
            isInvisible(schemaItem)) {
            return;
        }
        return (React.createElement("div", { style: {
                display: ((_b = (_a = realSchema === null || realSchema === void 0 ? void 0 : realSchema.properties) === null || _a === void 0 ? void 0 : _a[name]) === null || _b === void 0 ? void 0 : _b.isFold)
                    ? 'none'
                    : 'initial',
            }, key: name, hidden: (_d = (_c = schemaItem['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.hidden) !== null && _d !== void 0 ? _d : schemaItem.hidden, dir: rtl ? 'rtl' : undefined },
            React.createElement(RecursionField, { name: name, schema: schemaItem })));
    })));
});

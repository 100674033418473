import { CnPageScrollDomSelector } from "../constant/page";
/**
 * 弹层挂载的容器节点
 */
export function getPopupContainer(popupContainer) {
    if (popupContainer)
        return popupContainer;
    return function (target) {
        if (!target)
            return document.body;
        return target.closest(CnPageScrollDomSelector) || document.body;
    };
}

import React, { useEffect, useRef } from 'react';
import { CnAsyncJobsProContextState } from '../../context/cn-async-job-pro-context-state';
import { useJobPanel } from './hooks/use-job-panel';
import { useJobRunning } from './hooks/use-job-running';
import useJobsPolling from '../../hooks/use-jobs-polling';
import useStartPolling from './hooks/use-start-polling';
export var CnAsyncJobsContext = function (props) {
    var children = props.children, _a = props.importProps, importProps = _a === void 0 ? {} : _a, _b = props.exportProps, exportProps = _b === void 0 ? {} : _b;
    var _c = useJobPanel({ panelTab: exportProps ? 'export' : 'import' }), panelTab = _c.panelTab, setPanelTab = _c.setPanelTab, panelVisible = _c.panelVisible, setPanelVisible = _c.setPanelVisible;
    var importPropsRef = useRef(importProps);
    useEffect(function () {
        importPropsRef.current = importProps;
    }, [importProps]);
    var exportPropsRef = useRef(exportProps);
    useEffect(function () {
        exportPropsRef.current = exportProps;
    }, [exportProps]);
    var exportData = useJobsPolling({
        pollingRequestConfig: exportProps.pollingRequestConfig || exportProps.pollingService,
        onError: exportProps.onError,
        onSuccess: exportProps.onSuccess,
    });
    useStartPolling({
        loopSwitch: panelVisible,
        type: 'export',
        startTaskLoop: exportData.startTaskLoop,
    });
    var importData = useJobsPolling({
        pollingRequestConfig: importProps.pollingRequestConfig || importProps.pollingService,
        onError: importProps.onError,
        onSuccess: importProps.onSuccess,
    });
    useStartPolling({
        loopSwitch: panelVisible,
        type: 'import',
        startTaskLoop: importData.startTaskLoop,
    });
    var _d = useJobRunning({
        panelVisible: panelVisible,
        hasUnreadJob: exportData.hasUnreadJob || importData.hasUnreadJob,
    }), runningTip = _d.runningTip, hideRunningTip = _d.hideRunningTip;
    return (React.createElement(CnAsyncJobsProContextState.Provider, { value: {
            runningTip: runningTip,
            hideRunningTip: hideRunningTip,
            unread: !panelVisible && (exportData.hasUnreadJob || importData.hasUnreadJob),
            panelVisible: panelVisible,
            setPanelVisible: setPanelVisible,
            panelTab: panelTab,
            setPanelTab: setPanelTab,
            exportPropsRef: exportPropsRef,
            exportLoading: exportData.loading,
            exportResponse: {
                jobResult: exportData.jobResult,
                processedJobs: exportData.processedJobs,
            },
            importPropsRef: importPropsRef,
            importLoading: importData.loading,
            importResponse: {
                jobResult: importData.jobResult,
                processedJobs: importData.processedJobs,
            },
        } }, children));
};

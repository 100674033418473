import { __assign } from "tslib";
import React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnLoading } from '@/components/cn-loading';
import { CnImageViewer } from '@/components/cn-image-viewer';
import { useShowUploadRemoveIcon, useShowUploadPreviewIcon, useUploadState, } from '@cainiaofe/cn-ui-common';
export var UploadListItem = function (props) {
    var _a = useUploadState(), rootProps = _a.props, readOnly = _a.readOnly, onRemove = _a.onRemove;
    var style = props.style, file = props.file, fileList = props.fileList;
    var disabled = rootProps.disabled;
    var status = file.status;
    var isUploading = status && ['idle', 'uploading'].includes(status);
    var showRemove = useShowUploadRemoveIcon({
        showRemove: !disabled && !readOnly && rootProps.showRemove,
        file: file,
    });
    var showPreview = useShowUploadPreviewIcon({
        showPreview: rootProps.showPreview,
        onPreview: rootProps.onPreview,
        file: file,
    });
    var onPreview = function (_file) {
        if (!showPreview)
            return;
        if (rootProps.onPreview) {
            rootProps.onPreview(_file);
            return;
        }
        CnImageViewer.open({
            value: _file.objectUrl || _file.url,
            src: fileList.map(function (item) {
                return item.objectUrl || item.url;
            }),
        });
    };
    return (React.createElement("div", { className: "cn-ui-oss-image-upload-item" },
        isUploading ? (React.createElement("div", { className: "cn-ui-oss-image-upload-item-uploading" },
            React.createElement(CnLoading, { visible: true }))) : null,
        React.createElement("div", { style: __assign(__assign({}, style), { backgroundImage: "url(".concat(file.objectUrl || file.url, ")") }), className: "cn-ui-oss-image-upload-item-img", onClick: function (event) {
                event.stopPropagation();
                onPreview(file);
            } }),
        showRemove && (React.createElement("div", { className: "cn-ui-oss-image-upload-item-remove", onClick: function (event) {
                event.stopPropagation();
                onRemove(file);
            } },
            React.createElement(CnIcon, { type: "delete", size: "small" })))));
};

import { __assign, __spreadArray } from "tslib";
import { SaveConfigActionTypes } from '../../types/save-config-action-types';
import { getFilterItemMeta } from './get-filter-item-meta';
export var Actions = {
    updateItemsMeta: function (state, params) {
        var enableConfig = params.enableConfig, children = params.children;
        return function (dispatch) {
            var rst;
            if (!enableConfig) {
                rst = [];
            }
            else {
                rst = children.map(function (child) { return getFilterItemMeta(child); }).filter(Boolean);
            }
            dispatch({
                type: SaveConfigActionTypes.SetItemsMeta,
                payload: rst,
            });
        };
    },
    updateDataSource: function (state, params) {
        var configValue = state.configValue, itemsMeta = state.itemsMeta;
        var enableConfig = params.enableConfig;
        return function (dispatch) {
            var rst;
            if (!enableConfig) {
                rst = [];
            }
            else if (!configValue) {
                rst = itemsMeta.map(function (meta) {
                    return (__assign(__assign({}, meta), { visible: !meta._cnfilter_ismorefilter }));
                });
            }
            else {
                var metaInConfig_1 = [];
                var metaNotInConfig_1 = [];
                itemsMeta.forEach(function (meta) {
                    var idx = configValue.findIndex(function (val) { return val.name === (meta === null || meta === void 0 ? void 0 : meta.value); });
                    if (idx < 0) {
                        metaNotInConfig_1.push(__assign(__assign({}, meta), { visible: false }));
                        return;
                    }
                    metaInConfig_1[idx] = __assign(__assign({}, meta), { visible: configValue[idx].visible });
                });
                rst = __spreadArray(__spreadArray([], metaInConfig_1.filter(Boolean), true), metaNotInConfig_1, true);
            }
            dispatch({
                type: SaveConfigActionTypes.SetDataSource,
                payload: rst,
            });
        };
    },
    updateArrangedChildren: function (store, params) {
        var dataSource = store.dataSource;
        var enableConfig = params.enableConfig, children = params.children;
        return function (dispatch) {
            var rst;
            if (!enableConfig) {
                rst = children;
            }
            else {
                var lastIdx_1 = dataSource.length;
                var elementOrderList = children.map(function (child) {
                    var _a;
                    var meta = getFilterItemMeta(child);
                    var idx = !meta
                        ? -1
                        : dataSource.findIndex(function (dataSourceItem) { return dataSourceItem.value === (meta === null || meta === void 0 ? void 0 : meta.value); });
                    if (idx < 0) {
                        idx = lastIdx_1++;
                    }
                    return {
                        idx: idx,
                        element: __assign(__assign({}, child), { value: __assign(__assign({}, child.value), { 'x-visible': !!((_a = dataSource[idx]) === null || _a === void 0 ? void 0 : _a.visible) }) }),
                    };
                });
                elementOrderList.sort(function (a, b) { return a.idx - b.idx; });
                rst = elementOrderList.map(function (item) { return item.element; });
            }
            dispatch({
                type: SaveConfigActionTypes.SetArrangedChildren,
                payload: rst,
            });
        };
    },
};

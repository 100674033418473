import { __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { useEffect } from 'react';
import useJobPolling from '@/components/cn-async-jobs-pro/hooks/use-job-polling';
import { download, events } from '@cainiaofe/cn-utils';
import { CnButton } from '@/components/cn-button';
import { CnDialog } from '@/components/cn-dialog';
import { CnResult } from '@/components/cn-result';
import { CnBox } from '@/components/cn-box';
import { CnProgress } from '@/components/cn-progress';
import { getSomeStatusJobDesc } from './util';
import classNames from 'classnames';
import { LOOPING_IMPORT_JOB } from '../cn-async-jobs-pro/view/constant/constant';
var cssPrefix = 'cn-ui-sync-dialog';
export default function LoopDialog(props) {
    var _a;
    var showBgProcessBtn = props.showBgProcessBtn, onRender = props.onRender, autoDownload = props.autoDownload, visible = props.visible, setVisible = props.setVisible, setJobId = props.setJobId, restProp = __rest(props, ["showBgProcessBtn", "onRender", "autoDownload", "visible", "setVisible", "setJobId"]);
    var _b = useJobPolling(restProp), jobResult = _b.jobResult, cancel = _b.cancel, loading = _b.loading;
    useEffect(function () {
        // 当轮询弹窗未渲染同时任务执行结果不存在时不支持回调
        if (!visible || !jobResult)
            return;
        onRender();
    }, [jobResult, onRender, visible]);
    useEffect(function () {
        // 当任务结果数据不存在时，不执行下面的自动下载逻辑
        if (!jobResult)
            return;
        var downloadUrl = jobResult.downloadUrl, status = jobResult.status, jobName = jobResult.jobName;
        // 当任务执行成功后，判断是否开启自动下载同时下载链接存在时自动触发下载
        if (status === 'SUCCEEDED' && autoDownload && downloadUrl) {
            download(downloadUrl, jobName);
        }
    }, [autoDownload, jobResult]);
    useEffect(function () {
        // 弹窗被隐藏后停止轮询
        if (!visible) {
            cancel();
        }
    }, [visible, cancel]);
    if (!jobResult)
        return null;
    var jobName = jobResult.jobName, downloadUrl = jobResult.downloadUrl, errorDownloadUrl = jobResult.errorDownloadUrl, status = jobResult.status, reason = jobResult.reason;
    var showFooter = showBgProcessBtn && status === 'EXECUTING';
    // 关闭任务进度轮询弹窗
    var closeLoopDialog = function () {
        if (loading || status === 'EXECUTING') {
            events.emit(LOOPING_IMPORT_JOB);
        }
        setVisible(false);
        // 当关闭轮询弹窗时，取消轮询
        cancel();
    };
    return (React.createElement(CnDialog, { className: classNames(cssPrefix, (_a = {},
            _a["".concat(cssPrefix, "-show-footer")] = showFooter,
            _a)), size: "small", title: $i18n.get({
            id: 'Import',
            dm: '导入',
            ns: 'CnOssImport',
        }), visible: visible, onCancel: closeLoopDialog, onOk: closeLoopDialog, okProps: { visible: false }, cancelProps: { visible: false }, afterClose: function () {
            setJobId(undefined);
        }, onClose: closeLoopDialog, cache: false, footer: showFooter ? (React.createElement(CnButton, { onClick: closeLoopDialog, size: "medium" }, $i18n.get({
            id: 'BackgroundProcessing',
            dm: '后台处理',
            ns: 'CnOssImport',
        }))) : (false) },
        status === 'EXECUTING' ? (React.createElement(CnBox, { className: "".concat(cssPrefix, "-job-process"), justify: "center", direction: "column", align: "center", spacing: 12 },
            React.createElement(CnProgress, { className: "".concat(cssPrefix, "-running"), progressive: true, percent: jobResult.progress, shape: "circle" }),
            React.createElement("div", { className: "".concat(cssPrefix, "-running-tip") }, $i18n.get({
                id: 'FileImport',
                dm: '文件导入中...',
                ns: 'CnOssImport',
            })))) : null,
        status === 'FAILED' ? (React.createElement(CnResult, { size: "mini", type: "error", title: $i18n.get({
                id: 'DataImportFailed',
                dm: '数据导入失败',
                ns: 'CnOssImport',
            }), subTitle: reason, extra: errorDownloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return download(errorDownloadUrl); } }, $i18n.get({
                id: 'ViewFailureRecords',
                dm: '查看失败记录',
                ns: 'CnOssImport',
            }))) : null })) : null,
        status === 'SOME' ? (React.createElement(CnResult, { size: "mini", type: "warning", title: $i18n.get({
                id: 'SomeDataImportedSuccessfully',
                dm: '部分数据导入成功',
                ns: 'CnOssImport',
            }), subTitle: getSomeStatusJobDesc(jobResult), extra: React.createElement(React.Fragment, null,
                errorDownloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return download(errorDownloadUrl); } }, $i18n.get({
                    id: 'ViewFailureRecords',
                    dm: '查看失败记录',
                    ns: 'CnOssImport',
                }))) : null,
                downloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return download(downloadUrl, jobName); } }, $i18n.get({
                    id: 'ViewSuccessRecords',
                    dm: '查看成功记录',
                    ns: 'CnOssImport',
                }))) : null) })) : null,
        status === 'SUCCEEDED' ? (React.createElement(CnResult, { size: "mini", type: "success", title: $i18n.get({
                id: 'CongratulationsDataImportSuccessful',
                dm: '导入成功',
                ns: 'CnOssImport',
            }), extra: downloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return download(downloadUrl, jobName); } }, $i18n.get({ id: 'Download', dm: '下载', ns: 'CnOssImport' }))) : null })) : null));
}

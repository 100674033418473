import * as React from 'react';
export var useImproveMargin = function (props) {
    var disabled = props.disabled, ref = props.ref;
    React.useEffect(function () {
        var _a;
        if (disabled)
            return;
        var dom = ref.current;
        if (!dom)
            return;
        try {
            var list = (_a = dom.parentNode) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.cn-ui-card');
            var lastCard = list[list.length - 1];
            if (lastCard)
                lastCard.style.marginBottom = '0';
        }
        catch ( /* empty */_b) { /* empty */ }
    }, [disabled, ref]);
};

import { __assign, __rest, __spreadArray } from "tslib";
import React, { useState, useMemo, useCallback, useRef, useEffect, cloneElement, useContext, } from 'react';
import { ResponsiveGrid, Button } from '@/components/fusion';
import { CnButton } from '@/components/cn-button';
import { CnTooltip } from '@/components/cn-tooltip';
import classNames from 'classnames';
import { useSize } from 'ahooks';
import FilterItem from './filter-item';
import { CnIcon } from '@/components/cn-icon';
import map from 'lodash/map';
import { sendLog } from '@/components/cn-utils';
import FilterContext from './filter-context';
var Cell = ResponsiveGrid.Cell;
var isInvisible = function (el) { var _a, _b; return ((_b = (_a = el === null || el === void 0 ? void 0 : el.props) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.display) === 'none'; };
/**
 * 将Filter的children节点，最多保留maxRow行
 */
var calcLayout = function (maxRow, columns, children) {
    var _a;
    var idx = 0;
    var currentGroupSpan = 0;
    var groupCnt = 0;
    var showChildren = [];
    while (idx < children.length) {
        var child = children[idx];
        if (isInvisible(child)) {
            idx += 1;
            continue;
        }
        var span = Number((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.colSpan) || 1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(child);
        idx += 1;
    }
    // // btn前置空白
    // const spaceSpan = columns - currentGroupSpan - btnSpan;
    // if (spaceSpan < 0) {
    //   // 此行已放不下btn，需要换行
    //   ++groupCnt;
    //   showChildren.push(<Cell key="space-cell" colSpan={columns - btnSpan} />);
    // } else {
    //   if (spaceSpan > 0) {
    //     showChildren.push(<Cell key="space-cell" colSpan={spaceSpan} />);
    //   }
    //   ++groupCnt;
    // }
    var hideChildren = children.slice(idx);
    return {
        showChildren: showChildren,
        hideChildren: hideChildren,
    };
};
var calcLayoutWithButton = function (maxRow, columns, buttonSpan, children) {
    var _a;
    var idx = 0;
    var currentGroupSpan = 0;
    var groupCnt = 0;
    var btnSpan = Math.min(buttonSpan, columns);
    var showChildren = [];
    while (idx < children.length) {
        var child = children[idx];
        if (isInvisible(child)) {
            idx += 1;
            continue;
        }
        var span = Number((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.colSpan) || 1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span + btnSpan > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(child);
        idx += 1;
    }
    // btn前置空白
    var spaceSpan = columns - currentGroupSpan - btnSpan;
    if (spaceSpan < 0) {
        // 此行已放不下btn，需要换行
        ++groupCnt;
        showChildren.push(React.createElement(Cell, { key: "space-cell", colSpan: columns - btnSpan }));
    }
    else {
        if (spaceSpan > 0) {
            showChildren.push(React.createElement(Cell, { key: "space-cell", colSpan: spaceSpan }));
        }
        ++groupCnt;
    }
    var hideChildren = children.slice(idx);
    return {
        showChildren: showChildren,
        hideChildren: hideChildren,
    };
};
var renderExpandButton = function (_a) {
    var size = _a.size, expand = _a.expand, onClick = _a.onClick, ref = _a.ref, $i18n = _a.$i18n, _b = _a.iconOnly, iconOnly = _b === void 0 ? true : _b, showButton = _a.showButton, enableConfig = _a.enableConfig, _c = _a.renderConfigButton, renderConfigButton = _c === void 0 ? function () { return null; } : _c;
    var expandButton = (React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(CnButton, { "data-hottag": "cn-ui.cn-filter.more", size: size, onClick: onClick, className: classNames('cn-ui-filter-icon-btn', "cn-ui-filter-icon-btn-".concat(size)) },
            React.createElement("span", { ref: ref },
                React.createElement(CnIcon, { size: size, type: expand ? 'arrow-up' : 'arrow-down' }))) }, expand
        ? $i18n.get({ id: 'hide', dm: '折叠' })
        : $i18n.get({ id: 'more', dm: '展开' })));
    if (!iconOnly) {
        expandButton = (React.createElement(CnButton, { "data-hottag": "cn-ui.cn-filter.more", size: size, 
            // text
            onClick: onClick, className: classNames(
            // 'cn-ui-filter-icon-btn',
            // `cn-ui-filter-icon-btn-${size}`,
            'cn-ui-filter-expand-btn-with-text') },
            React.createElement("span", { ref: ref, className: "cn-ui-filter-expand-btn-with-text-content" },
                React.createElement(CnIcon, { size: "medium", className: "cn-ui-filter-expand-btn-with-text-icon", type: expand ? 'arrow-up' : 'arrow-down' }),
                expand
                    ? $i18n.get({ id: 'hide', dm: '更多' })
                    : $i18n.get({ id: 'more', dm: '更多' }))));
    }
    var configButton = renderConfigButton();
    if (showButton && enableConfig) {
        return (React.createElement(Button.Group, { className: "cn-ui-filter-btn-group", size: size },
            expandButton,
            configButton));
    }
    if (showButton) {
        return expandButton;
    }
    if (enableConfig) {
        return configButton;
    }
    return null;
};
var AutoColumnLayout = function (props) {
    var size = props.size, mode = props.mode, columns = props.columns, maxVisibleRow = props.maxVisibleRow, columnGap = props.columnGap, rowGap = props.rowGap, buttons = props.buttons, extendButtons = props.extendButtons, extendButtonsPosition = props.extendButtonsPosition, isRightButton = props.isRightButton, _a = props.buttonSpan, buttonSpan = _a === void 0 ? 1 : _a, hideButton = props.hideButton, _b = props.enableExpand, enableExpand = _b === void 0 ? true : _b, defaultExpand = props.defaultExpand, _c = props.enableConfig, enableConfig = _c === void 0 ? false : _c, renderConfigButton = props.renderConfigButton, propsExpand = props.expand, onExpandChange = props.onExpandChange, children = props.children, responsiveSize = props.responsiveSize, onColumnChange = props.onColumnChange, expandButtonUseIcon = props.expandButtonUseIcon, labelAlign = props.labelAlign, $i18n = props.$i18n, rest = __rest(props, ["size", "mode", "columns", "maxVisibleRow", "columnGap", "rowGap", "buttons", "extendButtons", "extendButtonsPosition", "isRightButton", "buttonSpan", "hideButton", "enableExpand", "defaultExpand", "enableConfig", "renderConfigButton", "expand", "onExpandChange", "children", "responsiveSize", "onColumnChange", "expandButtonUseIcon", "labelAlign", "$i18n"]);
    var overlayRef = useRef(null);
    var resizeRef = useRef(null);
    var _d = useState(defaultExpand || false), expand = _d[0], setExpand = _d[1];
    var filterContext = useContext(FilterContext);
    useEffect(function () {
        if (propsExpand !== undefined && propsExpand !== expand) {
            setExpand(propsExpand);
        }
    }, [expand, propsExpand]);
    var width = (useSize(resizeRef) || {}).width;
    var innerColunms = useMemo(function () {
        if (columns)
            return columns;
        var _responsive = responsiveSize;
        var matched = responsiveSize.length; // 默认8个
        if (!width)
            return matched;
        _responsive.some(function (v, idx) {
            if (width < v) {
                matched = idx;
                return true;
            }
            return false;
        });
        var minCols = labelAlign === 'left' ? 2 : 3;
        // 最小值3
        return matched > minCols ? matched : minCols;
    }, [columns, responsiveSize, width, labelAlign]);
    useEffect(function () {
        onColumnChange && onColumnChange(Number(innerColunms));
    }, [innerColunms, onColumnChange]);
    var handleToggleMore = useCallback(function () {
        sendLog({
            id: 'cn-ui.cn-filter.toggleExpand',
            name: 'CnFilter更多查询项点击',
        });
        onExpandChange && onExpandChange(!expand);
        if (propsExpand === undefined) {
            setExpand(!expand);
        }
    }, [propsExpand, expand, onExpandChange]);
    useEffect(function () {
        // 展开时需要刷新select从而重新计算宽度
        if (expand && (filterContext === null || filterContext === void 0 ? void 0 : filterContext.itemCollection)) {
            map(Object.keys(filterContext.itemCollection), function (i) {
                var _a, _b, _c, _d;
                if ([
                    'CnAsyncSelect',
                    'Select',
                    'CnEmployeeSelect',
                    'CnDepartmentSelect',
                    'CnTreeSelect',
                ].indexOf(filterContext.itemCollection[i].displayName) > -1) {
                    (_d = (_c = (_b = (_a = filterContext.itemCollection[i]) === null || _a === void 0 ? void 0 : _a.defaultChildRef) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.forceUpdate) === null || _d === void 0 ? void 0 : _d.call(_c);
                }
            });
        }
    }, [expand]);
    // const handleHideMore = useCallback(() => {
    //   onExpandChange && onExpandChange(false);
    //   if (propsExpand === undefined) {
    //     setExpand(false);
    //   }
    // }, [propsExpand, onExpandChange]);
    var normalizedChildren = useMemo(function () {
        return React.Children.map(children, function (_child, idx) {
            var _a, _b, _c;
            var child = _child;
            var span = Math.min(Number((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.span) || 1, Number(innerColunms));
            var style = isInvisible(child) ? { display: 'none' } : undefined;
            if (((_b = child === null || child === void 0 ? void 0 : child.props) === null || _b === void 0 ? void 0 : _b.display) === 'none') {
                style = { display: 'none' };
            }
            else if (((_c = child === null || child === void 0 ? void 0 : child.props) === null || _c === void 0 ? void 0 : _c.display) === 'hidden') {
                style = { visibility: 'hidden' };
            }
            return (React.createElement(Cell, { key: idx, colSpan: span, style: style }, child));
        });
    }, [children, innerColunms]);
    var showAll = !enableExpand || (mode === 'expand' && expand);
    var _e = useMemo(function () {
        return (isRightButton
            ? calcLayoutWithButton(showAll ? normalizedChildren.length : Number(maxVisibleRow) || 2, Number(innerColunms), Number(buttonSpan) || 1, normalizedChildren)
            : calcLayout(showAll ? normalizedChildren.length : Number(maxVisibleRow) || 2, Number(innerColunms), normalizedChildren));
    }, [showAll, innerColunms, maxVisibleRow, normalizedChildren]), showChildren = _e.showChildren, hideChildren = _e.hideChildren;
    var showButton = enableExpand &&
        (showAll ||
            (hideChildren &&
                hideChildren.filter(function (child) {
                    return !isInvisible(child);
                }).length > 0));
    // console.log('show cn-cn-button', hideChildren, showChildren);
    var renderChildren = __spreadArray(__spreadArray([], showChildren, true), hideChildren.map(function (child) {
        return cloneElement(child, { style: { display: 'none' } });
    }), true);
    var renderButton = function () {
        if (hideButton) {
            return null;
        }
        return (React.createElement(FilterItem, __assign({ size: size, className: classNames('filter-item-btns', labelAlign === 'left' ? 'left-label' : '', isRightButton ? 'right-btn' : ''), label: React.createElement("div", null), labelAlign: labelAlign, wrapperCol: labelAlign === 'left'
                ? {
                    span: 24,
                }
                : undefined }, rest),
            React.createElement("div", { className: "cn-ui-filter-btns" },
                extendButtonsPosition === 'left' ? extendButtons : undefined,
                buttons,
                extendButtonsPosition === 'right' ? extendButtons : undefined,
                renderExpandButton({
                    size: size,
                    expand: expand,
                    onClick: handleToggleMore,
                    ref: overlayRef,
                    $i18n: $i18n,
                    iconOnly: expandButtonUseIcon,
                    showButton: showButton,
                    enableConfig: enableConfig,
                    renderConfigButton: renderConfigButton,
                }),
                extendButtonsPosition === 'end' ? extendButtons : undefined)));
    };
    if (isRightButton) {
        return (React.createElement("div", { ref: resizeRef },
            React.createElement(ResponsiveGrid, { columns: innerColunms, gap: [rowGap, columnGap] },
                renderChildren,
                React.createElement(Cell, { colSpan: Number(buttonSpan) || 1 }, renderButton()))));
    }
    return (React.createElement("div", { ref: resizeRef },
        React.createElement(ResponsiveGrid, { columns: innerColunms, gap: [rowGap, columnGap] }, renderChildren),
        renderButton()));
};
export default AutoColumnLayout;

import { __assign } from "tslib";
import { SaveConfigActionTypes } from '../../types/save-config-action-types';
export var Reducers = function (state, action) {
    var type = action.type, payload = action.payload;
    switch (type) {
        case SaveConfigActionTypes.SetConfigValue:
            return __assign(__assign({}, state), { configValue: payload });
        case SaveConfigActionTypes.SetConfigInited:
            return __assign(__assign({}, state), { configInited: payload });
        case SaveConfigActionTypes.SetItemsMeta:
            return __assign(__assign({}, state), { itemsMeta: payload });
        case SaveConfigActionTypes.SetDataSource:
            return __assign(__assign({}, state), { dataSource: payload });
        case SaveConfigActionTypes.SetArrangedChildren:
            return __assign(__assign({}, state), { arrangedChildren: payload });
        case SaveConfigActionTypes.SetOverlayVisible:
            return __assign(__assign({}, state), { overlayVisible: payload });
        default:
            return __assign({}, state);
    }
};

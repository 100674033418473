import { __assign, __awaiter, __generator } from "tslib";
import useLatest from 'ahooks/lib/useLatest';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { CnMessage } from '@/components/cn-message';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { isPromise } from '@/components/cn-utils';
export default function useJobPolling(props) {
    var _this = this;
    var jobId = props.jobId, onSuccess = props.onSuccess, onSome = props.onSome, onError = props.onError, pollingRequestConfig = props.pollingRequestConfig;
    var onErrorRef = useLatest(onError);
    var onSuccessRef = useLatest(onSuccess);
    var onSomeRef = useLatest(onSome);
    var pollingRequestConfigRef = useLatest(pollingRequestConfig);
    var intervalRef = useRef(0);
    var _a = useCnRequest(__assign(__assign({}, pollingRequestConfig), { pollingInterval: intervalRef.current, onBefore: function (params) {
            var _a;
            if (intervalRef.current === 0)
                intervalRef.current = 1000;
            else if (intervalRef.current === 1000)
                intervalRef.current = 2000;
            else if (intervalRef.current === 2000)
                intervalRef.current = 3000;
            else if (intervalRef.current === 3000)
                intervalRef.current = 4000;
            else if (intervalRef.current === 4000)
                intervalRef.current = 5000;
            else if (intervalRef.current === 5000) {
                if (!props.pollingInterval || props.pollingInterval < 5000) {
                    intervalRef.current = 5000;
                }
                else {
                    intervalRef.current = props.pollingInterval;
                }
            }
            else
                intervalRef.current = 5000;
            (_a = pollingRequestConfig.onBefore) === null || _a === void 0 ? void 0 : _a.call(pollingRequestConfig, params);
        } })), _data = _a.data, error = _a.error, runAsync = _a.runAsync, cancel = _a.cancel, loading = _a.loading;
    var data = useMemo(function () {
        try {
            if (typeof error === 'object') {
                var temp = error === null || error === void 0 ? void 0 : error.data;
                if (temp === null || temp === void 0 ? void 0 : temp.jobId)
                    return temp;
            }
        }
        catch ( /* empty */_a) { /* empty */ }
        return _data;
    }, [error, _data]);
    var startTask = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var _pollingService, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _pollingService = pollingRequestConfigRef.current;
                    if (!_pollingService || !jobId)
                        return [2 /*return*/, undefined];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, runAsync({ jobId: jobId })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    if ((err_1 === null || err_1 === void 0 ? void 0 : err_1.message) === 'canceled')
                        return [2 /*return*/, undefined];
                    if (onErrorRef.current) {
                        onErrorRef.current(err_1);
                    }
                    else if (err_1.message) {
                        CnMessage.error(err_1.message);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, undefined];
            }
        });
    }); }, [jobId, onErrorRef, pollingRequestConfigRef, runAsync]);
    useEffect(function () {
        var _a, _b, _c;
        // 任务结束后调用 cancel 取消轮询
        if ((data === null || data === void 0 ? void 0 : data.status) !== 'EXECUTING')
            cancel();
        // 任务结束后调用对应的钩子
        var status = (data || {}).status;
        if (status === 'SUCCEEDED') {
            (_a = onSuccessRef.current) === null || _a === void 0 ? void 0 : _a.call(onSuccessRef, data);
            return;
        }
        if (status === 'FAILED') {
            (_b = onErrorRef.current) === null || _b === void 0 ? void 0 : _b.call(onErrorRef, data);
            return;
        }
        if (status === 'SOME') {
            /**
             * 当 result 为 Promise 时，会根据 Promise 异步处理结果，去调用 onSuccess 和 onError
             */
            var result = (_c = onSomeRef.current) === null || _c === void 0 ? void 0 : _c.call(onSomeRef, data);
            if (isPromise(result)) {
                result.then(function (temp) {
                    var _a;
                    (_a = onSuccessRef.current) === null || _a === void 0 ? void 0 : _a.call(onSuccessRef, temp || data);
                }, function (err) {
                    onErrorRef.current(err || data);
                });
            }
        }
    }, [data, cancel, onSomeRef, onErrorRef, onSuccessRef]);
    useEffect(function () {
        startTask();
        return function () {
            cancel();
        };
    }, [cancel, startTask]);
    return { jobResult: data, cancel: cancel, loading: loading };
}

import './index.scss';
import React from 'react';
import classNames from 'classnames';
import { Overlay } from '@fusion/lib';
import { CnTag } from '@/components/cn-tag';
import { FORM_EMPTY_CONTENT, useUploadState } from '@cainiaofe/cn-ui-common';
import { UploadListItem } from '../upload-list-item';
import { StaticFileIcon } from '../upload-list-item/icons';
export var MiniUploadList = function (props) {
    var _a = useUploadState(), readOnly = _a.readOnly, getValue = _a.getValue;
    var showUploadList = props.showUploadList;
    if (!showUploadList)
        return null;
    var fileList = getValue();
    if (!fileList.length) {
        if (readOnly) {
            return React.createElement(React.Fragment, null, FORM_EMPTY_CONTENT);
        }
        return null;
    }
    return (React.createElement(Overlay.Popup, { className: "cn-ui-oss-upload-list-table-popup", trigger: React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-oss-upload-list-table-trigger') },
            fileList.slice(0, 3).map(function (item) { return (React.createElement(StaticFileIcon, { key: item.key || item.name || item.url, file: item, className: "table-inline-icon" })); }),
            fileList.length > 3 ? (React.createElement(CnTag, { key: "item-tag", size: "small", type: "primary", className: "table-inline-more" },
                fileList.length - 3,
                " +")) : null) },
        React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-oss-upload-list', 'cn-ui-oss-upload-list-mini') }, fileList.map(function (item) { return (React.createElement(UploadListItem, { key: item.key, file: item })); }))));
};

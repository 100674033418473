import { __assign, __rest } from "tslib";
import './cn-form-step.scss';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { CnStep as Steps, StepItem as StepsItem, } from '@/components/cn-step';
import { formilyReact, } from '@/form/formily';
var connect = formilyReact.connect, useField = formilyReact.useField, observer = formilyReact.observer, useFieldSchema = formilyReact.useFieldSchema, RecursionField = formilyReact.RecursionField;
var parseSteps = function (schema) {
    var steps = [];
    schema.mapProperties(function (innerSchema, name) {
        var _a;
        if ((_a = innerSchema['x-component']) === null || _a === void 0 ? void 0 : _a.includes('CnFormStepItem')) {
            steps.push({
                name: name,
                props: innerSchema['x-component-props'],
                schema: innerSchema,
            });
        }
    });
    return steps;
};
export var CnFormStep = connect(observer(function (_a) {
    var _b;
    var cnFormStepInstance = _a.cnFormStepInstance, className = _a.className, restProps = __rest(_a, ["cnFormStepInstance", "className"]);
    var field = useField();
    var fieldSchema = useFieldSchema();
    var steps = parseSteps(fieldSchema);
    var current = restProps.current || (cnFormStepInstance === null || cnFormStepInstance === void 0 ? void 0 : cnFormStepInstance.current) || 0;
    (_b = cnFormStepInstance === null || cnFormStepInstance === void 0 ? void 0 : cnFormStepInstance.connect) === null || _b === void 0 ? void 0 : _b.call(cnFormStepInstance, steps, field);
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-formily-step') },
        React.createElement(Steps, __assign({}, restProps, { current: current }), steps.map(function (_a, key) {
            var props = _a.props;
            return React.createElement(StepsItem, __assign({}, props, { key: key }));
        })),
        steps.map(function (_a, key) {
            var name = _a.name, schema = _a.schema;
            if (key !== current)
                return null;
            return React.createElement(RecursionField, { key: key, name: name, schema: schema });
        })));
}));
export var CnFormStepItem = function (_a) {
    var children = _a.children;
    return React.createElement(Fragment, null, children);
};

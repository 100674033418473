import { isEmpty } from '../../utils';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
export var defaultFormat = function (key, value, child, cache) {
    if (Array.isArray(value)) {
        return value
            .filter(function (val) { return !isEmpty(val); })
            .map(function (val) { return defaultFormat(key, val, child, cache); })
            .join(',');
    }
    else if (isObject(value)) {
        // 只支持一层数据结构，如{type: '1', label: 'label'}
        return Object.keys(value)
            .map(function (k) { return defaultFormat(key, value[k], child, cache); })
            .join('-');
    }
    return !isNil(value) ? String(value) : '';
};

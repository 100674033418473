import { __assign } from "tslib";
import './index.scss';
import $i18n from 'panda-i18n';
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { download } from '@cainiaofe/cn-utils';
import { CnButton } from '@/components/cn-button';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { CnResult } from '@/components/cn-result';
import { CnMessage } from '@/components/cn-message';
import { cssPrefix } from '../constant/constant';
import JobItem from '../job-item';
import JobMessage from '../job-message';
import { useCnAsyncJobsProContextState } from '../../hooks/use-cn-async-job-pro-context-state';
export default function JobPanel(props) {
    var type = props.type, className = props.className, _a = props.style, _style = _a === void 0 ? {} : _a, autoDownload = props.autoDownload, viewMore = props.viewMore, jobResult = props.jobResult, processedJobs = props.processedJobs;
    var panelTab = useCnAsyncJobsProContextState().panelTab;
    var selected = panelTab === type;
    useEffect(function () {
        for (var _i = 0, processedJobs_1 = processedJobs; _i < processedJobs_1.length; _i++) {
            var job = processedJobs_1[_i];
            var status = job.status, downloadUrl = job.downloadUrl, jobName = job.jobName;
            var config = {
                content: React.createElement(JobMessage, { type: type, data: job }),
                duration: 3000,
            };
            if (status === 'FAILED') {
                CnMessage.error(config);
            }
            else if (status === 'SOME') {
                if (autoDownload)
                    download(downloadUrl, jobName);
                CnMessage.warning(config);
            }
            else if (status === 'SUCCEEDED') {
                if (autoDownload)
                    download(downloadUrl, jobName);
                CnMessage.success(config);
            }
        }
    }, [autoDownload, processedJobs, type]);
    var style = useMemo(function () {
        var temp = __assign(__assign({}, _style), { height: viewMore ? 'calc(100% - 56px)' : '100%' });
        if (!selected)
            temp.display = 'none';
        return temp;
    }, [viewMore, selected, _style]);
    if (!jobResult.length) {
        return (React.createElement(CnResult, { type: "empty", style: style, title: $i18n.get({
                id: 'NoTaskYet',
                dm: '暂无任务',
                ns: 'CnAsyncJobsPro',
            }) }));
    }
    return (React.createElement("div", { className: classNames("".concat(cssPrefix, "panel"), className), style: style },
        React.createElement(CnScrollBar, null, jobResult.map(function (data, i) {
            var _a;
            return (React.createElement(JobItem, { className: classNames((_a = {}, _a["".concat(cssPrefix, "item-first")] = i === 0, _a)), key: data.jobId, type: type, data: data }));
        })),
        viewMore ? (React.createElement("div", { className: classNames("".concat(cssPrefix, "panel-view-more")) },
            React.createElement(CnButton, { onClick: viewMore.onClick, text: true, size: "medium" }, viewMore.text ||
                $i18n.get({
                    id: 'SeeMore',
                    dm: '查看更多',
                    ns: 'CnAsyncJobsPro',
                })))) : null));
}

import $i18n from 'panda-i18n';
import { formilyShared, formilyValidator, formilyValidatorLocale } from '@/form/formily';
import isEqual from 'lodash/isEqual';
var isArr = formilyShared.isArr, isEmpty = formilyShared.isEmpty, isValid = formilyShared.isValid;
var isValidateEmpty = function (value) {
    var _a;
    if (isArr(value)) {
        for (var i = 0; i < value.length; i++) {
            if (isValid(value[i]))
                return false;
        }
        return true;
    }
    else {
        // compat to draft-js
        if (value === null || value === void 0 ? void 0 : value.getCurrentContent) {
            /* istanbul ignore next */
            return !((_a = value.getCurrentContent()) === null || _a === void 0 ? void 0 : _a.hasText());
        }
        return isEmpty(value);
    }
};
export default (function (value, rule) {
    if (rule.required === false)
        return '';
    var lang = formilyValidator.getValidateLocaleIOSCode(formilyValidator.getValidateLanguage());
    var defaultRequiredMessage = formilyValidatorLocale[lang]
        ? formilyValidatorLocale[lang].required
        : formilyValidatorLocale.en.required;
    var isMessageDefault = isEqual(rule.message, defaultRequiredMessage);
    if (isValidateEmpty(value)) {
        return isMessageDefault
            ? $i18n.get({
                id: 'ThisFieldIsRequired',
                dm: '该字段是必填字段',
                ns: 'CnForm',
            })
            : rule.message;
    }
    return '';
});

import { __assign, __extends } from "tslib";
import cx from 'classnames';
import * as React from 'react';
import { BehaviorSubject, combineLatest, noop, Subscription } from 'rxjs';
import { StyleSheetManager } from 'styled-components';
import * as op from 'rxjs/operators';
import { calculateRenderInfo } from './calculations';
import { EmptyHtmlTable } from './empty';
import TableHeader from './header';
import { getRichVisibleRectsStream } from './helpers/getRichVisibleRectsStream';
import { getFullRenderRange, makeRowHeightManager, } from './helpers/rowHeightManager';
import { TableDOMHelper } from './helpers/TableDOMUtils';
import { HtmlTable } from './html-table';
import Loading from './loading';
import { Classes, LOCK_SHADOW_PADDING, StyledArtTableWrapper, } from './styles';
import { getScrollbarSize, OVERSCAN_SIZE, shallowEqual, STYLED_REF_PROP, sum, syncScrollLeft, throttledWindowResize$, } from './utils';
var BaseTable = /** @class */ (function (_super) {
    __extends(BaseTable, _super);
    function BaseTable(props) {
        var _this = _super.call(this, props) || this;
        _this.rowHeightManager = makeRowHeightManager(_this.props.dataSource.length, _this.props.estimatedRowHeight);
        _this.artTableWrapperRef = React.createRef();
        _this.rootSubscription = new Subscription();
        _this.state = {
            hasScroll: true,
            needRenderLock: true,
            offsetY: 0,
            offsetX: 0,
            // 因为 ResizeObserver 在一开始总是会调用一次所提供的回调函数
            // 故这里为 maxRenderHeight/maxRenderWidth 设置一个默认值即可（因为这两个默认值很快就会被覆盖）
            // https://stackoverflow.com/questions/60026223/does-resizeobserver-invokes-initially-on-page-load
            maxRenderHeight: 600,
            // eslint-disable-next-line react/no-unused-state
            maxRenderWidth: 800,
        };
        return _this;
    }
    BaseTable.prototype.getVerticalRenderRange = function (useVirtual) {
        var dataSource = this.props.dataSource;
        var _a = this.state, offsetY = _a.offsetY, maxRenderHeight = _a.maxRenderHeight;
        var rowCount = dataSource.length;
        if (useVirtual.vertical) {
            return this.rowHeightManager.getRenderRange(offsetY, maxRenderHeight, rowCount);
        }
        else {
            return getFullRenderRange(rowCount);
        }
    };
    BaseTable.prototype.componentDidMount = function () {
        this.updateDOMHelper();
        this.props$ = new BehaviorSubject(this.props);
        this.initSubscriptions();
        this.didMountOrUpdate();
    };
    BaseTable.prototype.componentDidUpdate = function (prevProps, prevState) {
        this.updateDOMHelper();
        this.props$.next(this.props);
        this.didMountOrUpdate(prevProps, prevState);
    };
    BaseTable.prototype.componentWillUnmount = function () {
        this.rootSubscription.unsubscribe();
    };
    BaseTable.prototype.updateOffsetX = function (nextOffsetX) {
        if (this.lastInfo.useVirtual.horizontal) {
            // 更新的频率控制
            if (Math.abs(nextOffsetX - this.state.offsetX) >= OVERSCAN_SIZE / 2) {
                this.setState({ offsetX: nextOffsetX });
            }
        }
    };
    BaseTable.prototype.syncHorizontalScrollFromTableBody = function () {
        this.syncHorizontalScroll(this.domHelper.tableBody.scrollLeft);
    };
    BaseTable.prototype.renderTableHeader = function (info) {
        var _a = this.props, stickyTop = _a.stickyTop, hasHeader = _a.hasHeader;
        return (React.createElement("div", { className: cx(Classes.tableHeader, 'no-scrollbar'), style: {
                top: stickyTop === 0 ? undefined : stickyTop,
                display: hasHeader ? undefined : 'none',
            } },
            React.createElement(TableHeader, { info: info })));
    };
    BaseTable.prototype.renderTableBody = function (info) {
        var _a = this.props, dataSource = _a.dataSource, getRowProps = _a.getRowProps, primaryKey = _a.primaryKey, isLoading = _a.isLoading, emptyCellHeight = _a.emptyCellHeight, components = _a.components;
        var tableBodyClassName = cx(Classes.tableBody, Classes.horizontalScrollContainer, 'no-scrollbar');
        // 空数据
        if (dataSource.length === 0) {
            var _b = this.props, tableComponents = _b.components, emptyContent_1 = _b.emptyContent;
            var EmptyContent_1 = tableComponents === null || tableComponents === void 0 ? void 0 : tableComponents.EmptyContent;
            if (EmptyContent_1 == null && emptyContent_1 != null) {
                EmptyContent_1 = (function () { return emptyContent_1; });
            }
            /**
             * 渲染空数据表格
             * @returns {JSX.Element} 空数据表格
             */
            var renderEmptyTable = function () {
                return (React.createElement(EmptyHtmlTable, { descriptors: info.visible, isLoading: isLoading, EmptyContent: EmptyContent_1, emptyCellHeight: emptyCellHeight }));
            };
            return React.createElement("div", { className: tableBodyClassName }, renderEmptyTable());
        }
        var _c = info.verticalRenderRange, topIndex = _c.topIndex, bottomBlank = _c.bottomBlank, topBlank = _c.topBlank, bottomIndex = _c.bottomIndex;
        return (React.createElement("div", { "data-scroll-element-type": "horizontal-table", className: tableBodyClassName },
            topBlank > 0 && (React.createElement("div", { key: "top-blank", className: cx(Classes.virtualBlank, 'top'), style: { height: topBlank } })),
            React.createElement(HtmlTable, { components: components, tbodyHtmlTag: "tbody", getRowProps: getRowProps, primaryKey: primaryKey, data: dataSource.slice(topIndex, bottomIndex), horizontalRenderInfo: info, verticalRenderInfo: {
                    first: 0,
                    offset: topIndex,
                    limit: bottomIndex,
                    last: dataSource.length - 1,
                } }),
            bottomBlank > 0 && (React.createElement("div", { key: "bottom-blank", className: cx(Classes.virtualBlank, 'bottom'), style: { height: bottomBlank } }))));
    };
    BaseTable.prototype.renderTableFooter = function (info) {
        var _a = this.props, _b = _a.footerDataSource, footerDataSource = _b === void 0 ? [] : _b, getRowProps = _a.getRowProps, primaryKey = _a.primaryKey, stickyBottom = _a.stickyBottom, components = _a.components;
        return (React.createElement("div", { 
            // datdata-scroll-element-type="horizontal-table"a
            className: cx(Classes.tableFooter, Classes.horizontalScrollContainer), style: { bottom: stickyBottom === 0 ? undefined : stickyBottom } },
            React.createElement(HtmlTable, { components: components, tbodyHtmlTag: "tfoot", data: footerDataSource, horizontalRenderInfo: info, getRowProps: getRowProps, primaryKey: primaryKey, verticalRenderInfo: {
                    offset: 0,
                    first: 0,
                    last: footerDataSource.length - 1,
                    limit: Infinity,
                } })));
    };
    BaseTable.prototype.renderLockShadows = function (info) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: Classes.lockShadowMask, style: {
                    left: 0,
                    width: info.leftLockTotalWidth + LOCK_SHADOW_PADDING,
                } },
                React.createElement("div", { className: cx(Classes.lockShadow, Classes.leftLockShadow) })),
            React.createElement("div", { className: Classes.lockShadowMask, style: {
                    right: 0,
                    width: info.rightLockTotalWidth + LOCK_SHADOW_PADDING,
                } },
                React.createElement("div", { className: cx(Classes.lockShadow, Classes.rightLockShadow) }))));
    };
    BaseTable.prototype.renderStickyScroll = function () {
        var _a = this.props, hasStickyScroll = _a.hasStickyScroll, stickyBottom = _a.stickyBottom;
        var hasScroll = this.state.hasScroll;
        return (React.createElement("div", { "data-scroll-element-type": "horizontal-table", className: cx(Classes.stickyScroll, Classes.horizontalScrollContainer), style: {
                display: hasStickyScroll && hasScroll ? 'block' : 'none',
                bottom: stickyBottom,
            } },
            React.createElement("div", { className: Classes.stickyScrollItem })));
    };
    BaseTable.prototype.render = function () {
        var _a;
        var info = calculateRenderInfo(this);
        this.lastInfo = info;
        var _b = this.props, dataSource = _b.dataSource, className = _b.className, style = _b.style, hasHeader = _b.hasHeader, useOuterBorder = _b.useOuterBorder, _c = _b.isStickyHeader, isStickyHeader = _c === void 0 ? true : _c, isStickyFooter = _b.isStickyFooter, isLoading = _b.isLoading, footerDataSource = _b.footerDataSource, components = _b.components;
        var artTableWrapperClassName = cx(className, Classes.artTableWrapper, {
            'use-outer-border': useOuterBorder,
            empty: dataSource.length === 0,
            lock: info.hasLockColumn,
            'has-header': hasHeader,
            'sticky-header': isStickyHeader,
            'has-footer': footerDataSource.length > 0,
            'sticky-footer': isStickyFooter,
        });
        var artTableWrapperProps = (_a = {
                className: artTableWrapperClassName,
                style: style,
                'data-scroll-element-type': 'vertical-table'
            },
            _a[STYLED_REF_PROP] = this.artTableWrapperRef,
            _a);
        return (React.createElement(StyleSheetManager, { disableCSSOMInjection: true },
            React.createElement(StyledArtTableWrapper, __assign({}, artTableWrapperProps),
                React.createElement(Loading, { visible: isLoading, LoadingIcon: components.LoadingIcon, LoadingContentWrapper: components.LoadingContentWrapper },
                    React.createElement("div", { className: Classes.artTable },
                        this.renderTableHeader(info),
                        this.renderTableBody(info),
                        this.renderTableFooter(info),
                        this.renderLockShadows(info)),
                    this.renderStickyScroll(info)))));
    };
    /** 同步横向滚动偏移量 */
    BaseTable.prototype.syncHorizontalScroll = function (x) {
        this.updateOffsetX(x);
        var tableBody = this.domHelper.tableBody;
        var flat = this.lastInfo.flat;
        var leftLockShadow = this.domHelper.getLeftLockShadow();
        if (leftLockShadow) {
            var shouldShowLeftLockShadow = flat.left.length > 0 && this.state.needRenderLock && x > 0;
            // 是否现实shadow
            if (shouldShowLeftLockShadow) {
                leftLockShadow.classList.add('show-shadow');
            }
            else {
                leftLockShadow.classList.remove('show-shadow');
            }
        }
        var rightLockShadow = this.domHelper.getRightLockShadow();
        if (rightLockShadow) {
            var shouldShowRightLockShadow = flat.right.length > 0 &&
                this.state.needRenderLock &&
                x < tableBody.scrollWidth - tableBody.clientWidth;
            if (shouldShowRightLockShadow) {
                rightLockShadow.classList.add('show-shadow');
            }
            else {
                rightLockShadow.classList.remove('show-shadow');
            }
        }
    };
    /** 自定义滚动条宽度为table宽度，使滚动条滑块宽度相同 */
    BaseTable.prototype.updateStickyScroll = function () {
        var _a = this.domHelper, stickyScroll = _a.stickyScroll, artTable = _a.artTable, stickyScrollItem = _a.stickyScrollItem;
        if (!artTable) {
            return;
        }
        var tableBodyHtmlTable = this.domHelper.getTableBodyHtmlTable();
        var innerTableWidth = tableBodyHtmlTable.offsetWidth;
        var artTableWidth = artTable.offsetWidth;
        var stickyScrollHeightProp = this.props.stickyScrollHeight;
        var stickyScrollHeight = stickyScrollHeightProp === 'auto'
            ? getScrollbarSize().height
            : stickyScrollHeightProp;
        stickyScroll.style.marginTop = "-".concat(stickyScrollHeight + 1, "px");
        if (artTableWidth >= innerTableWidth) {
            if (this.state.hasScroll) {
                this.setState({ hasScroll: false });
            }
        }
        else if (!this.state.hasScroll && stickyScrollHeight > 5) {
            // 考虑下mac下面隐藏滚动条的情况
            this.setState({ hasScroll: true });
        }
        // 设置子节点宽度
        stickyScrollItem.style.width = "".concat(innerTableWidth, "px");
    };
    BaseTable.prototype.didMountOrUpdate = function (prevProps, prevState) {
        this.syncHorizontalScrollFromTableBody();
        this.updateStickyScroll();
        this.adjustNeedRenderLock();
        this.updateRowHeightManager();
        this.updateScrollLeftWhenLayoutChanged(prevProps, prevState);
    };
    BaseTable.prototype.updateScrollLeftWhenLayoutChanged = function (prevProps, prevState) {
        if (prevState != null) {
            if (!prevState.hasScroll && this.state.hasScroll) {
                this.domHelper.stickyScroll.scrollLeft = 0;
            }
        }
        if (prevProps != null) {
            var prevHasFooter = prevProps.footerDataSource.length > 0;
            var currentHasFooter = this.props.footerDataSource.length > 0;
            if (!prevHasFooter && currentHasFooter) {
                this.domHelper.tableFooter.scrollLeft =
                    this.domHelper.tableBody.scrollLeft;
            }
        }
    };
    BaseTable.prototype.initSubscriptions = function () {
        var _this = this;
        var _a = this.domHelper, tableHeader = _a.tableHeader, tableBody = _a.tableBody, tableFooter = _a.tableFooter, stickyScroll = _a.stickyScroll;
        this.rootSubscription.add(
        // widnow.resize 处理逻辑
        throttledWindowResize$.subscribe(function () {
            _this.updateStickyScroll();
            _this.adjustNeedRenderLock();
        }));
        // 滚动同步
        this.rootSubscription.add(
        // 同步多个元素之间的 scrollLeft
        syncScrollLeft([tableHeader, tableBody, tableFooter, stickyScroll], function (scrollLeft) {
            _this.syncHorizontalScroll(scrollLeft);
        }));
        // getRichVisibleRectsStream 找到滚动体，并计算 clipRect . top 、left、right、bottom
        var richVisibleRects$ = getRichVisibleRectsStream(this.domHelper.artTable, this.props$.pipe(op.skip(1), op.mapTo('structure-may-change')), this.props.virtualDebugLabel).pipe(op.shareReplay());
        // 每当可见部分发生变化的时候，调整 loading icon 的位置（如果 loading icon 存在的话）
        this.rootSubscription.add(combineLatest([
            richVisibleRects$.pipe(op.map(function (p) { return p.clipRect; }), op.distinctUntilChanged(shallowEqual)),
            this.props$.pipe(op.startWith(null), op.pairwise(), op.filter(function (_a) {
                var prevProps = _a[0], props = _a[1];
                return prevProps == null || (!prevProps.isLoading && props.isLoading);
            })),
        ]).subscribe(function (_a) {
            var clipRect = _a[0];
            var loadingIndicator = _this.domHelper.getLoadingIndicator();
            if (!loadingIndicator) {
                return;
            }
            var height = clipRect.bottom - clipRect.top;
            // fixme 这里的定位在有些特殊情况下可能会出错 see #132
            loadingIndicator.style.top = "".concat(height / 2, "px");
            loadingIndicator.style.marginTop = "".concat(height / 2, "px");
        }));
        // 每当可见部分发生变化的时候，如果开启了虚拟滚动，则重新触发 render
        this.rootSubscription.add(richVisibleRects$
            .pipe(op.filter(function () {
            var _a = _this.lastInfo.useVirtual, horizontal = _a.horizontal, vertical = _a.vertical;
            return horizontal || vertical;
        }), op.map(function (_a) {
            var clipRect = _a.clipRect, offsetY = _a.offsetY;
            return ({
                // 计算渲染视图大小
                maxRenderHeight: clipRect.bottom - clipRect.top,
                maxRenderWidth: clipRect.right - clipRect.left,
                offsetY: offsetY,
            });
        }), op.distinctUntilChanged(function (x, y) {
            // 因为 overscan 的存在，滚动较小的距离时不需要触发组件重渲染，这里做了一层控制
            return (Math.abs(x.maxRenderWidth - y.maxRenderWidth) <
                OVERSCAN_SIZE / 2 &&
                Math.abs(x.maxRenderHeight - y.maxRenderHeight) <
                    OVERSCAN_SIZE / 2 &&
                Math.abs(x.offsetY - y.offsetY) < OVERSCAN_SIZE / 2);
        }))
            .subscribe(function (sizeAndOffset) {
            _this.setState(sizeAndOffset);
        }));
    };
    /** 更新 DOM 节点的引用，方便其他方法直接操作 DOM */
    BaseTable.prototype.updateDOMHelper = function () {
        this.domHelper = new TableDOMHelper(this.artTableWrapperRef.current);
    };
    BaseTable.prototype.updateRowHeightManager = function () {
        var _a;
        var virtualTop = this.domHelper.getVirtualTop();
        var virtualTopHeight = (_a = virtualTop === null || virtualTop === void 0 ? void 0 : virtualTop.clientHeight) !== null && _a !== void 0 ? _a : 0;
        var zeroHeightRowCount = 0;
        var maxRowIndex = -1;
        var maxRowBottom = -1;
        for (var _i = 0, _b = this.domHelper.getTableRows(); _i < _b.length; _i++) {
            var tr = _b[_i];
            var rowIndex = Number(tr.dataset.rowindex);
            if (isNaN(rowIndex)) {
                continue;
            }
            maxRowIndex = Math.max(maxRowIndex, rowIndex);
            var offset = tr.offsetTop + virtualTopHeight;
            var size = tr.offsetHeight;
            if (size === 0) {
                zeroHeightRowCount += 1;
            }
            maxRowBottom = Math.max(maxRowBottom, offset + size);
            this.rowHeightManager.updateRow(rowIndex, offset, size);
        }
        // 当 estimatedRowHeight 过大时，可能出现「渲染行数过少，无法覆盖可视范围」的情况
        // 出现这种情况时，我们判断「下一次渲染能够渲染更多行」是否满足，满足的话就直接调用 forceUpdate
        // zeroHeightRowCount === 0 用于确保当前没有 display=none 的情况
        if (maxRowIndex !== -1 && zeroHeightRowCount === 0) {
            if (maxRowBottom < this.state.offsetY + this.state.maxRenderHeight) {
                var vertical = this.getVerticalRenderRange(this.lastInfo.useVirtual);
                if (vertical.bottomIndex - 1 > maxRowIndex) {
                    this.forceUpdate();
                }
            }
        }
    };
    /** 计算表格所有列的渲染宽度之和，判断表格是否需要渲染锁列 */
    BaseTable.prototype.adjustNeedRenderLock = function () {
        var needRenderLock = this.state.needRenderLock;
        var _a = this.lastInfo, flat = _a.flat, hasLockColumn = _a.hasLockColumn;
        if (hasLockColumn) {
            var sumOfColWidth = sum(flat.full.map(function (col) { return col.width; }));
            var nextNeedRenderLock = sumOfColWidth > this.domHelper.artTable.clientWidth;
            if (needRenderLock !== nextNeedRenderLock) {
                this.setState({ needRenderLock: nextNeedRenderLock });
            }
        }
        else if (needRenderLock) {
            this.setState({ needRenderLock: false });
        }
    };
    BaseTable.defaultProps = {
        hasHeader: true,
        isStickyHeader: true,
        stickyTop: 0,
        footerDataSource: [],
        isStickyFooter: true,
        stickyBottom: 0,
        hasStickyScroll: true,
        stickyScrollHeight: 'auto',
        useVirtual: 'auto',
        estimatedRowHeight: 48,
        isLoading: false,
        components: {},
        getRowProps: noop,
        dataSource: [],
    };
    return BaseTable;
}(React.Component));
export { BaseTable };

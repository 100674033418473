import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React, { useRef } from 'react';
import classnames from 'classnames';
import { CnMessage } from '@/components/cn-message';
import { ClipboardActionCopy, getParent, isFunction, isNumber, } from '../../utils';
import { CaretDownIcon, PopupContainer } from '../../dep';
import { Dropdown } from '../../dep/dropdown';
import { stateKey as multiSelectStateKey } from '../multi-select-pipeline';
import transformValueToString from './transform-value-to-string';
export function colAttachPipeline(colAttach, attachState) {
    return function (pipeline) {
        var _a, _b;
        var primaryKey = pipeline.ensurePrimaryKey('colAttach');
        if (typeof primaryKey !== 'string') {
            throw new Error($i18n.get({
                id: 'RowAttachOnlySupportsStringsAsPrimaryKey_562099196',
                dm: 'rowAttach 仅支持字符串作为 primaryKey',
                ns: 'CnBaseTable',
            }));
        }
        var attachColHandler = columnHeaderAttach(primaryKey, colAttach);
        pipeline.columns(attachColHandler({
            columns: pipeline.getColumns(),
            dataSource: pipeline.getDataSource(),
            state: attachState === null || attachState === void 0 ? void 0 : attachState.copy,
            selected: (_b = (_a = pipeline.getStateAtKey(multiSelectStateKey)) === null || _a === void 0 ? void 0 : _a.selectedRowKeys) !== null && _b !== void 0 ? _b : [],
        }));
        return pipeline;
    };
}
export function useAttach() {
    var keyRef = useRef('');
    var tranformRef = useRef(null);
    return {
        copy: {
            keyRef: keyRef,
            tranformRef: tranformRef,
        },
    };
}
function columnHeaderAttach(primaryKey, opts) {
    var copy = opts.copy, appendMenus = opts.appendMenus;
    var _a = copy || {}, _b = _a.headerCopySuccessMsg, headerCopySuccessMsg = _b === void 0 ? '' : _b, _c = _a.headerCopyFailMsg, headerCopyFailMsg = _c === void 0 ? '' : _c, copyColumnText = _a.copyColumnText, copySelectText = _a.copySelectText, copyTableText = _a.copyTableText;
    return function (topts) {
        var columns = topts.columns, dataSource = topts.dataSource, _a = topts.selected, selected = _a === void 0 ? [] : _a, state = topts.state;
        var keyRef = state.keyRef, tranformRef = state.tranformRef;
        var onCopyAll = function () {
            var _a, _b;
            var key = keyRef.current;
            var text = '';
            var filterCols = columns.find(function (col) { var _a; return ((_a = col.dataIndex) !== null && _a !== void 0 ? _a : col.code) == key; });
            text = transformValueToString((_a = filterCols === null || filterCols === void 0 ? void 0 : filterCols.copyTitle) !== null && _a !== void 0 ? _a : '-', (_b = filterCols === null || filterCols === void 0 ? void 0 : filterCols.attach) === null || _b === void 0 ? void 0 : _b.copyTransform);
            if (Array.isArray(dataSource)) {
                text = "".concat(text, "\n").concat(dataSource
                    .map(function (item) {
                    return item
                        ? transformValueToString(item[key], tranformRef.current)
                        : '';
                })
                    .filter(function (item) { return item !== ''; })
                    .join('\n'));
                copyText(text, headerCopySuccessMsg, headerCopyFailMsg);
            }
        };
        var onCopyTable = function () {
            var filterCols = columns.filter(function (col) { var _a; return (col === null || col === void 0 ? void 0 : col.attach) !== false && ((_a = col === null || col === void 0 ? void 0 : col.attach) === null || _a === void 0 ? void 0 : _a.copyEnable) !== false; });
            // copyEnable
            var text = filterCols
                .map(function (col) {
                var _a, _b, _c;
                return transformValueToString((_a = col === null || col === void 0 ? void 0 : col.copyTitle) !== null && _a !== void 0 ? _a : '-', ((_b = col === null || col === void 0 ? void 0 : col.attach) === null || _b === void 0 ? void 0 : _b.copyTransform) instanceof Function
                    ? (_c = col === null || col === void 0 ? void 0 : col.attach) === null || _c === void 0 ? void 0 : _c.copyTransform
                    : null);
            })
                .join('\t');
            text = "".concat(text, "\n").concat(dataSource
                .map(function (item) {
                return filterCols
                    .map(function (col) {
                    var _a, _b, _c;
                    return transformValueToString(
                    // @ts-ignore
                    item ? (_a = item[col === null || col === void 0 ? void 0 : col.code]) !== null && _a !== void 0 ? _a : '-' : '-', ((_b = col === null || col === void 0 ? void 0 : col.attach) === null || _b === void 0 ? void 0 : _b.copyTransform) instanceof Function
                        ? (_c = col === null || col === void 0 ? void 0 : col.attach) === null || _c === void 0 ? void 0 : _c.copyTransform
                        : null);
                })
                    .join('\t');
            })
                .join('\n'));
            copyText(text, headerCopySuccessMsg, headerCopyFailMsg);
        };
        var onCopySelect = function () {
            if (!(selected === null || selected === void 0 ? void 0 : selected.length)) {
                return;
            }
            if (Array.isArray(dataSource)) {
                var key_1 = keyRef.current;
                var text = dataSource
                    .filter(function (item) { return selected.includes(item ? item[primaryKey] : ''); })
                    .map(function (item) {
                    return item
                        ? transformValueToString(item[key_1], tranformRef.current)
                        : '';
                })
                    .filter(function (item) { return item !== ''; })
                    .join('\n');
                copyText(text, headerCopySuccessMsg, headerCopyFailMsg);
            }
        };
        var menu = (React.createElement("div", { className: "cn-table-copy-menu" },
            (copy !== null && copy !== void 0 ? copy : true) ? (React.createElement(React.Fragment, null,
                React.createElement("div", { onClick: onCopyTable }, copyTableText !== null && copyTableText !== void 0 ? copyTableText : $i18n.get({
                    id: 'TableCopy',
                    dm: '表格复制',
                    ns: 'CnBaseTable',
                })),
                React.createElement("div", { onClick: onCopyAll }, copyColumnText !== null && copyColumnText !== void 0 ? copyColumnText : $i18n.get({
                    id: 'WholeColumnReplication',
                    dm: '整列复制',
                    ns: 'CnBaseTable',
                })),
                React.createElement("div", { className: classnames({ disabled: !(selected === null || selected === void 0 ? void 0 : selected.length) }), onClick: onCopySelect }, copySelectText !== null && copySelectText !== void 0 ? copySelectText : $i18n.get({
                    id: 'CopySelectedItems',
                    dm: '复制选中项',
                    ns: 'CnBaseTable',
                })))) : null,
            appendMenus));
        var showMenus = function (event, col) {
            keyRef.current = col === null || col === void 0 ? void 0 : col.code;
            tranformRef.current = col === null || col === void 0 ? void 0 : col.copyTransform;
            Dropdown.hide();
            var recordEvent = __assign({}, event);
            setTimeout(function () {
                var hasGetHeaderCell = false;
                var target = getParent(recordEvent === null || recordEvent === void 0 ? void 0 : recordEvent.target, function (dom) {
                    var _a, _b;
                    var ret = (_b = (_a = dom === null || dom === void 0 ? void 0 : dom.className) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, 'cn-table-header-cell');
                    if (ret) {
                        hasGetHeaderCell = true;
                    }
                    return ret;
                }, 10);
                var customProps = { style: { padding: 0 } };
                if (hasGetHeaderCell) {
                    // @ts-ignore
                    var _a = isFunction(target === null || target === void 0 ? void 0 : target.getBoundingClientRect)
                        ? target.getBoundingClientRect()
                        : {}, width = _a.width, left = _a.left;
                    if (isNumber(width) && isNumber(left)) {
                        // @ts-ignore
                        customProps.style.left = left + width;
                    }
                    Dropdown.show(recordEvent, menu, {
                        offset: [0, 0],
                        props: customProps,
                        accordTarget: false,
                        Container: PopupContainer,
                        triangleHeight: 0,
                        target: target,
                        zIndex: 10000,
                        // @ts-ignore
                        transform: function (_a) {
                            var isTopHalf = _a.isTopHalf;
                            return "translate(-100%, ".concat(isTopHalf ? 0 : '-100', "%)");
                        },
                    });
                }
            });
        };
        var onMouseDownHandler = function (event, col) {
            if (event.button === 2) {
                showMenus(event, col);
            }
        };
        var oncontextmenuHandler = function (event) {
            event.stopPropagation();
            event.preventDefault();
        };
        if (Array.isArray(columns)) {
            columns.forEach(function (col) {
                var _a, _b;
                if (!col || (col === null || col === void 0 ? void 0 : col.attachSet)) {
                    return;
                }
                // col.attach: false, 可以关闭某列的
                if ((col === null || col === void 0 ? void 0 : col.attach) !== false && col.code) {
                    // 默认开启
                    col.title = (React.createElement("div", { className: "cn-table-copy-header" }, (_a = col.title) !== null && _a !== void 0 ? _a : col.name,
                        React.createElement("div", { onClick: function (event) { return showMenus(event, col); }, className: "cn-table-copy-header-icon" },
                            React.createElement(CaretDownIcon, null))));
                    if (col.width >= 0) {
                        col.width += 26;
                    }
                    col.attachSet = true;
                    col.headerCellProps = __assign(__assign({}, ((col === null || col === void 0 ? void 0 : col.headerCellProps) || {})), { onContextMenu: oncontextmenuHandler, onMouseDown: function (event) { return onMouseDownHandler(event, col); }, style: __assign(__assign({}, (((_b = col.headerCellProps) === null || _b === void 0 ? void 0 : _b.style) || {})), { position: 'relative' }) });
                }
            });
        }
        return columns;
    };
}
export function copyText(val, successMsg, failMsg) {
    try {
        var selectedText = ClipboardActionCopy(val);
        if (selectedText) {
            CnMessage.success(successMsg ||
                $i18n.get({
                    id: 'ReplicationSuccessful',
                    dm: '复制成功',
                    ns: 'CnBaseTable',
                }));
        }
    }
    catch (error) {
        CnMessage.error(failMsg ||
            $i18n.get({
                id: 'ReplicationFailed',
                dm: '复制失败',
                ns: 'CnBaseTable',
            }));
    }
    Dropdown.hide();
}

import { __assign } from "tslib";
import './index.scss';
import * as React from 'react';
import Tree from './view/tree';
import TreeNode from './view/tree-node';
Tree.Node = TreeNode;
// CnTree 中有 node.type !== TreeNode 的实现逻辑，暂时这样处理
var CnTreeNode = TreeNode;
CnTreeNode.displayName = 'CnTreeNode';
var CnTree = React.forwardRef(function (props, ref) {
    return React.createElement(Tree, __assign({ "data-name": "CnTree" }, props, { ref: ref }));
});
CnTree.displayName = 'CnTree';
/**
 * @deprecated
 */
// @ts-ignore
CnTree.Node = CnTreeNode;
export { CnTree, CnTreeNode, TreeNode };
export default CnTree;

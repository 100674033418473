import { useEffect, useState } from 'react';
import { events } from '@/components/cn-utils';
import { HIDE_PANEL, SHOW_PANEL } from '../../constant/constant';
/**
 * 处理任务面板为状态
 */
export var useJobPanel = function (props) {
    var _a = useState(props.panelTab), panelTab = _a[0], setPanelTab = _a[1];
    var _b = useState(false), panelVisible = _b[0], setPanelVisible = _b[1];
    useEffect(function () {
        var showPanel = function (options) {
            setPanelTab((options === null || options === void 0 ? void 0 : options.type) === 'import' ? 'import' : 'export');
            setPanelVisible(true);
        };
        events.on(SHOW_PANEL, showPanel);
        var hidePanel = function () { return setPanelVisible(false); };
        events.on(HIDE_PANEL, hidePanel);
        return function () {
            events.off(SHOW_PANEL, showPanel);
            events.off(HIDE_PANEL, hidePanel);
        };
    }, []);
    return {
        panelTab: panelTab,
        setPanelTab: setPanelTab,
        panelVisible: panelVisible,
        setPanelVisible: setPanelVisible,
    };
};

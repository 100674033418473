import { __assign } from "tslib";
import * as React from 'react';
// eslint-disable-next-line import/no-cycle
import { CnAsyncSelect } from '@/components/cn-async-select';
export default (function (column) {
    var options = column.options;
    return {
        cell: function (value) {
            var _a, _b, _c, _d;
            return (React.createElement(CnAsyncSelect, __assign({}, (options || {}), { filterLocal: true, value: value, requestConfig: __assign({ cacheKey: "".concat((_a = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _a === void 0 ? void 0 : _a.url, "+").concat((_b = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _b === void 0 ? void 0 : _b.method, "+").concat(JSON.stringify((_c = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _c === void 0 ? void 0 : _c.params), "+").concat(JSON.stringify((_d = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _d === void 0 ? void 0 : _d.data)), staleTime: 5000 }, ((options === null || options === void 0 ? void 0 : options.requestConfig) || {})), readOnly: true })));
        },
    };
});

import { __assign, __rest } from "tslib";
import './async-jobs-pro.scss';
import classNames from 'classnames';
import $i18n from 'panda-i18n';
import React from 'react';
import { CnBadge } from '@/components/cn-badge';
import { CnBox } from '@/components/cn-box';
import { CnLoading } from '@/components/cn-loading';
import { CnBalloon } from '../../cn-balloon';
import { CnIcon } from '../../cn-icon';
import { JobContainer } from './job-container';
import { cssPrefix } from './constant/constant';
import { CnAsyncJobsContext } from './context/cn-async-jobs-pro-context';
import { useCnAsyncJobsProContextState } from '../hooks/use-cn-async-job-pro-context-state';
var CnAsyncJobsView = function (props) {
    var className = props.className, style = props.style;
    var _a = useCnAsyncJobsProContextState(), runningTip = _a.runningTip, hideRunningTip = _a.hideRunningTip, exportLoading = _a.exportLoading, importLoading = _a.importLoading, unread = _a.unread, setPanelVisible = _a.setPanelVisible;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, className, "".concat(cssPrefix, "trigger")), style: style, onClick: function () { return setPanelVisible(true); } },
            React.createElement(CnBadge, { dot: unread },
                React.createElement(CnBalloon, { visible: runningTip, closable: false, trigger: React.createElement(CnLoading, { visible: importLoading || exportLoading, size: "medium", className: "".concat(cssPrefix, "trigger-loading") },
                        React.createElement(CnIcon, { type: "task-r-color", size: "xl" })) },
                    React.createElement(CnBox, { direction: "row", spacing: 9, align: "center" },
                        React.createElement("span", null, $i18n.get({
                            id: 'TheImportAndExportTaskIs_391802359',
                            dm: '导入导出任务后台运行中',
                            ns: 'CnAsyncJobsPro',
                        })),
                        React.createElement("span", { className: "".concat(cssPrefix, "running-tip-know"), onClick: hideRunningTip }, $i18n.get({
                            id: 'IKnow',
                            dm: '我知道了',
                            ns: 'CnAsyncJobsPro',
                        })))))),
        React.createElement(JobContainer, null)));
};
export var CnAsyncJobs = function (props) {
    var className = props.className, style = props.style, restProps = __rest(props, ["className", "style"]);
    return (React.createElement(CnAsyncJobsContext, __assign({}, restProps),
        React.createElement(CnAsyncJobsView, { className: className, style: style })));
};

import $i18n from 'panda-i18n';
import React from 'react';
export var getSomeStatusJobDesc = function (data) {
    var result = [];
    var test = [];
    if (Number.isInteger(data.successCount)) {
        test.push($i18n.get({
            id: 'SuccessfulSuccessCount',
            dm: '成功{successCount}条',
            ns: 'CnOssImport',
        }, { successCount: data.successCount }));
    }
    if (Number.isInteger(data.errorCount)) {
        test.push($i18n.get({
            id: 'FailedErrorCountBar',
            dm: '失败{errorCount}条',
            ns: 'CnOssImport',
        }, { errorCount: data.errorCount }));
    }
    result.push(test.join('，'));
    if (data.reason) {
        result.push(data.reason);
    }
    if (result.length) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, result[0]),
            result[1] ? React.createElement("div", { style: { marginTop: 2 } }, result[1]) : null));
    }
    return null;
};

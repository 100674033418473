import { __extends } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { FORM_EMPTY_CONTENT } from '@cainiaofe/cn-ui-common';
import { handleFormat } from './util';
var CnCurrencyAmountNumberPickerReadOnly = /** @class */ (function (_super) {
    __extends(CnCurrencyAmountNumberPickerReadOnly, _super);
    function CnCurrencyAmountNumberPickerReadOnly() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CnCurrencyAmountNumberPickerReadOnly.prototype.render = function () {
        var _a = this.props, amount = _a.amount, currency = _a.currency, precision = _a.precision, format = _a.format, hasHighlight = _a.hasHighlight, className = _a.className;
        var hasValue = amount || amount === 0;
        var readOnlyCls = classNames(CN_UI_HASH_CLASS_NAME, {
            'cn-ui-currency-amount-number-picker-readonly': true,
            'amount-light': !!hasHighlight,
        }, className);
        if (!hasValue) {
            return (React.createElement("div", { className: "cn-ui-currency-amount-number-picker-readonly" }, FORM_EMPTY_CONTENT));
        }
        return (React.createElement("div", { className: readOnlyCls },
            React.createElement("div", { className: "amount-readonly" }, handleFormat(amount, precision, format)),
            currency && React.createElement("div", { className: "currency-readonly" }, currency)));
    };
    return CnCurrencyAmountNumberPickerReadOnly;
}(React.Component));
export { CnCurrencyAmountNumberPickerReadOnly };

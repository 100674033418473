import { __assign } from "tslib";
import { SaveSelectedActionTypes } from '../../types/save-selected-action-types';
export var Reducers = function (state, action) {
    var type = action.type, payload = action.payload;
    switch (type) {
        case SaveSelectedActionTypes.SetOptions:
            return __assign(__assign({}, state), { options: payload });
        case SaveSelectedActionTypes.SetSelected:
            return __assign(__assign({}, state), { selected: payload });
        case SaveSelectedActionTypes.SetOverlayVisible:
            return __assign(__assign({}, state), { overlayVisible: payload });
        default:
            return __assign({}, state);
    }
};

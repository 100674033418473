import { __assign } from "tslib";
export var getFilterItemMeta = function (property) {
    var _a, _b;
    // todo _cnfilter_ismorefilter
    var name = property.key, value = property.value, _c = property._cnfilter_ismorefilter, _cnfilter_ismorefilter = _c === void 0 ? false : _c;
    var enableConfig = value.enableConfig, label = value.title;
    var span = (_b = (_a = value === null || value === void 0 ? void 0 : value['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.colSpan) !== null && _b !== void 0 ? _b : value.colSpan;
    if (enableConfig === false)
        return null;
    if (typeof enableConfig === 'string') {
        return {
            label: enableConfig,
            value: enableConfig,
            _cnfilter_ismorefilter: _cnfilter_ismorefilter,
            span: span,
        };
    }
    if (typeof enableConfig === 'object') {
        return __assign(__assign({}, enableConfig), { _cnfilter_ismorefilter: _cnfilter_ismorefilter, span: span });
    }
    if (name) {
        return {
            label: label,
            value: name,
            _cnfilter_ismorefilter: _cnfilter_ismorefilter,
            span: span,
        };
    }
    return null;
};

import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import $i18n from 'panda-i18n';
import pickBy from 'lodash/pickBy';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { formilyReact, formilyCore } from '@/form/formily';
import { CnFormItem } from '@/components/cn-form-item';
import { useItemCollection } from '../../hooks/use-item-collection';
import { getNormalizedDisplayName, getItemStyleInjectProps, getValueFormatterInjectProps, getFlowFormItemWidthByComponentName, } from '../../utils';
import { useFormItemLayout } from '../../hooks/use-form-item-layout';
var connect = formilyReact.connect, mapProps = formilyReact.mapProps, observer = formilyReact.observer, useField = formilyReact.useField;
export var CnFilterProItem = connect(observer(function (props) {
    var children = props.children, renderSelected = props.renderSelected, className = props.className, title = props.title, tip = props.tip, wrapperWidth = props.wrapperWidth, _a = props._shouldInsertProps, _shouldInsertProps = _a === void 0 ? true : _a, rest = __rest(props, ["children", "renderSelected", "className", "title", "tip", "wrapperWidth", "_shouldInsertProps"]);
    var field = useField();
    var componentType = field === null || field === void 0 ? void 0 : field.componentType;
    var currentWrapperWidth = wrapperWidth || getFlowFormItemWidthByComponentName(componentType);
    var formLayout = useFormItemLayout(rest);
    var _b = useItemCollection({
        renderSelected: renderSelected,
        label: title,
    }), setCollection = _b.setCollection, getCollectionInjectPorps = _b.getCollectionInjectPorps;
    var existName = false;
    var renderChildren = React.Children.map(children, function (child) {
        var childProps = (child === null || child === void 0 ? void 0 : child.props) || {};
        var style = __assign({}, childProps === null || childProps === void 0 ? void 0 : childProps.style);
        var initName = field === null || field === void 0 ? void 0 : field.path.toString();
        if (_shouldInsertProps && initName && !existName) {
            existName = true;
            var displayName = getNormalizedDisplayName(child === null || child === void 0 ? void 0 : child.type);
            setCollection(initName, displayName);
            var collectionInjectProps = getCollectionInjectPorps(displayName);
            var valueFromatterInjectProps = getValueFormatterInjectProps(displayName);
            var styleInjectProps = getItemStyleInjectProps(displayName, childProps);
            return React.cloneElement(child, pickBy(__assign(__assign(__assign({ style: style }, collectionInjectProps), valueFromatterInjectProps), styleInjectProps)));
        }
        return React.cloneElement(child, {
            style: style,
        });
    });
    var renderTitle = function () {
        if ((formLayout === null || formLayout === void 0 ? void 0 : formLayout.removeEmptyLabel) && !title) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "label-text" }, (formLayout === null || formLayout === void 0 ? void 0 : formLayout.filterDisplay) === 'flex' ? (React.createElement(CnEllipsis, { style: {
                    maxWidth: 96,
                } }, title)) : (title))));
    };
    return (React.createElement(CnFormItem, __assign({}, formLayout, { title: renderTitle(), tip: tip, cnTooltipProps: {
            align: 't',
        }, wrapperWidth: currentWrapperWidth, className: classNames(className, 'cn-ui-filter-pro-item', {
            'label-top': !formLayout.labelAlign || formLayout.labelAlign === 'top',
        }) }), renderChildren));
}), mapProps(function (props, field) {
    if (formilyCore.isVoidField(field)) {
        return {
            readOnly: field.readOnly,
            title: field.title || props.title,
            asterisk: props.asterisk,
            extra: props.extra || field.description,
        };
    }
    if (!field)
        return props;
    var takeFeedbackStatus = function () {
        if (field.validating)
            return 'pending';
        return field.decoratorProps.feedbackStatus || field.validateStatus;
    };
    // 获取错误信息
    var takeMessage = function () {
        var split = function (messages) {
            return messages.reduce(function (buf, text) {
                if (!text)
                    return buf;
                return buf.concat(text);
            }, []);
        };
        if (field.validating)
            return;
        if (props.feedbackText)
            return props.feedbackText;
        if (field.selfErrors.length) {
            if (props.requiredMessage) {
                var requiredErrorsIndex = field.selfErrors.indexOf($i18n.get({
                    id: 'ThisFieldIsRequired',
                    dm: '该字段是必填字段',
                    ns: 'CnForm',
                }));
                if (requiredErrorsIndex > -1) {
                    field.selfErrors.splice(requiredErrorsIndex, 1, props.requiredMessage);
                }
            }
            return split(field.selfErrors);
        }
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    // 获取是否有 required mark
    var takeAsterisk = function () {
        if (field.required && field.pattern !== 'readPretty') {
            return true;
        }
        if ('asterisk' in props) {
            return props.asterisk;
        }
        return false;
    };
    return {
        title: props.title || field.title,
        validateState: takeFeedbackStatus(),
        errors: takeMessage(),
        help: takeMessage(),
        asterisk: takeAsterisk(),
        extra: props.extra || field.description,
        readOnly: field.readOnly,
    };
}));

import { useFormLabelAlign } from '@cainiaofe/cn-ui-common';
/**
 * 获取字段标题列宽(固定宽度)
 * 1. 当 labelAlign 为 auto (自动布局)时，通过判断当前语言否是中文简繁自动生成上下左右布局
 */
export var useFormLabelCol = function (params) {
    var labelCol = params.labelCol;
    var labelAlign = useFormLabelAlign(params.labelAlign);
    // 当布局为左右布局，同时用户未配置列宽时
    if (labelAlign === 'left' && !labelCol) {
        return {
            fixedSpan: 5,
        };
    }
    // 非 auto 模式使用用户设置的值
    return labelCol;
};

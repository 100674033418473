import { withI18n } from 'panda-i18n';
import { List as NextList } from '@/components/fusion';
import locale from '@/locales';
import { ListWrapper } from './view/list-wrapper';
export var CnList = withI18n(ListWrapper, {
    componentName: 'CnList',
    locale: locale,
    forwardRef: true,
});
/**
 * @deprecated 请使用 CnList.Item
 */
CnList.Item = NextList.Item;
var CnListItem = NextList.Item;
export { CnListItem };

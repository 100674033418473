var DEFAULT_COLS = 4;
export function calcCols(props) {
    var width = props.width;
    if (width <= 428) {
        return 1;
    }
    if (width > 428 && width <= 894) {
        return 2;
    }
    if (width > 894 && width <= 1494) {
        return 4;
    }
    if (width > 1494) {
        return 6;
    }
    return DEFAULT_COLS;
}

import { __assign } from "tslib";
// 第三方依赖
import React, { useState } from 'react';
import $i18n from 'panda-i18n';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
// "@/” 开头的绝对依赖
import { CnBalloon } from '@/components/cn-balloon';
import { CnMenu, CnMenuItem } from '@/components/cn-menu';
import { CnButton } from '@/components/cn-button';
import { CnTooltip } from '@/components/cn-tooltip';
import { isPromise } from '@/components/cn-utils';
import { globalConfig } from '../config';
import { tableFullScreenArmsReport, tableDensityArmsReport, } from '../arms';
// 当前目录的相对依赖
import ColumnSet from './column-set';
import { FullScreenIcon, SizeIcon, ZebraIcon } from './icons';
import './index.scss';
export default function Toolbar(props) {
    // 工具栏组件的 props
    var batchArea = props.batchArea, toolArea = props.toolArea, settings = props.settings, tableInfo = props.tableInfo;
    // 是否显示选中数量和总数
    var _a = tableInfo || {}, showSelectedNum = _a.showSelectedNum, total = _a.total;
    var leftList = Array.isArray(batchArea) ? batchArea : []; // 工具栏左侧的按钮列表
    var rightList = Array.isArray(toolArea) ? toolArea : []; // 工具栏右侧的按钮列表
    var settingList = Array.isArray(settings) ? settings : []; // 工具栏的设置列表
    var hasToolBar = !isEmpty(settingList) || !isEmpty(leftList) || !isEmpty(rightList); // 是否有工具栏
    // 总数显示逻辑 首先有工具栏，其次有选择数量或者总数
    var showTotalInfo = hasToolBar && (showSelectedNum || !isNil(total)); // 是否显示总数信息
    // 根据设置类型排序
    function sortSetting(a, b) {
        var settingsValue = {
            size: 0,
            zebra: 1,
            fullscreen: 2,
            columnSet: 3,
        };
        var valueA = typeof a === 'string' ? settingsValue[a] : settingsValue === null || settingsValue === void 0 ? void 0 : settingsValue[a.type];
        var valueB = typeof b === 'string' ? settingsValue[b] : settingsValue === null || settingsValue === void 0 ? void 0 : settingsValue[b.type];
        return valueA - valueB;
    }
    return (React.createElement("div", { className: "cn-table-toolbar" },
        React.createElement("div", { className: "cn-table-toolbar-left" },
            showTotalInfo ? React.createElement(TotalInfo, { tableInfo: tableInfo }) : null,
            leftList.map(function (item, index) { return (React.createElement(RenderNormalItem, { key: index, item: item, opts: tableInfo, index: index })); })),
        React.createElement("div", { className: "cn-table-toolbar-right" },
            rightList.map(function (item, index) { return (React.createElement(RenderNormalItem, { key: index, item: item, opts: tableInfo, index: index })); }),
            (settingList === null || settingList === void 0 ? void 0 : settingList.length) > 0 ? (React.createElement("div", { className: "cn-table-toolbar-settings", key: "cn-table-toolbar-settings" }, settingList
                .sort(sortSetting)
                .map(function (item, index) {
                return renderSettings(item, tableInfo, index);
            }))) : null)));
}
function TotalInfo(props) {
    var _a;
    var tableInfo = props.tableInfo;
    var _b = tableInfo || {}, showSelectedNum = _b.showSelectedNum, selectKeys = _b.selectKeys, total = _b.total, totalSlot = _b.totalSlot;
    if (totalSlot) {
        return totalSlot;
    }
    return (React.createElement("span", { className: "cn-table-toolbar-left__select" },
        showSelectedNum ? (React.createElement(React.Fragment, null,
            $i18n.get({ id: 'Select', dm: '选中', ns: 'CnTable' }),
            React.createElement("span", { className: "cn-table-toolbar-highlight" }, (_a = selectKeys === null || selectKeys === void 0 ? void 0 : selectKeys.length) !== null && _a !== void 0 ? _a : 0),
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnTable',
            }))) : null,
        showSelectedNum && !isNil(total) ? (React.createElement("span", { className: "cn-table-toolbar-highlight" }, "/")) : null,
        !isNil(total) ? (React.createElement(React.Fragment, null,
            $i18n.get({ id: 'Total', dm: '共', ns: 'CnTable' }),
            React.createElement("span", { className: "cn-table-toolbar-all" }, total),
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnTable',
            }))) : null));
}
function useRenderButtonLoading(item) {
    var _a;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    if (!item) {
        return {
            ButtonOnClick: function () { },
            loading: false,
        };
    }
    if (((_a = item === null || item === void 0 ? void 0 : item.buttonProps) === null || _a === void 0 ? void 0 : _a.loading) && isFunction(item === null || item === void 0 ? void 0 : item.onClick)) {
        var wrapOnClick = new Proxy(item === null || item === void 0 ? void 0 : item.onClick, {
            apply: function (arget, object, args) {
                setLoading(true);
                var result = arget.apply(void 0, args);
                if (isPromise(result)) {
                    result.finally(function () {
                        setLoading(false);
                    });
                }
                else {
                    setLoading(false);
                }
                return result;
            },
        });
        return {
            ButtonOnClick: wrapOnClick,
            loading: loading,
        };
    }
    if (isFunction(item === null || item === void 0 ? void 0 : item.onClick)) {
        return {
            ButtonOnClick: item.onClick,
            loading: false,
        };
    }
    return {
        ButtonOnClick: function () { },
        loading: false,
    };
}
function RenderNormalItem(_a) {
    var _b, _c, _d, _e;
    var item = _a.item, opts = _a.opts, index = _a.index;
    var _f = opts || {}, selectKeys = _f.selectKeys, storeSelectInfo = _f.storeSelectInfo;
    var _g = useRenderButtonLoading(item), loading = _g.loading, ButtonOnClick = _g.ButtonOnClick;
    if (!item)
        return null;
    if ((item === null || item === void 0 ? void 0 : item.$$typeof) === Symbol.for('react.element')) {
        return React.cloneElement(item, __assign(__assign({}, ((item === null || item === void 0 ? void 0 : item.props) || {})), (isFunction(item === null || item === void 0 ? void 0 : item.type)
            ? {
                selectKeys: selectKeys,
                selectRecords: (_b = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _b === void 0 ? void 0 : _b[1],
            }
            : {})));
    }
    if (typeof item === 'function') {
        return item(selectKeys, (_c = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _c === void 0 ? void 0 : _c[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current);
    }
    if (typeof item.visible === 'function' &&
        !item.visible(selectKeys, (_d = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _d === void 0 ? void 0 : _d[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current)) {
        return null;
    }
    return (React.createElement(CnButton, __assign({ "data-hottag": "cn-ui.cn-table.".concat(item === null || item === void 0 ? void 0 : item.children), key: index, type: item === null || item === void 0 ? void 0 : item.type, onClick: function (event) {
            var _a;
            ButtonOnClick(event, selectKeys, (_a = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _a === void 0 ? void 0 : _a[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current);
        }, disabled: item && isFunction(item.isDisabled)
            ? item.isDisabled(selectKeys, (_e = storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current) === null || _e === void 0 ? void 0 : _e[1], storeSelectInfo === null || storeSelectInfo === void 0 ? void 0 : storeSelectInfo.current)
            : false }, ((item === null || item === void 0 ? void 0 : item.buttonProps) || {}), { loading: loading }), item === null || item === void 0 ? void 0 : item.children));
}
export function renderSettings(item, opts, index) {
    if (item === 'size' || (item === null || item === void 0 ? void 0 : item.type) === 'size') {
        return React.createElement(RenderSize, { key: index, setSize: opts.setSize, size: opts.size });
    }
    if (item === 'fullscreen' || (item === null || item === void 0 ? void 0 : item.type) === 'fullscreen') {
        return (React.createElement(RenderFullScreenButton, { key: index, isFullScreen: opts === null || opts === void 0 ? void 0 : opts.fullScreen, setFullScreen: opts.setFullScreen, index: index })); // RenderFullScreenButton(opts?.fullScreen, opts?.setFullScreen, index);
    }
    if (item === 'columnSet' || (item === null || item === void 0 ? void 0 : item.type) === 'columnSet') {
        return (React.createElement(ColumnSet, { key: index, columns: opts.columns, setColumns: opts.setColumns, zIndex: (item === null || item === void 0 ? void 0 : item.zIndex) || 1001 }));
    }
    if (item === 'zebra' || (item === null || item === void 0 ? void 0 : item.type) === 'zebra') {
        return (React.createElement(ZebraSetting, { key: index, zebra: opts.zebra, setZebra: opts.setZebra }));
    }
}
export function ZebraSetting(props) {
    var zebra = props.zebra, setZebra = props.setZebra;
    return (React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { "data-testid": "zebra-setting", "data-hottag": "cn-ui.cn-table.zebra-setting", className: "cn-table-toolbar-settings-item", onClick: function () { return setZebra(!zebra); } },
            React.createElement(ZebraIcon, { zebra: zebra, size: 15 })) }, $i18n.get({ id: 'ZebraCrossing', dm: '斑马线', ns: 'CnTable' })));
}
export function RenderFullScreenButton(_a) {
    var isFullScreen = _a.isFullScreen, setFullScreen = _a.setFullScreen, index = _a.index;
    React.useEffect(function () {
        function handleCancelFullScreen(event) {
            if (event.keyCode === 27) {
                setFullScreen(false);
            }
        }
        window.addEventListener('keydown', handleCancelFullScreen);
        return function () {
            window.removeEventListener('keydown', handleCancelFullScreen);
        };
    }, []);
    return (React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { "data-testid": "cn-table-toolbar-fullscreen-button", "data-hottag": "cn-ui.cn-table.full-screen", className: "cn-table-toolbar-settings-item", key: index, onClick: function () {
                setFullScreen(!isFullScreen);
                tableFullScreenArmsReport();
            } },
            React.createElement(FullScreenIcon, { isFullScreen: isFullScreen })) }, isFullScreen
        ? $i18n.get({ id: 'CancelFullScreen', dm: '取消全屏', ns: 'CnTable' })
        : $i18n.get({ id: 'FullScreen', dm: '全屏', ns: 'CnTable' })));
}
function RenderSize(props) {
    var size = props.size, setSize = props.setSize;
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var clickHandler = React.useCallback(function (val) {
        setVisible(false);
        setSize(val === null || val === void 0 ? void 0 : val[0]);
        tableDensityArmsReport();
    }, [setSize]);
    return (React.createElement(CnBalloon, { style: { padding: 0 }, trigger: React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { "data-hottag": "cn-ui.cn-table.size-setting", className: "cn-table-toolbar-settings-item", onClick: function () { return setVisible(true); }, style: { height: 'auto' } },
                React.createElement(SizeIcon, null)) }, $i18n.get({ id: 'Dimensions', dm: '尺寸', ns: 'CnTable' })), visible: visible, onVisibleChange: setVisible, triggerType: "click", align: "bl", closable: false },
        React.createElement(CnMenu, { selectMode: "single", selectedKeys: size, onSelect: clickHandler, style: {
                border: 0,
            } }, ((globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.sizeList()) || []).map(function (sizeOption) { return (React.createElement(CnMenuItem, { key: sizeOption.value }, sizeOption.label)); }))));
}

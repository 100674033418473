import { __awaiter, __generator } from "tslib";
import { useI18n } from 'panda-i18n';
import React, { useState } from 'react';
import { Input, Overlay, Icon } from '@/components/fusion';
import { sendLog } from '@/components/cn-utils';
import { useFilterRefsContext } from '../../context';
import { SaveSelectedActions, SaveSelectedActionTypes } from '../../store';
import { CnButton } from '@/components/cn-button';
export var InputSaveOverlay = function (_a) {
    var _b;
    var enableSaveSelected = _a.enableSaveSelected, values = _a.values, beforeGetSaveSelected = _a.beforeGetSaveSelected, store = _a.store, dispatch = _a.dispatch;
    var $i18n = useI18n();
    var _c = useState(''), name = _c[0], setName = _c[1];
    var filterContext = useFilterRefsContext();
    var overlayVisible = store.overlayVisible, options = store.options;
    var overlayRef = filterContext.overlayRef;
    var handleClose = function () {
        dispatch({
            type: SaveSelectedActionTypes.SetOverlayVisible,
            payload: false,
        });
    };
    var asyncHandleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _values;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _values = values;
                    if (!beforeGetSaveSelected) return [3 /*break*/, 2];
                    return [4 /*yield*/, beforeGetSaveSelected(_values)];
                case 1:
                    _values = (_a.sent()) || _values;
                    _a.label = 2;
                case 2:
                    dispatch(SaveSelectedActions.saveSetOptions(store, {
                        data: _values,
                        name: name,
                    }));
                    setName('');
                    handleClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSave = function () {
        sendLog({
            id: 'cn-ui.cn-filter.saveSelectedOk',
        });
        asyncHandleSave();
    };
    var renderSaveValidate = function () {
        var sameItem = options.find(function (i) { return i.label === name; });
        if (sameItem) {
            return (React.createElement("div", { className: "cn-ui-filter-save-panel-msg" },
                React.createElement(Icon, { className: "cn-next-icon-warning", style: { color: '#FFA64E', marginRight: 5 } }),
                $i18n.get({
                    id: 'TheNameIsDuplicatedAndWill_401244060',
                    dm: '名称重复，保存后将覆盖',
                    ns: 'CnFilter',
                })));
        }
        return '';
    };
    if (!enableSaveSelected)
        return null;
    return (React.createElement(Overlay.Popup, { visible: overlayVisible, target: overlayRef.current, safeNode: overlayRef.current, onRequestClose: function () {
            dispatch({
                type: SaveSelectedActionTypes.SetOverlayVisible,
                payload: false,
            });
        }, align: "tc bc", container: function (e) { return e === null || e === void 0 ? void 0 : e.closest('.cn-ui-filter-pro'); } },
        React.createElement("div", { className: "cn-ui-filter-save-panel", style: { width: ((_b = overlayRef === null || overlayRef === void 0 ? void 0 : overlayRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth) || 200 } },
            React.createElement(Input, { value: name, onChange: setName }),
            renderSaveValidate(),
            React.createElement("div", { className: "cn-ui-filter-save-panel-btns" },
                React.createElement(CnButton, { onClick: function () {
                        dispatch({
                            type: SaveSelectedActionTypes.SetOverlayVisible,
                            payload: false,
                        });
                        sendLog({
                            id: 'cn-ui.cn-filter.saveSelectedCancel',
                        });
                    } }, $i18n.get({ id: 'cancel', dm: '取消', ns: 'CnFilter' })),
                React.createElement(CnButton, { type: "primary", onClick: handleSave }, $i18n.get({ id: 'Save', dm: '保存', ns: 'CnFilter' }))))));
};

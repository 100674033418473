import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnCheckbox } from '@/components/cn-checkbox';
import uniqBy from 'lodash/uniqBy';
import isNil from 'lodash/isNil';
import { rowSelectionSymbol } from '../../global';
import { flattenDeepChildren } from '@/components/cn-utils';
import { arrayUtils, getFunctionVal, isPlainObject, mergeCellProps, safeGetRowKey, } from '../../utils';
export var stateKey = 'multiSelect';
// @ts-ignore
var defaultIsDisabled = function (row) { var _a; return (_a = row === null || row === void 0 ? void 0 : row.disabled) !== null && _a !== void 0 ? _a : false; };
export function multiSelectPipeline(opts) {
    if (opts === void 0) { opts = {}; }
    return function multiSelectStep(pipeline) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var primaryKey = pipeline.ensurePrimaryKey(stateKey);
        var dataSource = flattenDeepChildren(pipeline.getDataSource());
        // @ts-ignore
        var isDisabled = (_a = opts.isDisabled) !== null && _a !== void 0 ? _a : defaultIsDisabled;
        var clickArea = (_b = opts.clickArea) !== null && _b !== void 0 ? _b : 'checkbox';
        var selectedRowKeys;
        if ('selectedRowKeys' in opts) {
            selectedRowKeys = opts.selectedRowKeys;
        }
        else if ('value' in opts) {
            selectedRowKeys = opts.value;
        }
        else if ((_c = pipeline.getStateAtKey(stateKey)) === null || _c === void 0 ? void 0 : _c.selectedRowKeys) {
            selectedRowKeys = (_d = pipeline.getStateAtKey(stateKey)) === null || _d === void 0 ? void 0 : _d.selectedRowKeys;
        }
        else {
            selectedRowKeys = opts.defaultValue;
        }
        selectedRowKeys = Array.isArray(selectedRowKeys) ? selectedRowKeys : [];
        var selectedRowRecords = (_f = (_e = (dataSource || [])).filter) === null || _f === void 0 ? void 0 : _f.call(_e, function (row, rowIndex) {
            return selectedRowKeys.includes(safeGetRowKey(primaryKey, row, rowIndex));
        });
        var lastKey = (_k = (_j = (_g = opts.lastKey) !== null && _g !== void 0 ? _g : (_h = pipeline.getStateAtKey(stateKey)) === null || _h === void 0 ? void 0 : _h.lastKey) !== null && _j !== void 0 ? _j : opts.defaultLastKey) !== null && _k !== void 0 ? _k : '';
        var onChange = function (nextValue, key, keys, action) {
            var _a, _b, _c, _d, _e, _f;
            if (['check', 'uncheck'].includes(action) &&
                opts.onSelect instanceof Function) {
                opts.onSelect(action === 'check', key, nextValue);
            }
            if (['check-all', 'uncheck-all'].includes(action) &&
                opts.onSelectAll instanceof Function) {
                opts.onSelectAll(action === 'check-all', nextValue);
            }
            // 获取当前选中的记录
            var selectedRecords = (_b = (_a = pipeline.getStateAtKey(stateKey)) === null || _a === void 0 ? void 0 : _a.selectedRecords) !== null && _b !== void 0 ? _b : [];
            // 获取下一次选中的记录
            var nextRecords = (_d = (_c = (dataSource || [])).filter) === null || _d === void 0 ? void 0 : _d.call(_c, function (row, rowIndex) {
                return nextValue.includes(safeGetRowKey(primaryKey, row, rowIndex));
            });
            var nextSelectedRecords = [];
            if (opts.crossPageEmpty) {
                // 是否跨页保存信息
                (_e = opts.onChange) === null || _e === void 0 ? void 0 : _e.call(opts, nextValue, nextRecords, key, keys, action); // 调用onChange回调
            }
            else {
                // 用全量的nextValue在当前的dataSource中找到records加入存储
                // 因为每次都是全量的nextValue 所以当前页数据会和存储数据出现重复
                var fullSelectedRecords = nextRecords === null || nextRecords === void 0 ? void 0 : nextRecords.concat(selectedRecords); // 全量选中的记录
                // 根据primaryKey去重保持数据唯一
                var uniqSelectedRecords_1 = uniqBy(fullSelectedRecords, primaryKey); // 去重后的选中记录
                // 全量数据根据nextValue过滤保持数据一致性
                nextSelectedRecords = nextValue
                    .map(function (value) {
                    return uniqSelectedRecords_1 === null || uniqSelectedRecords_1 === void 0 ? void 0 : uniqSelectedRecords_1.find(function (item) {
                        return !isNil(item) && safeGetRowKey(primaryKey, item, null) === value;
                    });
                })
                    .filter(function (item) { return !isNil(item); }); // 下一次选中的记录
                // takeNextSelectedRecords(fullSelectedRecords, primaryKey);
                (_f = opts.onChange) === null || _f === void 0 ? void 0 : _f.call(opts, nextValue, nextSelectedRecords, key, keys, action); // 调用onChange回调
            }
            pipeline.setStateAtKey(stateKey, {
                selectedRowKeys: nextValue,
                lastKey: key,
                selectedRecords: nextSelectedRecords !== null && nextSelectedRecords !== void 0 ? nextSelectedRecords : [],
            }, { keys: keys, action: action });
        };
        /** dataSource 中包含的所有 keys */
        var fullKeySet = new Set();
        /** 所有有效的 keys（disable 状态为 false） */
        var allKeys = [];
        (dataSource || []).forEach(function (row, rowIndex) {
            var rowKey = safeGetRowKey(primaryKey, row, rowIndex);
            fullKeySet.add(rowKey);
            // const selectedRowRecords = (dataSource || []).filter?.((row, rowIndex) =>
            //   selectedRowKeys.includes(safeGetRowKey(primaryKey, row, rowIndex)),
            // );
            // 在 allKeys 中排除被禁用的 key
            if (!isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords)) {
                allKeys.push(rowKey);
            }
        });
        var set = new Set(selectedRowKeys);
        var isAllChecked = allKeys.length > 0 && allKeys.every(function (key) { return set.has(key); });
        var isAnyChecked = allKeys.some(function (key) { return set.has(key); });
        var defaultCheckboxColumnTitle = (React.createElement(CnCheckbox, { style: {
                verticalAlign: 'middle',
            }, checked: isAllChecked, indeterminate: !isAllChecked && isAnyChecked, onChange: function () {
                if (isAllChecked) {
                    onChange(arrayUtils.diff(selectedRowKeys, allKeys), '', allKeys, 'uncheck-all');
                }
                else {
                    // merge 是为了防止丢失选中的key
                    onChange(arrayUtils.merge(selectedRowKeys, allKeys), '', allKeys, 'check-all');
                }
            } }));
        var checkboxColumn = __assign(__assign({ name: $i18n.get({ id: 'SelectOrNot', dm: '是否选中', ns: 'CnBaseTable' }), title: defaultCheckboxColumnTitle, width: 50, lock: true, attach: false, 
            // @ts-ignore
            sizeFixed: true, symbol: rowSelectionSymbol, align: 'center' }, ((opts === null || opts === void 0 ? void 0 : opts.columnProps) || {})), { getCellProps: function (value, row, rowIndex) {
                var _a;
                var rowKey = safeGetRowKey(primaryKey, row, rowIndex);
                var configProps = getFunctionVal((_a = opts === null || opts === void 0 ? void 0 : opts.columnProps) === null || _a === void 0 ? void 0 : _a.getCellProps, [value, row, rowIndex], {});
                if (fullKeySet.has(rowKey) && clickArea === 'cell') {
                    var prevChecked_1 = set.has(rowKey);
                    // const selectedRowRecords = (dataSource || []).filter?.(
                    //   (row, rowIndex) =>
                    //     selectedRowKeys.includes(
                    //       safeGetRowKey(primaryKey, row, rowIndex),
                    //     ),
                    // );
                    var disabled = isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords);
                    return mergeCellProps(isPlainObject(configProps) ? configProps : {}, {
                        style: { cursor: disabled ? 'not-allowed' : 'pointer' },
                        onClick: disabled
                            ? undefined
                            : function (e) {
                                if (opts.stopClickEventPropagation) {
                                    e.stopPropagation();
                                }
                                onCheckboxChange(prevChecked_1, rowKey, e.shiftKey);
                            },
                    });
                }
                return mergeCellProps(configProps, {
                    style: {
                        padding: 0,
                    },
                });
            }, render: function (_, row, rowIndex) {
                var key = safeGetRowKey(primaryKey, row, rowIndex);
                var checked = set.has(key);
                return (React.createElement(CnCheckbox, { checked: checked, style: {
                        verticalAlign: 'middle',
                    }, disabled: (function () {
                        return isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords);
                    })(), onChange: clickArea === 'checkbox'
                        ? function (arg1, arg2) {
                            var _a;
                            // 这里要同时兼容 antd 和 fusion 的用法
                            // fusion: arg2?.nativeEvent
                            // antd: arg1.nativeEvent
                            var nativeEvent = (_a = arg2 === null || arg2 === void 0 ? void 0 : arg2.nativeEvent) !== null && _a !== void 0 ? _a : arg1.nativeEvent;
                            if (nativeEvent) {
                                if (opts.stopClickEventPropagation) {
                                    nativeEvent.stopPropagation();
                                }
                                onCheckboxChange(checked, key, nativeEvent.shiftKey);
                            }
                        }
                        : undefined }));
            } });
        var nextColumns = pipeline.getColumns().slice();
        var placement = (_l = opts.placement) !== null && _l !== void 0 ? _l : 'start';
        if (placement === 'start') {
            nextColumns.unshift(checkboxColumn);
        }
        else {
            checkboxColumn.lock = 'right';
            nextColumns.push(checkboxColumn);
        }
        pipeline.columns(nextColumns);
        // @ts-ignore
        pipeline.appendRowPropsGetter(function (row, rowIndex) {
            var _a;
            var rowKey = safeGetRowKey(primaryKey, row, rowIndex);
            if (!fullKeySet.has(rowKey)) {
                // rowKey 不在 fullKeySet 中说明这一行是在 multiSelect 之后才生成的，multiSelect 不对之后生成的行进行处理
                return;
            }
            var style = {};
            var className;
            var onClick;
            var checked = set.has(rowKey);
            if (((_a = opts.highlightRowWhenSelected) !== null && _a !== void 0 ? _a : true) && checked) {
                className = 'highlight-cell';
            }
            if (clickArea === 'row') {
                var disabled = isDisabled(row, rowIndex, selectedRowKeys, selectedRowRecords);
                if (!disabled) {
                    style.cursor = 'pointer';
                    onClick = function (e) {
                        if (opts.stopClickEventPropagation) {
                            e.stopPropagation();
                        }
                        onCheckboxChange(checked, rowKey, e.shiftKey);
                    };
                }
            }
            // @ts-ignore
            return { className: className, style: style, onClick: onClick };
        });
        return pipeline;
        function onCheckboxChange(prevChecked, key, batch) {
            var batchKeys = [key];
            if (batch && lastKey) {
                var lastIdx = allKeys.indexOf(lastKey);
                var cntIdx = allKeys.indexOf(key);
                var _a = lastIdx < cntIdx ? [lastIdx, cntIdx] : [cntIdx, lastIdx], start = _a[0], end = _a[1];
                batchKeys = allKeys.slice(start, end + 1);
            }
            if (prevChecked) {
                // @ts-ignore
                onChange(arrayUtils.diff(selectedRowKeys, batchKeys), key, batchKeys, 'uncheck');
            }
            else {
                // @ts-ignore
                onChange(arrayUtils.merge(selectedRowKeys, batchKeys), key, batchKeys, 'check');
            }
        }
    };
}

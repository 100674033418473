import './mini-upload-list.scss';
import React from 'react';
import classNames from 'classnames';
import { Overlay } from '@fusion/lib';
import { CnTag } from '@/components/cn-tag';
import { FORM_EMPTY_CONTENT, useUploadState } from '@cainiaofe/cn-ui-common';
import { MiniUploadListItem } from './mini-upload-list-item';
export var MiniUploadList = function () {
    var _a;
    var _b = useUploadState(), rootProps = _b.props, getValue = _b.getValue;
    var fileList = getValue();
    if (!fileList.length) {
        if ((_a = rootProps.readOnlyProps) === null || _a === void 0 ? void 0 : _a.emptyRender) {
            return React.createElement(React.Fragment, null, rootProps.readOnlyProps.emptyRender());
        }
        return React.createElement(React.Fragment, null, FORM_EMPTY_CONTENT);
    }
    return (React.createElement(Overlay.Popup, { trigger: React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-oss-image-upload-mini-trigger') },
            fileList.slice(0, 3).map(function (item) { return (React.createElement("div", { key: item.key || item.name || item.url, className: "cn-oss-image-upload-mini-trigger__item" },
                React.createElement("img", { src: item.url }))); }),
            fileList.length > 3 ? (React.createElement(CnTag, { key: "item-tag", size: "small", type: "primary", className: "cn-oss-image-upload-mini-trigger__more" },
                fileList.length - 3,
                " +")) : null) },
        React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-oss-upload-list', 'cn-ui-oss-upload-list-mini') }, fileList.map(function (item) { return (React.createElement(MiniUploadListItem, { key: item.key, file: item, fileList: fileList })); }))));
};

export var cssPrefix = 'cn-ui-async-jobs-pro-';
/** 记录显示任务运行中引导 */
export var RUNNING_TIP_KEY = 'CnAsyncJobsPro.RunningTip';
/** 显示任务面板 */
export var SHOW_PANEL = 'CnAsyncJobsPro.showPanel';
/** 隐藏任务面板 */
export var HIDE_PANEL = 'CnAsyncJobsPro.hidePanel';
/** 轮询导出任务 */
export var LOOPING_EXPORT_JOB = 'CnAsyncJobsPro.loopingExportJob';
/** 轮询导入任务 */
export var LOOPING_IMPORT_JOB = 'CnAsyncJobsPro.loopingImportJob';

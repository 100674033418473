import { __assign, __makeTemplateObject } from "tslib";
import * as React from 'react';
import ReactDOM from 'react-dom';
import * as op from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import useUpdate from 'ahooks/lib/useUpdate';
import styled from 'styled-components';
import { isFunction, safeRenderHeader, isLeafNode, makeRecursiveMapper, collectNodes, safeCellPaddingWidth, } from '../../utils';
import { columnResizeChangeArmsReport } from '@/components/cn-table/view/arms';
import { DragLine } from './drag-line';
import { clamp } from './utils';
import { ColAbility } from './types';
import { getInitNextWidth } from './get-init-next-width';
var ResizeHandle = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: -5px;\n  width: 10px;\n  cursor: col-resize;\n  z-index: 1001;\n"], ["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: -5px;\n  width: 10px;\n  cursor: col-resize;\n  z-index: 1001;\n"])));
export function columnsResizePipeline(resizeOption) {
    var columnResize = resizeOption.columnResize, widthsRef = resizeOption.widthsRef, dragLineRef = resizeOption.dragLineRef, ability = resizeOption.ability, update = resizeOption.update, size = resizeOption.size;
    // 允许拖拽
    var allowResize = ability === ColAbility.widthAndAsyncResize ||
        ability === ColAbility.widthAndResize;
    // 是否可异步拖拽
    var allowColAsyncDrag = ability === ColAbility.widthAndAsyncResize;
    var _a = columnResize || {}, _b = _a.minWidth, minWidth = _b === void 0 ? 4 : _b, _c = _a.maxWidth, maxWidth = _c === void 0 ? Infinity : _c, _d = _a.disableUserSelectWhenResizing, disableUserSelectWhenResizing = _d === void 0 ? true : _d;
    var asyncDragColHandler = function (val) {
        var _a, _b;
        isFunction((_a = dragLineRef === null || dragLineRef === void 0 ? void 0 : dragLineRef.current) === null || _a === void 0 ? void 0 : _a.setOffset) &&
            ((_b = dragLineRef === null || dragLineRef === void 0 ? void 0 : dragLineRef.current) === null || _b === void 0 ? void 0 : _b.setOffset(val));
    };
    var onChange = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!(columnResize === null || columnResize === void 0 ? void 0 : columnResize.widths)) {
            widthsRef.current = args[0];
            update();
        }
        if ((columnResize === null || columnResize === void 0 ? void 0 : columnResize.onChange) instanceof Function) {
            columnResize.onChange.apply(columnResize, args);
        }
        columnResizeChangeArmsReport();
    };
    var widths = widthsRef.current;
    var startResize = function (nowColumn, startIndex, allLeafColumns, e) {
        var _a;
        // 记录鼠标开始位置
        var startX = e.clientX;
        // 异步拖拽记录
        var asyncRecord = null;
        // 当前列的 code
        var nowColCode = (_a = nowColumn === null || nowColumn === void 0 ? void 0 : nowColumn.code) !== null && _a !== void 0 ? _a : startIndex;
        var nowWidths = __assign(__assign({}, (getInitNextWidth(e, allLeafColumns) || {})), (widthsRef.current || {})); // 获取初始宽度
        var nextWidths = cloneDeep(nowWidths); // 下一个宽度
        var nextWidths$ = fromEvent(window, 'mousemove').pipe(
        // 监听鼠标移动事件
        op.takeUntil(fromEvent(window, 'mouseup')), // 监听鼠标抬起事件
        op.map(function (event) {
            // 处理鼠标移动事件
            var movingX = event.clientX; // 获取鼠标移动位置
            var changeWidth = movingX - startX; // 计算宽度变化量
            // 如果当前列是叶子节点
            if (isLeafNode(nowColumn)) {
                // 计算下一个宽度
                nextWidths[nowColCode] = clamp(minWidth, nowWidths[nowColCode] + changeWidth, maxWidth);
            }
            else {
                // 如果当前列有孩子节点
                var leafColumns = collectNodes(
                // 获取所有叶子节点
                nowColumn.children, 'leaf-only');
                var avgChangeWidth_1 = changeWidth / leafColumns.length; // 计算平均宽度变化量
                makeRecursiveMapper(function (col, range) {
                    var _a;
                    // 遍历所有节点
                    if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                        // 如果是叶子节点
                        var colCode = (_a = col === null || col === void 0 ? void 0 : col.code) !== null && _a !== void 0 ? _a : range === null || range === void 0 ? void 0 : range.startIndex; // 获取节点 code
                        nextWidths[colCode] = clamp(
                        // 计算下一个宽度
                        minWidth, nowWidths[colCode] + avgChangeWidth_1, maxWidth);
                    }
                    return col;
                })(nowColumn.children, startIndex);
            }
            // 返回下一个宽度、当前列 code、当前列宽度、鼠标移动位置
            return [nextWidths, nowColCode, nextWidths[nowColCode], movingX];
        }));
        var prevUserSelect = ''; // 保存之前的 user-select 样式
        var docElemStyle;
        if (disableUserSelectWhenResizing) {
            // 如果需要禁用 user-select 样式
            docElemStyle = document.documentElement.style;
            prevUserSelect = docElemStyle.userSelect; // 保存之前的 user-select 样式
            docElemStyle.userSelect = 'none'; // 禁用 user-select 样式
            docElemStyle.cursor = 'col-resize'; // 设置鼠标样式
        }
        nextWidths$.subscribe({
            // 监听下一个宽度
            next: function (args) {
                if (allowColAsyncDrag) {
                    // 如果允许异步拖拽
                    asyncRecord = args; // 保存结果
                    asyncDragColHandler(args[3]); // 设置异步拖拽位置
                }
                else {
                    onChange.apply(void 0, args); // 更新宽度
                }
            },
            complete: function () {
                if (allowColAsyncDrag && asyncRecord) {
                    // 如果允许异步拖拽且有异步拖拽记录
                    onChange.apply(void 0, asyncRecord); // 更新宽度
                    asyncDragColHandler(0); // 重置异步拖拽位置
                }
                if (disableUserSelectWhenResizing) {
                    // 如果需要禁用 user-select 样式
                    docElemStyle.userSelect = prevUserSelect; // 恢复之前的 user-select 样式
                    docElemStyle.cursor = 'unset'; // 重置鼠标样式
                }
            },
        });
    };
    var innerTransform = function (columns) {
        // let totalWidthCalByColumn = 0;
        var leafColumns = [];
        var traverseAndGetData = makeRecursiveMapper(
        // @ts-ignore
        function (col, range) {
            var _a, _b, _c;
            if (!col) {
                return col;
            }
            var prevTitle = safeRenderHeader(col);
            var code = col.code || range.startIndex;
            var resizable = 'resizable' in col ? col === null || col === void 0 ? void 0 : col.resizable : true;
            var colWidth = (_b = (_a = widths[code]) !== null && _a !== void 0 ? _a : col.width) !== null && _b !== void 0 ? _b : safeCellPaddingWidth(size);
            if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                // totalWidthCalByColumn += colWidth;
                leafColumns.push(__assign(__assign({}, (col || {})), { range: range }));
            }
            var resColumn = resizable && allowResize
                ? __assign(__assign({}, col), { width: colWidth, title: (React.createElement(React.Fragment, null,
                        prevTitle,
                        React.createElement(ResizeHandle, { className: "resize-handle", onMouseDown: function (e) {
                                return startResize(col, range === null || range === void 0 ? void 0 : range.startIndex, leafColumns, e);
                            } }))), headerCellProps: __assign(__assign({}, col.headerCellProps), { style: __assign(__assign({}, (_c = col.headerCellProps) === null || _c === void 0 ? void 0 : _c.style), { overflow: 'visible', position: 'relative' }) }) }) : __assign(__assign({}, col), { width: colWidth });
            return resColumn;
        });
        // @ts-ignore
        var resColumns = traverseAndGetData(columns);
        return resColumns;
    };
    return function (pipeline) {
        var columns = innerTransform(pipeline.getColumns());
        return pipeline.columns(columns);
    };
}
// @ts-ignore
export function AsyncDragLine(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var resize = _a.resize, tableRef = _a.tableRef;
    var update = useUpdate();
    if (resize === null || resize === void 0 ? void 0 : resize.dragLineUpdateRef) {
        resize.dragLineUpdateRef.current = update;
    }
    return (resize === null || resize === void 0 ? void 0 : resize.ability) === ColAbility.widthAndAsyncResize &&
        ((_c = (_b = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _b === void 0 ? void 0 : _b.domHelper) === null || _c === void 0 ? void 0 : _c.artTableWrapper)
        ? ReactDOM.createPortal(React.createElement(DragLine, { ref: resize === null || resize === void 0 ? void 0 : resize.dragLineRef, tableClientX: ((_f = (_e = (_d = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _d === void 0 ? void 0 : _d.domHelper) === null || _e === void 0 ? void 0 : _e.artTableWrapper) === null || _f === void 0 ? void 0 : _f.getBoundingClientRect) instanceof Function
                ? (_j = (_h = (_g = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _g === void 0 ? void 0 : _g.domHelper) === null || _h === void 0 ? void 0 : _h.artTableWrapper) === null || _j === void 0 ? void 0 : _j.getBoundingClientRect().x
                : 0 }), (_l = (_k = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _k === void 0 ? void 0 : _k.domHelper) === null || _l === void 0 ? void 0 : _l.artTableWrapper)
        : null;
}
export { useAutoWidth } from './use-auto-width';
export { useColumnsResize } from './use-columns-resize';
export * from './types';
var templateObject_1;

export var uninjectSet = new Set([
    'Base',
    'NumberPicker',
    'DatePicker2',
    'RangePicker2',
    'WeekPicker2',
    'MonthPicker2',
    'QuarterPicker2',
    'YearPicker2',
    'Range',
    'Rating',
    'Switch',
]);

import { __assign, __awaiter, __generator, __rest } from "tslib";
/**
 * 基础 request method, 基于 CNRequest 实现 (背后是 axios 实例).
 * 这里可以做一些业务的特殊定制, 比如说统一的埋点上报, 以及通用的网络错误处理.
 */
import * as React from 'react';
import { request, getRequest } from 'cn-request';
import { useRequest } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
export function packRequest(options) {
    if (!getRequest()) {
        console.error('组件使用默认实例中...请先初始化项目cn-request,切勿发布到生产环境');
    }
    return request(options).then(function (result) {
        if ('request' in result && 'config' in result) {
            return result.data;
        }
        return result;
    });
}
export function unpackRequest(options) {
    return __awaiter(this, void 0, Promise, function () {
        var _a, data, success, errorMsg;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, packRequest(options)];
                case 1:
                    _a = _b.sent(), data = _a.data, success = _a.success, errorMsg = _a.errorMsg;
                    if (!success) {
                        throw new Error(errorMsg);
                    }
                    return [2 /*return*/, data];
            }
        });
    });
}
export function useCnRequest(requestConfig, plugins) {
    var service = requestConfig.service, _a = requestConfig.method, method = _a === void 0 ? 'GET' : _a, paramsProps = requestConfig.params, dataProps = requestConfig.data, formatResult = requestConfig.formatResult, _b = requestConfig.formatParam, formatParam = _b === void 0 ? function (data) { return data; } : _b;
    var paramsFormatter = requestConfig.paramsFormatter || formatParam;
    var resultFormatter = requestConfig.resultFormatter || formatResult;
    var requestService = React.useMemo(function () {
        if (service) {
            return service;
        }
        return function (runParams) {
            var params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET' &&
                typeof paramsFormatter === 'function'
                ? paramsFormatter(__assign(__assign({}, runParams), paramsProps))
                : paramsProps;
            var data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST' &&
                typeof paramsFormatter === 'function'
                ? paramsFormatter(__assign(__assign({}, runParams), dataProps))
                : dataProps;
            return packRequest(__assign(__assign({}, requestConfig), { params: params, data: data }));
        };
    }, [requestConfig]);
    var _c = useRequest(requestService, __assign({ ready: !!(requestConfig.url || requestConfig.service) }, requestConfig), plugins), res = _c.data, otherResult = __rest(_c, ["data"]);
    var data = React.useMemo(function () {
        if (isEmpty(res)) {
            return res;
        }
        if (typeof resultFormatter === 'function') {
            return resultFormatter(res);
        }
        if (!isEmpty(res === null || res === void 0 ? void 0 : res.data)) {
            return res === null || res === void 0 ? void 0 : res.data;
        }
    }, [res]);
    return __assign(__assign({}, otherResult), { data: data });
}

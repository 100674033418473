import { withI18n } from 'panda-i18n';
import { events } from '@/components/cn-utils';
import locale from '@/locales';
import { HIDE_PANEL, SHOW_PANEL } from './view/constant/constant';
import { CnAsyncJobs as CnAsyncJobsView } from './view/async-jobs-pro';
export var CnAsyncJobsPro = withI18n(CnAsyncJobsView, {
    locale: locale,
    componentName: 'CnAsyncJobsPro',
});
CnAsyncJobsPro.showPanel = function (options) {
    events.emit(SHOW_PANEL, options);
};
CnAsyncJobsPro.hidePanel = function () {
    events.emit(HIDE_PANEL);
};

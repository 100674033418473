import { useCallback, useEffect, useState } from 'react';
import { RUNNING_TIP_KEY, } from '../../constant/constant';
/**
 * 提示只在用户第一次进入页面时显示
 * @param setRunningTip
 */
export var useJobRunning = function (props) {
    var panelVisible = props.panelVisible, hasUnreadJob = props.hasUnreadJob;
    var _a = useState(false), runningTip = _a[0], setRunningTip = _a[1];
    var hideRunningTip = useCallback(function () {
        setRunningTip(false);
        window.localStorage.setItem(RUNNING_TIP_KEY, '1');
    }, []);
    useEffect(function () {
        if (window.localStorage.getItem(RUNNING_TIP_KEY)) {
            return;
        }
        if (!panelVisible && hasUnreadJob) {
            setRunningTip(true);
        }
    }, [hasUnreadJob, panelVisible]);
    return {
        runningTip: runningTip,
        hideRunningTip: hideRunningTip,
    };
};

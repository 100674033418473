import moment from 'moment';
import dayjs from 'dayjs';
import { childrenToDataSource, defaultDateFormat, } from '../../utils';
import { formatWithDataSource } from './format-with-data-source';
export var formatMap = new Map([
    [
        'CheckboxGroup',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Checkbox', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'RadioGroup',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Radio', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'CascaderSelect',
        function (key, value, child, cache) {
            var dataSource = ((child === null || child === void 0 ? void 0 : child.props) || {}).dataSource;
            if (!(value === null || value === void 0 ? void 0 : value.length))
                return '';
            return formatWithDataSource(key, value, dataSource, cache);
        },
    ],
    [
        'DatePicker',
        function (_, value, child) {
            var _a;
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.date;
            if (props.showTime) {
                format = "".concat(format, " ").concat(((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) || defaultDateFormat.time);
            }
            return moment(value).format(format);
        },
    ],
    [
        'RangePicker',
        function (_, value, child) {
            var _a;
            if (!(value === null || value === void 0 ? void 0 : value.length))
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.date;
            if (props.showTime) {
                format = "".concat(format, " ").concat(((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) || defaultDateFormat.time);
            }
            if (typeof value === 'string')
                return value;
            return value
                .map(function (val) { return (val ? moment(val).format(format) : ''); })
                .join(' ~ ');
        },
    ],
    [
        'WeekPicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.week;
            return moment(value).format(format);
        },
    ],
    [
        'MonthPicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.month;
            return moment(value).format(format);
        },
    ],
    [
        'YearPicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.year;
            return moment(value).format(format);
        },
    ],
    [
        'Picker',
        function (_, value, child) {
            var _a, _b;
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var type = props.type;
            var mode = (props === null || props === void 0 ? void 0 : props.mode) || 'date';
            if (type === 'range') {
                if (!(value === null || value === void 0 ? void 0 : value.length))
                    return '';
                var format_1 = props.format ||
                    defaultDateFormat[mode] ||
                    defaultDateFormat.date;
                if (props.mode === 'date' && props.showTime) {
                    format_1 = "".concat(format_1, " ").concat(((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) ||
                        defaultDateFormat.time);
                }
                if (typeof value === 'string')
                    return value;
                return value
                    .map(function (val) { return (val ? dayjs(val).format(format_1) : ''); })
                    .join(' ~ ');
            }
            var format = props.format ||
                defaultDateFormat[mode] ||
                defaultDateFormat.date;
            if (mode === 'date' && props.showTime) {
                format = "".concat(format, " ").concat(((_b = props.showTime) === null || _b === void 0 ? void 0 : _b.format) || defaultDateFormat.time);
            }
            return dayjs(value).format(format);
        },
    ],
    [
        'Select',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Option', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'TimePicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.time;
            return moment(value).format(format);
        },
    ],
    [
        'TimePicker2',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.time;
            if (typeof value === 'string') {
                return value;
            }
            if (Array.isArray(value)) {
                return value
                    .map(function (val) {
                    if (typeof val === 'string') {
                        return val;
                    }
                    return val ? dayjs(val).format(format) : '';
                })
                    .join(' ~ ');
            }
            return dayjs(value).format(format);
        },
    ],
    [
        'TreeSelect',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'TreeNode', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.label,
                    };
                });
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
]);

import { __assign, __rest } from "tslib";
import './index.scss';
import React from 'react';
import classNames from 'classnames';
import { FORM_EMPTY_CONTENT, useUploadState } from '@cainiaofe/cn-ui-common';
import { UploadListItem } from '../upload-list-item';
export var UploadList = function (props) {
    var _a = useUploadState(), readOnly = _a.readOnly, getValue = _a.getValue, rootProps = _a.props;
    var limit = rootProps.limit;
    var showUploadList = props.showUploadList, restProps = __rest(props, ["showUploadList"]);
    if (!showUploadList)
        return null;
    var fileList = getValue();
    if (!fileList.length) {
        if (readOnly) {
            return React.createElement(React.Fragment, null, FORM_EMPTY_CONTENT);
        }
        return null;
    }
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-oss-upload-list', 'cn-ui-oss-upload-list-normal') }, fileList.slice(0, limit).map(function (item, index) { return (React.createElement(UploadListItem, __assign({ key: item.key || "".concat(item.name, "_").concat(index) }, restProps, { file: item }))); })));
};

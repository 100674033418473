import { __assign } from "tslib";
import * as React from 'react';
import omit from 'lodash/omit';
import { BaseTable } from './base';
import { getFooterDataSource } from '../pipelines';
import { stylegen, classNamegen } from './config';
import './index.scss';
var HighPerformanceTable = React.forwardRef(function (props, ref) {
    var pipeline = props.pipeline, propsFooterDataSource = props.footerDataSource, footerRule = props.footerRule;
    var propsToOmit = [
        'pipeline',
        'primaryKey',
        'dataSource',
        'columns',
        'rowSelection',
        'columnResize',
        'columnsAutoWidth',
        'columnsAutoWidthOpt',
        'operateColumn',
        'rowDetail',
        'filter',
        'showIndex',
        'index',
        'size',
        'footerDataSource',
        'footerRule',
        'colAttach',
        'onSort',
        'sortCustom',
        'treeConfig',
        'tree',
        'cellProps',
        'className',
        'crossline',
        'crosslineConfig',
        'isZebra',
        'zebraColor',
        'tableRef',
    ];
    var restProps = omit(props, propsToOmit);
    var footerDataSource = propsFooterDataSource !== null && propsFooterDataSource !== void 0 ? propsFooterDataSource : getFooterDataSource(pipeline, footerRule);
    return (React.createElement(BaseTable, __assign({ "data-name": "CnBaseTable" }, restProps, { footerDataSource: footerDataSource, ref: ref, className: classNamegen(props), style: stylegen(props) }, pipeline.getProps())));
});
HighPerformanceTable.displayName = 'HighPerformanceTable';
export default HighPerformanceTable;

import { __assign } from "tslib";
import $i18n, { withI18n } from 'panda-i18n';
import * as React from 'react';
import locale from '@/locales';
import { Input } from '@/components/fusion';
import { HocBaseComponents, Plugin, pluginManager, } from '@/components/cn-utils';
import { nextCompMap } from './next-comp-map';
export var CnInputTextAreaBase = nextCompMap(Input.TextArea, 'CnInputTextArea');
var CnInputTextAreaView = React.forwardRef(function (props, ref) {
    var _a, _b, _c;
    var placeholder = props.placeholder ||
        $i18n.get({ id: 'TERM.Enter', dm: '请输入', ns: 'CnInput' });
    var readOnlyProps = __assign(__assign({}, props.readOnlyProps), { cnTextEllipsisProps: __assign(__assign({ line: 5 }, (_a = props.readOnlyProps) === null || _a === void 0 ? void 0 : _a.cnTextEllipsisProps), { style: __assign(__assign({}, (_c = (_b = props.readOnlyProps) === null || _b === void 0 ? void 0 : _b.cnTextEllipsisProps) === null || _c === void 0 ? void 0 : _c.style), { whiteSpace: 'break-spaces' }) }) });
    var plugin = React.useMemo(function () {
        var _plugin = new Plugin('CnInputTextArea', pluginManager);
        _plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        _plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnInputTextArea')
            .map(function (item) { return item.method; }));
        return _plugin;
    }, []);
    var plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return (React.createElement(CnInputTextAreaBase, __assign({}, props, { readOnlyProps: readOnlyProps, placeholder: placeholder, ref: ref })));
    }
    return HocBaseComponents(CnInputTextAreaBase, {
        props: __assign(__assign({}, props), { placeholder: placeholder, readOnlyProps: readOnlyProps }),
        plugins: plugins,
        ref: ref,
    });
});
CnInputTextAreaView.displayName = 'CnInputTextAreaView';
export var CnInputTextArea = withI18n(CnInputTextAreaView, {
    locale: locale,
    componentName: 'CnInputTextArea',
});

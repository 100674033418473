import { isEmpty } from '../../utils';
import { formatRenderItem } from './format-render-item';
export function getRenderItems(values, filterItemColloction, selectedCacheRef) {
    return Object.entries(values || {})
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        if (isEmpty(value) ||
            (Array.isArray(value) && !value.filter(function (val) { return !isEmpty(val); }).length)) {
            return null;
        }
        var _b = formatRenderItem(key, value, filterItemColloction, selectedCacheRef.current), text = _b.text, valueText = _b.valueText;
        return {
            key: key,
            value: value,
            text: text,
            valueText: valueText,
        };
    })
        .filter(Boolean);
}

import { events } from '@fusion/lib/util';
import debounce from 'lodash/debounce';
var timeout;
/**
 * 用于监听当前页面到达哪一个锚点
 * @param dataSource 数据源
 * @param container 函数，返回监听容器
 * @param cb 锚点改变时触发
 * @param external 放置额外参数，例如 offsetY
 */
export var startListen = function (dataSource, container, cb, external) {
    updateNodePosition(dataSource, container, cb, external);
    timeout = window.setTimeout(function () {
        setEventHandlerForContainer(dataSource, container, cb, external);
    });
};
export var setEventHandlerForContainer = function (dataSource, container, cb, external) {
    var affixContainer = container;
    if (affixContainer) {
        events.on(affixContainer, 'scroll', updateNodePosition.bind(null, dataSource, container, cb, external), false);
        events.on(affixContainer, 'resize', updateNodePosition.bind(null, dataSource, container, cb, external), false);
    }
};
/**
 * 解除 startListen 带来的监听功能
 */
export var removeListen = function (dataSource, container) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    removeEventHandlerForContainer(dataSource, container);
};
var removeEventHandlerForContainer = function (dataSource, container) {
    var affixContainer = container;
    if (affixContainer) {
        events.off(affixContainer, 'scroll', updateNodePosition.bind(null, dataSource, container));
        events.off(affixContainer, 'resize', updateNodePosition.bind(null, dataSource, container));
    }
};
/**
 * 获取节点在容器中的偏移量
 */
export function getNodeOffset(el, container) {
    if (container === window) {
        return el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top;
    }
    return (el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top) - (container === null || container === void 0 ? void 0 : container.getBoundingClientRect().top);
}
/**
 * 滚动时触发，判断所有在页面中的节点中的”第一个“，并通过 cb 返回此 htmlId
 */
export var updateNodePosition = debounce(function (dataSource, container, cb, external) {
    var offsetY = (external || {}).offsetY;
    var affixContainer = container;
    if (affixContainer) {
        var findEl_1;
        var predicateItem_1 = function (item) {
            var el = (item === null || item === void 0 ? void 0 : item.htmlId) && document.getElementById(item.htmlId);
            if (el) {
                if (getNodeOffset(el, affixContainer) >= (offsetY || 0)) {
                    findEl_1 = item.htmlId;
                    return true;
                }
            }
            return false;
        };
        dataSource.some(function (item) {
            var status = predicateItem_1(item);
            if (!status && Array.isArray(item.children)) {
                item.children.some(function (subItem) {
                    var subStatus = predicateItem_1(subItem);
                    if (!subStatus && Array.isArray(subItem.children)) {
                        subItem.children.some(function (thirdItem) {
                            var thirdStatus = predicateItem_1(thirdItem);
                            return thirdStatus;
                        });
                    }
                    return subStatus;
                });
            }
            return !!findEl_1;
        });
        if (findEl_1) {
            cb && cb(findEl_1);
        }
    }
}, 100, {
    leading: true,
});
/**
 * 用于在特定容器内跳转到对应的锚点位置
 * 增加 offsetY，用于跳转的偏移，防止部分元素 fixed 遮挡锚点
 */
export var jumpToNode = function (htmlId, container, offsetY) {
    if (offsetY === void 0) { offsetY = 0; }
    var el = htmlId && document.getElementById("".concat(htmlId));
    if (el) {
        if (container) {
            var affixContainer = container;
            if (affixContainer === window) {
                window.scrollTo({
                    top: Math.max(0, ((el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top) + window.pageYOffset) + offsetY),
                    behavior: 'smooth',
                });
            }
            else {
                affixContainer.scrollTo({
                    top: Math.max(0, ((el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top) - (affixContainer === null || affixContainer === void 0 ? void 0 : affixContainer.getBoundingClientRect().top) + affixContainer.scrollTop) + offsetY),
                    behavior: 'smooth',
                });
            }
        }
    }
};
/**
 * 根据 target 获取实际 DOM 节点
 * @param target css selectors
 * @param container 查询容器
 * @returns 单个 DOM 节点
 */
export var findNode = function (target, container) {
    if (container === void 0) { container = document; }
    if (!target || typeof target !== 'string') {
        return target;
    }
    var rst = target;
    try {
        rst = container.querySelector(target);
    }
    catch (err) { /* empty */ }
    return rst;
};
/**
 * 根据 target 获取所有实际 DOM 节点
 * @param target css selectors
 * @param container 查询容器
 * @param ignore 去除的 selectors
 * @returns DOM 节点数组
 */
export var findAllNodes = function (target, container, ignore) {
    if (container === void 0) { container = document; }
    if (!target || typeof target !== 'string') {
        return null;
    }
    if (container === window) {
        container = document;
    }
    var rst;
    var selectors = target;
    if (ignore) {
        selectors = target.split(',').map(function (selector) {
            return "".concat(selector.trim(), ":not(").concat(ignore, ")");
        });
    }
    try {
        rst = container.querySelectorAll(selectors);
    }
    catch (err) {
        rst = null;
    }
    return rst || null;
};
var addNode = function (obj, nest) {
    var level = obj.level;
    var array = nest;
    var lastItem = array[array.length - 1];
    var lastItemLevel = lastItem ? lastItem.level : 0;
    var counter = level - lastItemLevel;
    while (counter > 0) {
        lastItem = array[array.length - 1];
        if (lastItem && level === lastItem.level) {
            break;
        }
        else if (lastItem && lastItem.children !== undefined) {
            array = lastItem.children;
        }
        counter--;
    }
    array.push(obj);
};
/**
 * 获取嵌套的 headings
 */
export var getNestHeadings = function (headingsArray, headingsContainer, levelNodes) {
    return [].reduce.call(headingsArray, function (prev, curr) {
        var _a, _b, _c, _d;
        var level = 0;
        var node = curr;
        while (node && node !== headingsContainer) {
            if ([].indexOf.call(levelNodes, node) > -1) {
                level++;
            }
            node = node.parentElement;
        }
        var obj = {
            htmlId: curr.id,
            children: [],
            level: level,
            label: curr.textContent.trim(),
            // icon 的获取方式，临时方案，后续需要优化
            icon: (_d = (_c = (_b = (_a = curr === null || curr === void 0 ? void 0 : curr.closest('.cn-ui-card-header')) === null || _a === void 0 ? void 0 : _a.querySelector('.cn-ui-icon use')) === null || _b === void 0 ? void 0 : _b.href) === null || _c === void 0 ? void 0 : _c.baseVal) === null || _d === void 0 ? void 0 : _d.replace(/#/, ''),
        };
        addNode(obj, prev.nest);
        return prev;
    }, {
        nest: [],
    }).nest;
};

import { __assign } from "tslib";
import './cn-form-item.scss';
import * as React from 'react';
import classNames from 'classnames';
import { CnGrid } from '@/components/cn-grid';
import { CnIcon } from '@/components/cn-icon';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { normalizeCol } from './utils';
import { useFormItemLabelAlign } from './hooks/use-form-item-label-align';
var CnRow = CnGrid.CnRow, CnCol = CnGrid.CnCol;
// 流式布局默认宽度
export var defaultWrapperWidth = 152;
export var CnFormItem = function (props) {
    var _a;
    var _b;
    var required = props.required, title = props.title, labelCol = props.labelCol, tip = props.tip, cnTooltipProps = props.cnTooltipProps, asterisk = props.asterisk, readOnly = props.readOnly, filterDisplay = props.filterDisplay, errors = props.errors, wrapperCol = props.wrapperCol, extra = props.extra, children = props.children, _c = props.rightExtra, rightExtra = _c === void 0 ? null : _c, wrapperWidth = props.wrapperWidth, className = props.className, style = props.style, id = props.id, size = props.size;
    /**
     * left 左右布局，代表字段标题和字段输入器在一行渲染，当用户配置了 wrapperCol || labelCol 代表开启
     * top 上下布局，字段标题和字段输入器各占一行
     */
    var labelAlign = useFormItemLabelAlign({ wrapperCol: wrapperCol, labelCol: labelCol });
    /**
     * 文本对齐方式
     * 1. 当用户未配置时，固定为左对齐
     * 2. 当用户未配置时，且布局未左右布局时，固定为右对齐
     */
    var labelTextAlign = (_b = props.labelTextAlign) !== null && _b !== void 0 ? _b : (labelAlign === 'left' ? 'right' : 'left');
    // 渲染字段名称提示信息
    var renderTip = function () {
        if (!tip)
            return null;
        return (React.createElement(CnTooltip, __assign({ className: "cn-ui-form-item-explanation", trigger: React.createElement("div", { className: "cn-ui-form-item-label-help" },
                React.createElement(CnIcon, { role: "cn-form-item-tip", type: "help-color", size: 14 })) }, cnTooltipProps), tip));
    };
    var renderLabel = function () {
        if (!title)
            return null;
        var cls = classNames('cn-ui-form-item-header', "cn-ui-form-label-align-".concat(labelTextAlign), {
            'cn-ui-left': labelAlign === 'left',
            'cn-ui-form-item-readonly': !!readOnly,
            'cn-ui-form-item-flex': filterDisplay === 'flex',
        });
        var ele = (React.createElement("div", { className: "cn-ui-form-item-label" },
            !readOnly && (required || asterisk) && (React.createElement("span", { className: "cn-ui-form-item-required" }, "* ")),
            React.createElement(CnTextEllipsis, { line: 1, hasTooltip: true }, title),
            renderTip()));
        if (labelAlign === 'left') {
            if (typeof labelCol === 'object' && labelCol.hidden) {
                return null;
            }
            return (React.createElement(CnCol, __assign({}, (filterDisplay === 'flex' ? {} : normalizeCol(labelCol)), { className: cls }), ele));
        }
        return React.createElement("div", { className: cls }, ele);
    };
    var renderContent = function () {
        var content = [
            React.createElement("div", { key: "control", className: "cn-ui-form-item-control" },
                children,
                rightExtra && (React.createElement("div", { className: "cn-ui-form-item-control-right-extra" }, rightExtra))),
            Array.isArray(errors) && errors.length > 0 ? (React.createElement("div", { key: "error", className: "cn-ui-form-item-error" }, errors.map(function (errorItem, idx) { return (React.createElement("div", { className: "error-item", key: "error-".concat(idx) }, errorItem)); }))) : null,
            React.createElement("div", { key: "extra", className: "cn-ui-form-item-extra" },
                React.createElement("span", null, extra)),
        ];
        if (filterDisplay === 'flex') {
            return (React.createElement("div", { className: "cn-ui-form-item-footer", style: {
                    width: wrapperWidth,
                } }, content));
        }
        if (labelAlign === 'left') {
            return (React.createElement(CnCol, __assign({}, normalizeCol(wrapperCol), { className: "cn-ui-form-item-footer" }), content));
        }
        return React.createElement("div", { className: "cn-ui-form-item-footer" }, content);
    };
    var commonProps = {
        'data-name': 'CnFormItem',
        id: id,
        style: style,
        className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-form-item', "cn-ui-form-item-".concat(labelAlign === 'left' ? 'inline' : 'block'), (_a = {},
            _a["cn-ui-form-item-".concat(size)] = size,
            _a)),
    };
    if (labelAlign === 'left') {
        return (React.createElement(CnRow, __assign({ gutter: 0 }, commonProps),
            renderLabel(),
            renderContent()));
    }
    return (React.createElement("div", __assign({ role: "cn-form-item" }, commonProps),
        renderLabel(),
        renderContent()));
};
CnFormItem.displayName = 'CnFormItem';

import { __assign, __rest } from "tslib";
import './cn-checkbox-group.scss';
import omit from 'lodash/omit';
import * as React from 'react';
import classNames from 'classnames';
import { Checkbox } from '@fusion/lib';
import { CnCheckboxTagGroup } from './cn-checkbox-tag-group';
import { CnCheckboxReadonly } from './cn-checkbox-readonly';
import { useDataSource } from '../hooks/use-data-source';
export var CnCheckboxGroup = function (props) {
    var _a;
    var className = props.className, isPreview = props.isPreview, renderPreview = props.renderPreview, _readOnly = props.readOnly, _b = props.readOnlyProps, readOnlyProps = _b === void 0 ? {
        type: 'enum',
    } : _b, $i18n = props.$i18n, shape = props.shape, _otherProps = __rest(props, ["className", "isPreview", "renderPreview", "readOnly", "readOnlyProps", "$i18n", "shape"]);
    var dataSource = useDataSource(props);
    var readOnly = isPreview || _readOnly;
    var otherProps = __assign(__assign({}, omit(_otherProps, ['requestConfig'])), { 'data-name': 'CnCheckbox', className: classNames(className, (_a = {},
            _a["cn-checkbox-group--".concat(props.size)] = props.size,
            _a)) });
    if (shape === 'tag' && !readOnly) {
        return (React.createElement(CnCheckboxTagGroup, __assign({}, otherProps, { dataSource: dataSource })));
    }
    return (React.createElement(Checkbox.Group, __assign({ isPreview: readOnly, renderPreview: renderPreview || (function (val) {
            return React.createElement(CnCheckboxReadonly, { value: val, readOnlyProps: readOnlyProps });
        }) }, otherProps, { 
        // @ts-ignore 跟 fusion 不一致，但不影响功能，忽略 dataSource 类型校验，后期考虑内化 fusion 组件
        dataSource: dataSource })));
};

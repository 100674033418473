import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { SaveConfigActionTypes } from '../../store';
import { CnButton } from '@/components/cn-button';
export var ConfigButton = function (_a) {
    var enableConfig = _a.enableConfig, store = _a.store, dispatch = _a.dispatch, props = __rest(_a, ["enableConfig", "store", "dispatch"]);
    var overlayVisible = store.overlayVisible;
    if (!enableConfig)
        return null;
    return (React.createElement(CnButton, __assign({}, props, { className: "cn-ui-filter-setting-btn", "data-hottag": "cn-ui.cn-filter-pro.config", onClick: function () {
            sendLog({
                id: 'cn-ui.cn-filter.clickSaveConfigButton',
            });
            dispatch({
                type: SaveConfigActionTypes.SetOverlayVisible,
                payload: !overlayVisible,
            });
        } }),
        React.createElement("span", null,
            React.createElement(CnIcon, { type: "setting" }))));
};

import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { UploadList } from './upload-list';
import { CnUploader, useUploadState, withNativeProps, } from '@cainiaofe/cn-ui-common';
import { MiniUploadList } from './mini-upload-list';
export var UploadView = React.forwardRef(function (props, ref) {
    var _a;
    var _b = useUploadState(), rootProps = _b.props, readOnly = _b.readOnly, limited = _b.limited;
    var hideAddStyle = limited ? { display: 'none' } : {};
    var _c = rootProps, capture = _c.capture, disabled = _c.disabled, size = _c.size;
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-oss-image-upload', (_a = {
                'cn-ui-oss-image-upload-readonly': readOnly
            },
            _a["cn-ui-oss-image-upload--".concat(size)] = size,
            _a)), "data-testid": "cn-ui-oss-image-upload" },
        React.createElement("div", { className: "cn-ui-oss-image-upload-list" },
            readOnly && props.shape === 'mini' ? React.createElement(MiniUploadList, null) : React.createElement(UploadList, null),
            readOnly || capture ? null : (React.createElement("div", { className: classNames('cn-ui-oss-image-upload-item', 'cn-ui-oss-image-upload-add', {
                    'cn-ui-oss-image-upload-add--disabled': disabled,
                }), style: hideAddStyle },
                React.createElement(CnUploader, { ref: ref, className: "cn-ui-oss-image-upload-add-inner" },
                    React.createElement(CnIcon, { type: "add", size: "xl" }))))),
        capture ? (React.createElement("div", { className: "cn-ui-oss-image-upload-disabled-tip" }, $i18n.get({
            id: 'TheOnlyAllowPhotoUploadFunctionI_1016983121',
            dm: '启用了「仅允许拍照上传」功能，请前往钉钉移动客户端拍照上传图片。',
            ns: 'CnOssImageUpload',
        }))) : null));
});
UploadView.displayName = 'UploadView';

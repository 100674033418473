import { __assign } from "tslib";
// 第三方依赖
import isNil from 'lodash/isNil';
export function useColumnsResize(props, columns, setColumns) {
    var columnResizeProps = props.columnResize;
    // 如果 columnResize 为 false 或空直接返回
    if (isNil(columnResizeProps) || columnResizeProps == false) {
        return columnResizeProps;
    }
    if (columnResizeProps === undefined)
        return columnResizeProps;
    // 组装 columnResize
    var columnResize = {};
    // 组装 onChange
    var onChange = function (info) {
        var columnsWidth = columns.map(function (el) {
            return __assign(__assign({}, el), { width: info[el.dataIndex] });
        });
        setColumns(columnsWidth, 'user');
    };
    columnResize.onChange = function (info, dataIndex, width) {
        var _a;
        (_a = columnResizeProps === null || columnResizeProps === void 0 ? void 0 : columnResizeProps.onChange) === null || _a === void 0 ? void 0 : _a.call(columnResizeProps, info, dataIndex, width);
        onChange(info);
    };
    return __assign(__assign({}, (typeof columnResizeProps === 'object' ? columnResizeProps : {})), columnResize);
}

import { __assign, __extends, __rest } from "tslib";
// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Overlay from '@fusion/lib/overlay';
import ConfigProvider from '@fusion/lib/config-provider';
import { guid } from '@fusion/lib/util';
import Message from './message';
var config = ConfigProvider.config;
var instance;
var timeouts = {};
var Mask = /** @class */ (function (_super) {
    __extends(Mask, _super);
    function Mask() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            visible: true,
        };
        _this.handleClose = function (silent) {
            if (silent === void 0) { silent = false; }
            _this.setState({
                visible: false,
            });
            if (!silent) {
                _this.props.onClose && _this.props.onClose();
            }
        };
        return _this;
    }
    Mask.prototype.componentWillUnmount = function () {
        var timeoutId = this.props.timeoutId;
        if (timeoutId in timeouts) {
            var timeout = timeouts[timeoutId];
            clearTimeout(timeout);
            delete timeouts[timeoutId];
        }
    };
    Mask.prototype.render = function () {
        /* eslint-disable no-unused-vars */
        var _a = this.props, prefix = _a.prefix, type = _a.type, title = _a.title, content = _a.content, align = _a.align, offset = _a.offset, hasMask = _a.hasMask, afterClose = _a.afterClose, animation = _a.animation, overlayProps = _a.overlayProps, timeoutId = _a.timeoutId, className = _a.className, style = _a.style, others = __rest(_a, ["prefix", "type", "title", "content", "align", "offset", "hasMask", "afterClose", "animation", "overlayProps", "timeoutId", "className", "style"]);
        /* eslint-enable */
        var visible = this.state.visible;
        return (React.createElement(Overlay, __assign({}, overlayProps, { prefix: prefix, animation: animation, visible: visible, align: align, offset: offset, hasMask: hasMask, afterClose: afterClose }),
            React.createElement(Message, __assign({}, others, { prefix: prefix, visible: true, type: type, shape: "toast", title: title, style: style, className: "".concat(prefix, "message-wrapper ").concat(className), onClose: this.handleClose }), content)));
    };
    Mask.contextTypes = {
        prefix: PropTypes.string,
    };
    Mask.propTypes = {
        prefix: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.node,
        content: PropTypes.node,
        align: PropTypes.string,
        offset: PropTypes.array,
        hasMask: PropTypes.bool,
        afterClose: PropTypes.func,
        animation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        overlayProps: PropTypes.object,
        onClose: PropTypes.func,
        timeoutId: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
    };
    Mask.defaultProps = {
        prefix: 'cn-next-',
        align: 'tc tc',
        offset: [0, 30],
        hasMask: false,
        animation: {
            in: 'pulse',
            out: 'zoomOut',
        },
        style: {},
        className: '',
    };
    return Mask;
}(React.Component));
var NewMask = config(Mask);
var create = function (props) {
    /* eslint-disable no-unused-vars */
    var duration = props.duration, afterClose = props.afterClose, contextConfig = props.contextConfig, others = __rest(props, ["duration", "afterClose", "contextConfig"]);
    /* eslint-enable no-unused-vars */
    var div = document.createElement('div');
    document.body.appendChild(div);
    var closeChain = function () {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
        afterClose && afterClose();
    };
    var newContext = contextConfig;
    if (!newContext)
        newContext = ConfigProvider.getContext();
    var mask;
    var myRef;
    var destroyed = false;
    var destroy = function () {
        var inc = mask && mask.getInstance();
        inc && inc.handleClose(true);
        destroyed = true;
    };
    ReactDOM.render(React.createElement(ConfigProvider, __assign({}, newContext),
        React.createElement(NewMask, __assign({ afterClose: closeChain }, others, { ref: function (ref) {
                myRef = ref;
            } }))), div, function () {
        mask = myRef;
        if (mask && destroyed) {
            destroy();
        }
    });
    return {
        component: mask,
        destroy: destroy,
    };
};
function handleConfig(_config, type) {
    var newConfig = {};
    if (typeof _config === 'string' || React.isValidElement(_config)) {
        newConfig.title = _config;
    }
    else if (isObject(_config)) {
        newConfig = __assign({}, _config);
    }
    if (typeof newConfig.duration !== 'number') {
        newConfig.duration = 3000;
    }
    if (type) {
        newConfig.type = type;
    }
    return newConfig;
}
function isObject(obj) {
    return {}.toString.call(obj) === '[object Object]';
}
function open(_config, type) {
    close();
    _config = handleConfig(_config, type);
    var timeoutId = guid();
    instance = create(__assign(__assign({}, _config), { timeoutId: timeoutId }));
    if (_config.duration > 0) {
        var timeout = setTimeout(close, _config.duration);
        timeouts[timeoutId] = timeout;
    }
}
function close() {
    if (instance) {
        instance.destroy();
        instance = null;
    }
}
/**
 * 创建提示弹层
 * @exportName show
 * @param {Object} props 属性对象
 */
function show(_config) {
    open(_config);
}
/**
 * 关闭提示弹层
 * @exportName hide
 */
function hide() {
    close();
}
/**
 * 创建成功提示弹层
 * @exportName success
 * @param {Object} props 属性对象
 */
function success(_config) {
    open(_config, 'success');
}
/**
 * 创建警告提示弹层
 * @exportName warning
 * @param {Object} props 属性对象
 */
function warning(_config) {
    open(_config, 'warning');
}
/**
 * 创建错误提示弹层
 * @exportName error
 * @param {Object} props 属性对象
 */
function error(_config) {
    open(_config, 'error');
}
/**
 * 创建帮助提示弹层
 * @exportName help
 * @param {Object} props 属性对象
 */
function help(_config) {
    open(_config, 'help');
}
/**
 * 创建加载中提示弹层
 * @exportName loading
 * @param {Object} props 属性对象
 */
function loading(_config) {
    open(_config, 'loading');
}
/**
 * 创建通知提示弹层
 * @exportName notice
 * @param {Object} props 属性对象
 */
function notice(_config) {
    open(_config, 'notice');
}
export default {
    show: show,
    hide: hide,
    success: success,
    warning: warning,
    error: error,
    help: help,
    loading: loading,
    notice: notice,
};
export var withContext = function (WrappedComponent) {
    var HOC = function (props) {
        return (React.createElement(ConfigProvider.Consumer, null, function (contextConfig) { return (React.createElement(WrappedComponent, __assign({}, props, { contextMessage: {
                show: function (_config) {
                    if (_config === void 0) { _config = {}; }
                    return show(__assign(__assign({}, _config), { contextConfig: contextConfig }));
                },
                hide: hide,
                success: function (_config) {
                    if (_config === void 0) { _config = {}; }
                    return success(__assign(__assign({}, _config), { contextConfig: contextConfig }));
                },
                warning: function (_config) {
                    if (_config === void 0) { _config = {}; }
                    return warning(__assign(__assign({}, _config), { contextConfig: contextConfig }));
                },
                error: function (_config) {
                    if (_config === void 0) { _config = {}; }
                    return error(__assign(__assign({}, _config), { contextConfig: contextConfig }));
                },
                help: function (_config) {
                    if (_config === void 0) { _config = {}; }
                    return help(__assign(__assign({}, _config), { contextConfig: contextConfig }));
                },
                loading: function (_config) {
                    if (_config === void 0) { _config = {}; }
                    return loading(__assign(__assign({}, _config), { contextConfig: contextConfig }));
                },
                notice: function (_config) {
                    if (_config === void 0) { _config = {}; }
                    return notice(__assign(__assign({}, _config), { contextConfig: contextConfig }));
                },
            } }))); }));
    };
    return HOC;
};

// 第三方依赖
import { useMemo, useRef } from 'react';
import flip from 'lodash/flip';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
// 当前目录的相对依赖
import { useControlValue } from './use-control-value';
/**
 * 用于处理表格行选择的 hook
 * @param {CnTableProps} props - 表格组件的 props
 * @returns {Object} - 返回一个对象，包含 hasSelect, storeSelectInfo, selectKeys, setSelectKeys 四个属性
 */
export function useSelect(props) {
    var _a;
    var showSelect = props.showSelect, rowSelection = props.rowSelection, primaryKey = props.primaryKey;
    var onSelectChange = props.onSelectChange;
    // 获取选中的行的key值
    var _b = useControlValue(
    // @ts-ignore
    rowSelection, {
        defaultValuePropName: (_a = rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.defaultValue) !== null && _a !== void 0 ? _a : [],
        valuePropName: 'selectedRowKeys',
        changePropName: 'onChange', // 改变选中行的key值
    }), selectKeys = _b[0], setSourceSelectKeys = _b[1];
    // 保存每一次的selection 信息，存储给button的时候传递
    // 保存每一次的selection信息
    var storeSelectInfo = useRef([]);
    var hasSelect = useMemo(
    // 是否有选择
    function () { return !!(showSelect || rowSelection); }, // 如果showSelect或rowSelection存在，则返回true，否则返回false
    [showSelect, rowSelection]);
    /**
     * 设置选中的行
     * @param  {...any} argsArr - 选中的行的 key 值
     */
    var setSelectKeys = function () {
        var argsArr = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            argsArr[_i] = arguments[_i];
        }
        // 设置选中的行
        storeSelectInfo.current = argsArr; // 保存每一次的selection信息
        if (showSelect && isFunction(onSelectChange)) {
            // 如果showSelect存在且onSelectChange是函数
            if (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.useDetailValue) {
                // 如果rowSelection.useDetailValue存在
                var onSelectChangeFlip = flip(onSelectChange); // 将onSelectChange反转
                onSelectChangeFlip.apply(null, argsArr.slice(0, 2)); // 将反转后的onSelectChange应用到argsArr的前两个元素上
            }
            else {
                onSelectChange.apply(null, argsArr); // 将onSelectChange应用到argsArr上
            }
        }
        setSourceSelectKeys.apply(void 0, argsArr); // 改变选中行的key值
    };
    var selectKeysProps;
    if (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.useDetailValue) {
        if (Array.isArray(selectKeys)) {
            selectKeysProps = selectKeys.map(function (item) { return (isPlainObject(item) ? item[primaryKey] : item); });
        }
        selectKeysProps = isPlainObject(selectKeys) ? selectKeys[primaryKey] : selectKeys;
    }
    else {
        selectKeysProps = selectKeys;
    }
    return {
        hasSelect: hasSelect,
        storeSelectInfo: storeSelectInfo,
        selectKeys: selectKeysProps,
        setSelectKeys: setSelectKeys,
    };
}

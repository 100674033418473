import { __assign } from "tslib";
import { getPropsField } from './get-props-field';
export var getGridItemStyle = function (props) {
    var cols = props.cols, layoutMode = props.layoutMode, gridItemStyle = props.gridItemStyle, _a = props.area, area = _a === void 0 ? [] : _a, index = props.index, item = props.item;
    var isSingleLayout = layoutMode === 'single-col' || layoutMode === 'single-left';
    var _b = item.props, colSpan = _b.colSpan, rowSpan = _b.rowSpan, hidden = _b.hidden;
    var style = __assign({}, gridItemStyle);
    var originItemStyle = getPropsField(item, 'style') || {};
    if (hidden || originItemStyle.display === 'none') {
        style.display = 'none';
    }
    if (isSingleLayout) {
        return style;
    }
    var areaIndexData = [].concat(area[index]);
    var gridColumn = colSpan || (areaIndexData === null || areaIndexData === void 0 ? void 0 : areaIndexData[0]) || 1;
    var gridRow = rowSpan || (areaIndexData === null || areaIndexData === void 0 ? void 0 : areaIndexData[1]) || 1;
    return __assign(__assign({}, style), { gridColumn: "span ".concat(cols && (gridColumn === 'full' || gridColumn > cols) ? cols : gridColumn), gridRow: "span ".concat(gridRow) });
};

import { __assign, __awaiter, __generator, __rest, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import flattenDeep from 'lodash/flattenDeep';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
// import { CascaderSelect } from '@/components/fusion';
import { CnCascaderSelect as CascaderSelect, } from '@/components/cn-cascader-select';
// import { CnCascaderSelectProps as CascaderSelectProps } from '@/components/cn-cascader-select';
// import { CascaderSelectProps } from '@fusion/types/cascader-select';
import { getSubDeptCacheByKey } from './request-cache';
import { getDepartmentsByKeyword } from './service';
import DepartmentValueItem from './department-value-item';
import { FORM_EMPTY_CONTENT, getPopupContainer } from '@cainiaofe/cn-ui-common';
import DepartmentPreview from '@/components/cn-department-select/view/department-preview';
var DepartmentSelect = function (props) {
    var readOnly = props.readOnly, dataSource = props.dataSource, style = props.style, _a = props.mokelay, mokelay = _a === void 0 ? true : _a, host = props.host, popupContainer = props.popupContainer, itemPropsRef = props.itemPropsRef, _b = props.isArrayValue, isArrayValue = _b === void 0 ? false : _b, otherProps = __rest(props, ["readOnly", "dataSource", "style", "mokelay", "host", "popupContainer", "itemPropsRef", "isArrayValue"]);
    var timer = React.useRef();
    var _c = React.useState(''), searchValue = _c[0], onSearchValueChange = _c[1];
    var _d = React.useState([]), departmentList = _d[0], setDepartmentList = _d[1];
    var _e = React.useState([]), departmentSearchResult = _e[0], setDepartmentSearchResult = _e[1];
    if (itemPropsRef) {
        itemPropsRef.current = {
            renderSelected: function (key, value, itemLabel) {
                if (Array.isArray(value)) {
                    return (React.createElement("div", { style: { display: 'flex', width: 200 } },
                        itemLabel,
                        ":",
                        value.map(function (valueItem) {
                            return (React.createElement(DepartmentValueItem, { style: { width: 100 }, item: { value: valueItem }, host: host, mokelay: mokelay }));
                        })));
                }
                return (React.createElement("div", { style: { display: 'flex', width: 200, overflow: 'hidden' } },
                    itemLabel,
                    ":",
                    React.createElement(DepartmentValueItem, { style: { width: 100 }, item: { value: value }, host: host, mokelay: mokelay }),
                    ";"));
            },
        };
    }
    // 初始数据
    React.useEffect(function () {
        function init() {
            return __awaiter(this, void 0, void 0, function () {
                var initialData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getSubDeptCacheByKey({
                                deptNo: '22187',
                                mokelay: mokelay,
                                host: host,
                            })];
                        case 1:
                            initialData = _a.sent();
                            setDepartmentList(initialData);
                            return [2 /*return*/];
                    }
                });
            });
        }
        init();
    }, []);
    var getServiceAndSetMap = function (requestData) {
        var clonedepartmentList = cloneDeep(departmentList);
        var getTail = function (item) {
            if ((item === null || item === void 0 ? void 0 : item.children) && (item === null || item === void 0 ? void 0 : item.children.length) > 0) {
                return item === null || item === void 0 ? void 0 : item.children.map(function (m) { return getTail(m); });
            }
            else {
                return [item];
            }
        };
        var childrenDepartment = find(flattenDeep(clonedepartmentList.map(function (m) { return getTail(m); })), { code: requestData.code });
        return getSubDeptCacheByKey({
            deptNo: requestData.deptNo,
            mokelay: mokelay,
            host: host,
        }).then(function (resData) {
            childrenDepartment.children = resData;
            setDepartmentList(clonedepartmentList);
        });
    };
    var onSearch = function (newSearchValue) {
        onSearchValueChange(newSearchValue);
        if (!isNil(newSearchValue)) {
            clearTimeout(timer.current);
            timer.current = setTimeout(function () {
                getDepartmentsByKeyword({
                    host: host,
                    searchKey: newSearchValue,
                    mokelay: mokelay,
                }).then(function (_departmentList) {
                    setDepartmentSearchResult(_departmentList);
                });
            }, 300);
        }
    };
    var valueRender = function (item) {
        return React.createElement(DepartmentValueItem, { item: item, mokelay: mokelay, host: host });
    };
    if (readOnly) {
        if (!(props === null || props === void 0 ? void 0 : props.value)) {
            return FORM_EMPTY_CONTENT;
        }
        return (React.createElement(DepartmentPreview, { value: props === null || props === void 0 ? void 0 : props.value, mokelay: mokelay, host: host }));
    }
    var resultRender = function (_searchValue, path) {
        var parts = [];
        path === null || path === void 0 ? void 0 : path.forEach(function (item, i) {
            var _a, _b;
            var reExp = _searchValue.replace(/[-.+*?^$()[\]{}|\\]/g, function (v) { return "\\".concat(v); });
            var re = new RegExp(reExp, 'gi');
            var others = (_a = item === null || item === void 0 ? void 0 : item.fullName) === null || _a === void 0 ? void 0 : _a.split(re);
            var matches = (_b = item === null || item === void 0 ? void 0 : item.fullName) === null || _b === void 0 ? void 0 : _b.match(re);
            others === null || others === void 0 ? void 0 : others.forEach(function (other, j) {
                if (other) {
                    parts.push(other);
                }
                if (j < others.length - 1) {
                    parts.push(React.createElement("em", { key: "".concat(i, "-").concat(j) }, matches[j]));
                }
            });
            if (i < path.length - 1) {
                parts.push(' / ');
            }
        });
        return React.createElement("span", null, parts);
    };
    var handleValueChange = function (value, data, extra) {
        var _a = props || {}, onChange = _a.onChange, multiple = _a.multiple;
        if (multiple !== true && isArrayValue === true && !Array.isArray(value) && !isNil(value)) {
            onChange === null || onChange === void 0 ? void 0 : onChange([value], [data], extra);
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(value, data, extra);
        }
    };
    var selectProps = __assign(__assign({ placeholder: $i18n.get({
            id: 'PleaseSelectADepartment',
            dm: '请选择部门',
            ns: 'CnDepartmentSelect',
        }), dataSource: __spreadArray(__spreadArray([], departmentSearchResult, true), departmentList, true), 
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        loadData: getServiceAndSetMap, isPreview: readOnly, searchValue: searchValue, style: __assign({ width: '100%' }, style), onSearch: onSearch, changeOnSelect: true, onSearchClear: function () { return onSearch(''); }, resultRender: resultRender, resultAutoWidth: false, valueRender: valueRender, popupContainer: getPopupContainer(popupContainer) }, otherProps), { onChange: handleValueChange });
    return React.createElement(CascaderSelect, __assign({}, selectProps));
};
export default DepartmentSelect;

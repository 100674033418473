import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { PandaConfigProvider } from 'panda-i18n';
import { withNativeProps, nativePropsReg } from '@cainiaofe/cn-ui-common';
import { CnPageContent } from './page-content';
import { obj } from '@fusion/lib/util';
export function CnPageView(props) {
    var style = props.style, className = props.className, restProps = __rest(props, ["style", "className"]);
    // props处理
    var pickedNativeProps = obj.pickAttrsWith(props, nativePropsReg);
    var pureProps = obj.pickOthers(pickedNativeProps, restProps);
    return (React.createElement(PandaConfigProvider, null, withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-page', 'cn-page', {
            'cn-page--no-padding': restProps.noPadding,
        }), "data-name": "CnPage" },
        React.createElement(CnPageContent, __assign({}, pureProps)),
        React.createElement("div", { className: "cn-ui-page-footer-placeholder" })))));
}

import { __assign, __rest } from "tslib";
import * as React from 'react';
import classnames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
function SortIcon(props) {
    var style = props.style, className = props.className, order = props.order, rest = __rest(props, ["style", "className", "order"]);
    return (React.createElement("div", __assign({ className: classnames('cn-table-sort-icon', className) }, rest),
        order === 'none' && React.createElement(CnIcon, { type: "triangle-sort", size: "small" }),
        order === 'asc' && (React.createElement(CnIcon, { type: "icon-triangle-sort-descending", size: "small" })),
        order === 'desc' && (React.createElement(CnIcon, { type: "icon-triangle-sort-ascending", size: "small" }))));
}
export default function SortHeaderCell(props) {
    var column = props.column, sortOrder = props.sortOrder, onToggle = props.onToggle, children = props.children;
    var alignmentMap = {
        right: 'flex-end',
        center: 'center',
        left: 'flex-start',
    };
    var justifyContent = alignmentMap[column.align];
    return (React.createElement("div", { style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: justifyContent,
        } },
        children,
        React.createElement(SortIcon, { style: { marginLeft: 2, flexShrink: 0 }, size: 16, order: sortOrder, onClick: onToggle })));
}

import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { Rating } from '@fusion/lib';
import { useNextLocale } from '../cn-utils';
export var CnRating = function (props) {
    var className = props.className, _a = props.count, count = _a === void 0 ? 5 : _a, _b = props.allowHalf, allowHalf = _b === void 0 ? false : _b, _c = props.defaultValue, defaultValue = _c === void 0 ? 0 : _c, _d = props.allowClear, allowClear = _d === void 0 ? true : _d, restProps = __rest(props, ["className", "count", "allowHalf", "defaultValue", "allowClear"]);
    var ratingLocale = useNextLocale('Rating');
    return (React.createElement(Rating, __assign({ "data-name": "CnRating", className: classNames(className, 'cn-ui-rating'), count: count, allowHalf: allowHalf, defaultValue: defaultValue, allowClear: allowClear }, restProps, { locale: ratingLocale, size: "large" })));
};
CnRating.displayName = 'CnRating';

import { useRef } from 'react';
import { useFilterRefsContext } from '../context';
import { formatMap } from '../components/filter-selected-tags/format-map';
import { uninjectSet } from '../components/filter-selected-tags/uninject-set';
export var useItemCollection = function (itemProps) {
    var filterContext = useFilterRefsContext();
    var defaultChildRef = useRef(null);
    var fusionChildRef = useRef(null);
    var itemPropsRef = useRef(null);
    var setValueRender = function (valueRender) {
        if (typeof valueRender === 'function') {
            itemPropsRef.current = {
                renderSelected: function (_key, val, label) {
                    return valueRender(val, label);
                },
            };
        }
        var update = (filterContext || {}).update;
        update && update();
    };
    var setRef = function (target, ref) {
        if (target && !target._observeProps) {
            target._observeProps = true;
            target._props = target.props;
            Object.defineProperty(target, 'props', {
                get: function () {
                    return target._props;
                },
                set: function (val) {
                    var update = (filterContext || {}).update;
                    if (target._props !== val) {
                        update && update();
                    }
                    target._props = val;
                },
            });
        }
        ref.current = target;
    };
    var getCollectionInjectPorps = function (displayName) {
        var inject = {
            ref: function (target) {
                setRef(target, defaultChildRef);
            },
        };
        if (!formatMap.has(displayName) && !uninjectSet.has(displayName)) {
            inject.filterFusionRef = function (target) { return setRef(target, fusionChildRef); };
            inject.setFilterValueRender = setValueRender;
            inject.setFilterRenderValue = setValueRender;
            inject.itemPropsRef = itemPropsRef;
        }
        return inject;
    };
    var setCollection = function (name, displayName) {
        filterContext.itemCollection[name] = {
            itemProps: itemProps,
            defaultChildRef: defaultChildRef,
            fusionChildRef: fusionChildRef,
            itemPropsRef: itemPropsRef,
            displayName: displayName,
        };
    };
    return {
        getCollectionInjectPorps: getCollectionInjectPorps,
        setCollection: setCollection,
    };
};

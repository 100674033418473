import { __assign, __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n';
import React, { useCallback, useMemo, useState } from 'react';
import useLatest from 'ahooks/lib/useLatest';
import classNames from 'classnames';
import { CnDialog } from '@/components/cn-dialog';
import { events, friendlyTips } from '@/components/cn-utils';
import { useCnRequest, withNativeProps } from '@cainiaofe/cn-ui-common';
import { CnMessage } from '@/components/cn-message';
import { CnOSSUpload } from '../cn-oss-upload';
import { CnButton } from '@/components/cn-button';
import { cssPrefix } from './constant';
import ImportButton from './import-button';
import LoopDialog from './loop-dialog';
import { LOOPING_IMPORT_JOB } from '../cn-async-jobs-pro/view/constant/constant';
export var CnOSSImportView = function (props) {
    var children = props.children, style = props.style, className = props.className, buttonProps = props.buttonProps, buttonText = props.buttonText, autoImport = props.autoImport, uploadMsgSlot = props.uploadMsgSlot, cnUploadProps = props.uploadProps, createService = props.createService, _createRequestConfig = props.createRequestConfig, hideLoop = props.hideLoop, pollingService = props.pollingService, _pollingRequestConfig = props.pollingRequestConfig, pollingInterval = props.pollingInterval, showBgProcessBtn = props.showBgProcessBtn, onCreateSuccess = props.onCreateSuccess, onSuccess = props.onSuccess, onSome = props.onSome, onError = props.onError, accept = props.accept;
    var _a = useState(), jobId = _a[0], setJobId = _a[1];
    var _b = useState(false), jobCreateLoading = _b[0], setJobCreateLoading = _b[1];
    var _c = useState(), ossData = _c[0], setOssData = _c[1];
    var _d = useState(false), uploadDialogVisible = _d[0], setUploadDialogVisible = _d[1];
    var _e = useState(false), loopDialogVisible = _e[0], setLoopDialogVisible = _e[1];
    var onCreateSuccessRef = useLatest(onCreateSuccess);
    var onErrorRef = useLatest(onError);
    var createRequestConfig = useMemo(function () {
        var main = function () {
            if (_createRequestConfig) {
                return _createRequestConfig;
            }
            if (typeof createService === 'function') {
                return {
                    service: function (params) {
                        if (typeof params === 'string') {
                            return createService(params);
                        }
                        return createService(params.key);
                    },
                };
            }
            return createService;
        };
        return __assign(__assign({}, main()), { manual: true });
    }, [createService, _createRequestConfig]);
    var pollingRequestConfig = useMemo(function () {
        var main = function () {
            if (_pollingRequestConfig) {
                return _pollingRequestConfig;
            }
            if (typeof pollingService === 'function') {
                return {
                    service: pollingService,
                };
            }
            return pollingService;
        };
        return __assign(__assign({}, main()), { manual: true });
    }, [pollingService, _pollingRequestConfig]);
    var openUploadDialog = useCallback(function () {
        setUploadDialogVisible(true);
    }, []);
    var _onCreateJobSuccess = useCallback(function (job) {
        if (onCreateSuccessRef.current) {
            onCreateSuccessRef.current(job);
        }
        else if (hideLoop) {
            CnMessage.success($i18n.get({
                id: 'TaskCreationSuccessful',
                dm: '任务创建成功',
                ns: 'CnOssImport',
            }));
        }
    }, [hideLoop, onCreateSuccessRef]);
    var runAsync = useCnRequest(createRequestConfig).runAsync;
    /**
     * 创建导入任务
     */
    var _createJob = useCallback(function (_ossData) { return __awaiter(void 0, void 0, void 0, function () {
        var result, auditUrl, _a, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!_ossData)
                        return [2 /*return*/];
                    setJobCreateLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 8, , 9]);
                    return [4 /*yield*/, runAsync({ key: _ossData.key })];
                case 2:
                    result = _b.sent();
                    if (!result.needAudit) return [3 /*break*/, 7];
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 6, , 7]);
                    return [4 /*yield*/, new Promise(function (resolve, reject2) {
                            CnDialog.confirm({
                                title: $i18n.get({
                                    id: 'ImportDataApproval',
                                    dm: '导入数据审批',
                                    ns: 'CnOssImport',
                                }),
                                content: $i18n.get({
                                    id: 'ImportOfThisDataRequiresApproval_1619972702',
                                    dm: '导入该数据需经过审批，是否提交导入数据审批流程？',
                                    ns: 'CnOssImport',
                                }),
                                onOk: resolve,
                                okProps: {
                                    children: $i18n.get({
                                        id: 'TERM.Submit',
                                        dm: '提交',
                                        ns: 'CnOssImport',
                                    }),
                                },
                                onCancel: reject2,
                            });
                        })];
                case 4:
                    _b.sent();
                    return [4 /*yield*/, runAsync({ key: _ossData.key, __needAudit: true })];
                case 5:
                    result = _b.sent();
                    auditUrl = result.auditUrl;
                    if (auditUrl) {
                        window.open(auditUrl);
                        setUploadDialogVisible(false);
                        CnMessage.success($i18n.get({
                            id: 'TaskCreationSuccessful',
                            dm: '任务创建成功',
                            ns: 'CnOssImport',
                        }));
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 7];
                case 6:
                    _a = _b.sent();
                    return [3 /*break*/, 7];
                case 7:
                    if (result === null || result === void 0 ? void 0 : result.jobId) {
                        _onCreateJobSuccess(result);
                        if (hideLoop) {
                            events.emit(LOOPING_IMPORT_JOB);
                            setUploadDialogVisible(false);
                            setOssData(undefined);
                        }
                        else {
                            setLoopDialogVisible(true);
                            setJobId(result.jobId);
                        }
                    }
                    else {
                        throw new Error($i18n.get({
                            id: 'TheCreatedInterfaceDoesNotReturn_287404140',
                            dm: '创建接口未返回jobId，请检查导入任务是否创建成功',
                            ns: 'CnOssImport',
                        }));
                    }
                    return [3 /*break*/, 9];
                case 8:
                    err_1 = _b.sent();
                    if (onErrorRef.current) {
                        onErrorRef.current(err_1);
                    }
                    else {
                        friendlyTips({
                            component: 'CnImport',
                            message: $i18n.get({
                                id: 'CreateImportTaskException',
                                dm: '创建导入任务异常',
                                ns: 'CnOssImport',
                            }),
                        });
                    }
                    setJobCreateLoading(false);
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); }, [runAsync, _onCreateJobSuccess, hideLoop, onErrorRef]);
    /**
     * 创建导入任务
     */
    var createJob = useCallback(function () {
        _createJob(ossData);
    }, [_createJob, ossData]);
    /**
     * 导入文件上传成功后，判断是否创建导出任务
     */
    var onUpload = useCallback(function (fileList) { return __awaiter(void 0, void 0, void 0, function () {
        var file;
        var _a;
        return __generator(this, function (_b) {
            file = fileList[0];
            if (!file || (file.status && file.status !== 'done'))
                return [2 /*return*/];
            setOssData(file);
            (_a = cnUploadProps === null || cnUploadProps === void 0 ? void 0 : cnUploadProps.onChange) === null || _a === void 0 ? void 0 : _a.call(cnUploadProps, fileList, file);
            if (autoImport)
                _createJob(file);
            return [2 /*return*/];
        });
    }); }, [_createJob, autoImport, cnUploadProps]);
    return withNativeProps(props, React.createElement("div", { "data-name": "CnOSSImport", className: classNames(CN_UI_HASH_CLASS_NAME, className, "".concat(cssPrefix, "wrapper")), style: style },
        React.createElement(ImportButton, { buttonProps: buttonProps, buttonText: buttonText, onClick: openUploadDialog }, children),
        React.createElement(CnDialog, { className: "".concat(cssPrefix, "dialog"), title: $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' }), visible: uploadDialogVisible, onOk: createJob, okProps: { visible: false }, cancelProps: { visible: false }, onClose: function () { return setUploadDialogVisible(false); }, afterClose: function () {
                setJobCreateLoading(false);
                setOssData(undefined);
            }, cache: false, footer: React.createElement(React.Fragment, null,
                React.createElement(CnButton, { onClick: function () { return setUploadDialogVisible(false); }, size: "medium" }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnOssImport' })),
                autoImport ? null : (React.createElement(CnButton, { style: { marginLeft: 8 }, disabled: !ossData, loading: jobCreateLoading, type: "primary", onClick: createJob, size: "medium" }, $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' })))) },
            React.createElement("div", { className: "".concat(cssPrefix, "content-wrapper") },
                uploadMsgSlot ? (React.createElement("div", { className: "".concat(cssPrefix, "message-slot") },
                    React.createElement(CnMessage, { type: "notice" }, uploadMsgSlot))) : null,
                React.createElement(CnOSSUpload, __assign({ className: "".concat(cssPrefix, "upload"), shape: "dragger" }, cnUploadProps, { accept: accept, multiple: false, limit: 1, onChange: onUpload })))),
        jobId ? (React.createElement(LoopDialog, { jobId: jobId, setJobId: setJobId, onRender: function () { return setUploadDialogVisible(false); }, visible: loopDialogVisible, setVisible: setLoopDialogVisible, pollingRequestConfig: pollingRequestConfig, pollingInterval: pollingInterval, showBgProcessBtn: showBgProcessBtn, onSuccess: onSuccess, onSome: onSome, onError: onError })) : null));
};
CnOSSImportView.defaultProps = {
    accept: '.xls,.xlsx',
    pollingInterval: 5000,
};
CnOSSImportView.displayName = 'CnOSSImport';

import { isEmpty, valueToDataSourceItem } from '../../utils';
import isObject from 'lodash/isObject';
import isMap from 'lodash/isMap';
export var formatWithDataSource = function (key, value, dataSource, cache) {
    var itemCache;
    // 检查cache是否为Map类型
    if (isMap(cache)) {
        // 尝试从cache获取key对应的值
        itemCache = cache.get(key);
        // 如果key不存在于cache中，则创建一个新的Map并将其设置到cache中
        if (!itemCache) {
            itemCache = new Map();
            cache.set(key, itemCache);
        }
    }
    else {
        // cache不是Map时，直接创建一个新的Map
        itemCache = new Map();
    }
    var items = valueToDataSourceItem(value, dataSource);
    var valueItems = items.map(function (item) {
        if (!item || !item.value) {
            // 在dataSource中未匹配的，尝试在cache中匹配
            var label = itemCache.get(item);
            if (label) {
                return {
                    label: label,
                    value: item,
                };
            }
        }
        return item;
    });
    // 更新缓存中label
    itemCache.clear();
    valueItems.forEach(function (item) {
        if (item === null || item === void 0 ? void 0 : item.label) {
            itemCache.set(item.value, item.label);
        }
    });
    return valueItems
        .map(function (item) { return (isObject(item) ? item.label || item.value
        : item); })
        .filter(function (val) { return !isEmpty(val); })
        .join(', ');
};

import { __assign } from "tslib";
import * as React from 'react';
import { collectNodes, isLeafNode, layeredSort, mergeCellProps, safeGetValue, safeGetCellProps, safeRenderHeader, } from '../../utils';
import { smartCompare } from './smart-compare';
var stateKey = 'sort';
export default function sort(opts) {
    if (opts === void 0) { opts = {
        SortHeaderIconWidth: 16,
    }; }
    return function sortStep(pipeline) {
        var _a, _b, _c;
        // 接收一个TablePipeline对象，返回一个TablePipeline对象
        var _d = opts.orders, orders = _d === void 0 ? ['desc', 'asc', 'none'] : _d, // 排序顺序
        _e = opts.mode, // 排序顺序
        mode = _e === void 0 ? 'multiple' : _e, // 排序模式
        SortHeaderCell = opts.SortHeaderCell, // 排序表头
        keepDataSource = opts.keepDataSource, // 是否保持dataSource不变
        highlightColumnWhenActive = opts.highlightColumnWhenActive, // 排序激活时是否高亮这一列的单元格
        stopClickEventPropagation = opts.stopClickEventPropagation, // 是否对触发onChangeOpenKeys的click事件调用event.stopPropagation()
        _f = opts.clickArea, // 是否对触发onChangeOpenKeys的click事件调用event.stopPropagation()
        clickArea = _f === void 0 ? 'content' : _f, // 点击事件的响应区域
        _g = opts.SortHeaderIconWidth, // 点击事件的响应区域
        SortHeaderIconWidth = _g === void 0 ? 16 : _g; // 从opts中获取以上参数
        var inputSorts = // 获取排序字段列表
         (_c = (_b = (_a = opts.sorts) !== null && _a !== void 0 ? _a : pipeline.getStateAtKey(stateKey)) !== null && _b !== void 0 ? _b : opts.defaultSorts) !== null && _c !== void 0 ? _c : [];
        var activeSorts = inputSorts.filter(function (s) { return s.order !== 'none'; }); // 获取当前排序字段列表
        // 单字段排序的情况下 sorts 中只有第一个排序字段才会生效
        var sorts = mode === 'multiple' ? activeSorts : activeSorts.slice(0, 1); // 根据排序模式获取排序字段列表
        var onChangeSortsInMultipleMode = function (nextSorts) {
            var _a;
            // 更新排序字段列表的回调函数
            (_a = opts.onChangeSorts) === null || _a === void 0 ? void 0 : _a.call(opts, nextSorts);
            pipeline.setStateAtKey(stateKey, nextSorts);
        };
        var onChangeSorts;
        if (mode === 'multiple') {
            onChangeSorts = onChangeSortsInMultipleMode;
        }
        else {
            onChangeSorts = function (nextSorts) {
                // 单字段排序的情况下，nextSorts 中只有最后一个排序字段才会生效
                var len = nextSorts.length;
                onChangeSortsInMultipleMode(nextSorts.slice(len - 1));
            };
        }
        var sortOptions = {
            // 排序选项
            sorts: sorts,
            onChangeSorts: onChangeSorts,
            orders: orders,
            mode: mode,
            keepDataSource: keepDataSource,
            highlightColumnWhenActive: highlightColumnWhenActive,
            stopClickEventPropagation: stopClickEventPropagation,
            clickArea: clickArea,
        };
        var sortMap = new Map(// 排序字段映射
        sorts.map(function (sortItem, index) { return [sortItem.code, __assign({ index: index }, sortItem)]; }));
        var dataSource = pipeline.getDataSource(); // 获取数据源
        var columns = pipeline.getColumns(); // 获取列信息
        pipeline.dataSource(processDataSource(dataSource)); // 处理数据源
        pipeline.columns(processColumns(columns)); // 处理列信息
        return pipeline; // 返回TablePipeline对象
        function processDataSource(tableDataSource) {
            // 处理数据源
            if (keepDataSource) {
                // 如果保持dataSource不变，则直接返回dataSource
                return tableDataSource;
            }
            // 排序列映射
            var sortColumnsMap = new Map(collectNodes(columns, 'leaf-only')
                .filter(function (col) {
                var _a, _b;
                return ((_a = col.features) === null || _a === void 0 ? void 0 : _a.sortable) !== false &&
                    ((_b = col.features) === null || _b === void 0 ? void 0 : _b.sortable) != null;
            })
                .map(function (col) { return [col.code, col]; }));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return layeredSort(tableDataSource, function (x, y) {
                // 对dataSource进行排序
                for (var _i = 0, sorts_1 = sorts; _i < sorts_1.length; _i++) {
                    var _a = sorts_1[_i], code = _a.code, order = _a.order;
                    // 遍历排序字段列表
                    var column = sortColumnsMap.get(code); // 获取当前排序字段对应的列信息
                    // 如果 code 对应的 column 不可排序，我们跳过该 code
                    if (column == null) {
                        // 如果当前排序字段对应的列信息不存在，则跳过该排序字段
                        continue;
                    }
                    var sortable = column.features.sortable; // 获取当前排序字段对应的列信息的sortable属性
                    var compareFn = typeof sortable === 'function' ? sortable : smartCompare;
                    var xValue = safeGetValue(column, x, -1);
                    var yValue = safeGetValue(column, y, -1);
                    var cmp = compareFn(xValue, yValue, x, y);
                    if (cmp !== 0) {
                        return cmp * (order === 'asc' ? 1 : -1);
                    }
                }
                return 0;
            });
        }
        // 在「升序 - 降序 - 不排序」之间不断切换
        function toggle(code) {
            var newSort = sortMap.get(code);
            if (newSort == null) {
                onChangeSorts(sorts.concat([{ code: code, order: orders[0] }]));
            }
            else {
                var index = sorts.findIndex(function (s) { return s.code === code; });
                var nextSorts = sorts.slice(0, index + 1);
                var nextOrder = getNextOrder(newSort.order);
                if (nextOrder === 'none') {
                    nextSorts.pop();
                }
                else {
                    nextSorts[index] = __assign(__assign({}, nextSorts[index]), { order: nextOrder });
                }
                onChangeSorts(nextSorts);
            }
        }
        function processColumns(tableColumns) {
            return tableColumns.map(dfs);
            function dfs(col) {
                var _a;
                var result = __assign({}, col);
                var sortable = col.code && (((_a = col.features) === null || _a === void 0 ? void 0 : _a.sortable) || sortMap.has(col.code));
                var active = sortable && sortMap.has(col === null || col === void 0 ? void 0 : col.code);
                if (sortable) {
                    var sortIndex = -1;
                    var sortOrder = 'none';
                    if (active) {
                        var _b = sortMap.get(col === null || col === void 0 ? void 0 : col.code) || {}, order = _b.order, index = _b.index;
                        sortOrder = order;
                        sortIndex = index;
                        if (highlightColumnWhenActive) {
                            // @ts-ignore
                            result.headerCellProps = mergeCellProps(col.headerCellProps, {
                                style: { background: 'var(--header-highlight-bgcolor)' },
                            });
                            result.getCellProps = function (value, row, rowIndex) {
                                var prevCellProps = safeGetCellProps(col, row, rowIndex);
                                return mergeCellProps(prevCellProps, {
                                    style: { background: 'var(--highlight-bgcolor)' },
                                });
                            };
                        }
                    }
                    result.title = (React.createElement(SortHeaderCell, { clickArea: clickArea, onToggle: function (e) {
                            if (stopClickEventPropagation) {
                                e.stopPropagation();
                            }
                            toggle(col === null || col === void 0 ? void 0 : col.code);
                        }, sortOrder: sortOrder, column: col, sortIndex: sortIndex, 
                        // @ts-ignore
                        sortOptions: sortOptions }, safeRenderHeader(col)));
                    // @ts-ignore
                    if ((result === null || result === void 0 ? void 0 : result.width) > 0) {
                        // @ts-ignore
                        result.width += SortHeaderIconWidth + 2;
                    }
                }
                if (!isLeafNode(col)) {
                    result.children = col.children.map(dfs);
                }
                return result;
            }
        }
        function getNextOrder(order) {
            var idx = orders.indexOf(order);
            return orders[idx === orders.length - 1 ? 0 : idx + 1];
        }
    };
}

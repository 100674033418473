import { __assign } from "tslib";
/**
 * 该文件对外导出了一个对象，该对象包含了show和hide两个方法，分别用于显示和隐藏下拉框。
 * show方法接收三个参数：事件对象、下拉框内容和配置项。
 * 其中配置项包括偏移量、Balloon.Inner的props、是否与目标元素对齐、三角形高度、Balloon.Inner的容器、z-index、下拉框底部、是否有遮罩、transform、目标元素、点击遮罩是否关闭下拉框、点击遮罩的回调和是否可见。
 * hide方法用于隐藏下拉框。此外，该文件还定义了下拉框的id、位置和容器，以及一个防止多次点击的锁和一个下拉框容器组件。
 */
import * as React from 'react';
import ReactDOM from 'react-dom';
import isFunction from 'lodash/isFunction';
import { Balloon } from '@/components/fusion';
import './index.scss';
// 定义下拉框的 id
var DropDown = 'cn-table-dropdown2';
// 获取下拉框的容器
var getContainer = function () {
    var container = document.getElementById(DropDown);
    if (!container) {
        container = document.createElement('div');
        container.id = DropDown;
        document.body.append(container);
    }
    return container;
};
// 定义锁，防止多次点击
var lock = false;
// 显示下拉框
var show = function (event, // 事件对象
content, // 下拉框内容
opts) {
    var _a, _b;
    if (lock || !content) {
        return;
    }
    lock = true;
    var container = getContainer();
    var _c = opts || {}, offset = _c.offset, props = _c.props, _d = _c.accordTarget, accordTarget = _d === void 0 ? true : _d, _e = _c.triangleHeight, triangleHeight = _e === void 0 ? 10 : _e, _f = _c.zIndex, zIndex = _f === void 0 ? 100000 : _f, footer = _c.footer, _g = _c.hasMask, hasMask = _g === void 0 ? true : _g, transform = _c.transform, target = _c.target, _h = _c.closeOnClick, closeOnClick = _h === void 0 ? true : _h, onMaskClick = _c.onMaskClick, _j = _c.isInVisible, isInVisible = _j === void 0 ? true : _j;
    var clientX = event.clientX;
    var targetDom = target !== null && target !== void 0 ? target : event === null || event === void 0 ? void 0 : event.target;
    var _k = isFunction(targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect)
        ? targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect()
        : {}, height = _k.height, left = _k.left, top = _k.top;
    var innerHeight = window.innerHeight, innerWidth = window.innerWidth;
    var isTopHalf = top < innerHeight / 2;
    var isLeftHalf = left < innerWidth / 2;
    var addVerOffset = (_a = offset === null || offset === void 0 ? void 0 : offset[0]) !== null && _a !== void 0 ? _a : 0;
    var addHozOffset = (_b = offset === null || offset === void 0 ? void 0 : offset[1]) !== null && _b !== void 0 ? _b : 0;
    var posTop = 0;
    posTop = isTopHalf
        ? top + height + triangleHeight + document.documentElement.scrollTop
        : top - triangleHeight + document.documentElement.scrollTop;
    var containerHeight = isTopHalf
        ? innerHeight - top - height - triangleHeight
        : top - triangleHeight;
    var pos = {
        top: isTopHalf ? posTop + addVerOffset : posTop - addVerOffset,
    };
    if (accordTarget) {
        pos[isLeftHalf ? 'left' : 'right'] = isLeftHalf
            ? left + addHozOffset
            : innerWidth - left - addHozOffset;
    }
    else {
        pos.left = clientX + addHozOffset;
    }
    var Container = (opts === null || opts === void 0 ? void 0 : opts.Container) || Balloon.Inner;
    var maskClick = function () {
        closeOnClick && hide();
        onMaskClick instanceof Function && onMaskClick();
    };
    var styleTransform = (function () {
        if (isFunction(transform)) {
            return transform({
                isTopHalf: isTopHalf,
                accordTarget: accordTarget,
            });
        }
        return "translate(".concat(accordTarget ? 0 : -50, "%, ").concat(isTopHalf ? 0 : '-100', "%)");
    })();
    ReactDOM.render(React.createElement(React.Fragment, null,
        React.createElement(Container, __assign({ onClick: function (containerEvent) {
                containerEvent.stopPropagation();
            }, closable: false, align: isTopHalf ? 'b' : 't' }, (props || {}), { style: __assign(__assign(__assign({ position: 'absolute', maxHeight: isInVisible ? containerHeight : 'auto', overflow: 'auto' }, pos), { zIndex: zIndex, transform: styleTransform }), ((props === null || props === void 0 ? void 0 : props.style) || {})) }),
            content,
            footer),
        hasMask && (React.createElement(DropDownContainer, { onClick: maskClick, style: { zIndex: zIndex - 1 } }))), container);
};
// 隐藏下拉框
function hide() {
    if (!lock) {
        return;
    }
    lock = false;
    var container = getContainer();
    ReactDOM.unmountComponentAtNode(container);
}
// 下拉框容器
function DropDownContainer(props) {
    return React.createElement("div", __assign({}, props, { className: "dropdown-container" }));
}
// 导出 show 和 hide 方法
export default {
    show: show,
    hide: hide,
};

import { __assign, __rest } from "tslib";
import './cn-dialog.scss';
import './index.scss';
import $i18n, { PandaConfigProvider } from 'panda-i18n';
import React from 'react';
import { Dialog } from '@/components/fusion';
import { CnButton } from '@/components/cn-button';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import classnames from 'classnames';
var getFooter = function (props) {
    var _a = props.footer, footer = _a === void 0 ? null : _a, okProps = props.okProps, cancelProps = props.cancelProps, onCancel = props.onCancel, onOk = props.onOk;
    if (footer === false)
        return false;
    var _b = okProps || {}, _c = _b.children, okChildren = _c === void 0 ? $i18n.get({
        id: 'Confirmation',
        dm: '确认',
        ns: 'CnDialog',
    }) : _c, _d = _b.visible, okVisible = _d === void 0 ? true : _d, restOkProps = __rest(_b, ["children", "visible"]);
    var _e = cancelProps || {}, _f = _e.children, cancelChildren = _f === void 0 ? $i18n.get({
        id: 'Cancel',
        dm: '取消',
        ns: 'CnDialog',
    }) : _f, _g = _e.visible, cancelVisible = _g === void 0 ? true : _g, restCancelProps = __rest(_e, ["children", "visible"]);
    var footerDom = (React.createElement(React.Fragment, null,
        footer,
        cancelVisible && (React.createElement(CnButton, __assign({ "data-hottag": "cn-ui.cn-dialog.cancel", className: "cn-dialog-button", onClick: onCancel, size: "medium" }, restCancelProps), cancelChildren)),
        okVisible && (React.createElement(CnButton, __assign({ "data-hottag": "cn-ui.cn-dialog.ok", className: "cn-dialog-button", type: "primary", size: "medium", onClick: onOk }, restOkProps), okChildren))));
    return footerDom;
};
// 尺寸规范
var SIZE = {
    autoLarge: '90%',
    large: '1100px',
    medium: '660px',
    small: '380px',
};
function CnDialog(props) {
    // 拆除不需要向下传递的props参数
    var _a = props.size, size = _a === void 0 ? 'medium' : _a, className = props.className, children = props.children, hideTitle = props.hideTitle, _b = props.centered, centered = _b === void 0 ? true : _b, _$i18n = props.$i18n, rest = __rest(props, ["size", "className", "children", "hideTitle", "centered", "$i18n"]);
    var cls = classnames(className, 'cn-next-dialog', 'cn-ui-dialog', {
        'cn-next-hide-title': hideTitle,
    });
    return withNativeProps(props, React.createElement(Dialog, __assign({ "data-name": "CnDialog", "data-testid": "CnDialog" }, rest, { v2: true, centered: centered, width: SIZE[size], className: cls, footer: getFooter(props) }),
        React.createElement(PandaConfigProvider, null, children)));
}
var footerActionProps = function (_a) {
    var okProps = _a.okProps, cancelProps = _a.cancelProps;
    return ({
        okProps: okProps || {
            children: $i18n.get({
                id: 'Confirmation',
                dm: '确认',
                ns: 'CnDialog',
            }),
        },
        cancelProps: cancelProps || {
            children: $i18n.get({
                id: 'Cancel',
                dm: '取消',
                ns: 'CnDialog',
            }),
        },
    });
};
CnDialog.success = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.success(__assign(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, prefix: 'cn-next-', className: cls }), footerActionProps({
        okProps: props.okProps,
        cancelProps: props.cancelProps,
    })));
};
CnDialog.warning = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.warning(__assign(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, prefix: 'cn-next-', className: cls }), footerActionProps({
        okProps: props.okProps,
        cancelProps: props.cancelProps,
    })));
};
CnDialog.error = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.error(__assign(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, prefix: 'cn-next-', className: cls }), footerActionProps({
        okProps: props.okProps,
        cancelProps: props.cancelProps,
    })));
};
CnDialog.notice = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.notice(__assign(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, prefix: 'cn-next-', className: cls }), footerActionProps({
        okProps: props.okProps,
        cancelProps: props.cancelProps,
    })));
};
CnDialog.help = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.help(__assign(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, prefix: 'cn-next-', className: cls }), footerActionProps({
        okProps: props.okProps,
        cancelProps: props.cancelProps,
    })));
};
CnDialog.alert = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.alert(__assign(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, prefix: 'cn-next-', className: cls }), footerActionProps({
        okProps: props.okProps,
        cancelProps: props.cancelProps,
    })));
};
CnDialog.confirm = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.confirm(__assign(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, className: cls, footerActions: ['cancel', 'ok'] }), footerActionProps({
        okProps: props.okProps,
        cancelProps: props.cancelProps,
    })));
};
CnDialog.show = function (props) {
    var _a = props.size, size = _a === void 0 ? 'small' : _a, className = props.className, onCancel = props.onCancel, _b = props.centered, centered = _b === void 0 ? true : _b;
    var cls = classnames(size, className, 'cn-next-quick-dialog');
    // 点击取消自动关闭弹窗功能
    var handleCancel = function () {
        instance.hide();
        onCancel && onCancel();
    };
    var instance = Dialog.show(__assign(__assign({ width: SIZE[size] }, props), { v2: true, centered: centered, className: cls, footer: getFooter(__assign(__assign({}, props), { onCancel: handleCancel })) }));
    return instance;
};
CnDialog.Inner = Dialog.Inner;
CnDialog.defaultProps = {
    size: 'medium',
    centered: true,
    footerActions: ['cancel', 'ok'],
};
export default CnDialog;

import { __assign } from "tslib";
// 导入 isFunction 工具函数
import { isFunction } from './common';
// 判断节点是否为叶子节点
export function isLeafNode(node) {
    // 如果节点没有子节点或子节点长度为0，返回 true，表示是叶子节点
    return node.children == null || node.children.length === 0;
}
// 遍历所有节点，并将节点收集到一个数组中
// order 参数可用于指定遍历规则：
// * `pre` 前序遍历 （默认）
// * `post` 后续遍历
// * `leaf-only` 忽略内部节点，只收集叶子节点
export function collectNodes(nodes, order) {
    if (order === void 0) { order = 'pre'; }
    // 初始化结果数组
    var result = [];
    // 调用深度优先搜索函数
    dfs(nodes);
    // 返回结果数组
    return result;
    // 深度优先搜索函数
    function dfs(dfsNodes) {
        // 如果节点为空，直接返回
        if (dfsNodes == null) {
            return;
        }
        // 遍历节点
        for (var _i = 0, dfsNodes_1 = dfsNodes; _i < dfsNodes_1.length; _i++) {
            var node = dfsNodes_1[_i];
            // 如果是叶子节点
            if (isLeafNode(node)) {
                // 将节点添加到结果数组中
                result.push(node);
            }
            else if (order === 'pre') {
                // 如果是前序遍历
                // 将节点添加到结果数组中
                result.push(node);
                // 递归遍历子节点
                dfs(node.children);
            }
            else if (order === 'post') {
                // 如果是后序遍历
                // 递归遍历子节点
                dfs(node.children);
                // 将节点添加到结果数组中
                result.push(node);
            }
            else {
                // 如果是只收集叶子节点
                // 递归遍历子节点
                dfs(node.children);
            }
        }
    }
}
// 对树状结构的数据进行排序
// layeredSort 是一个递归的过程，针对树上的每一个父节点，该函数都会重新对其子节点数组（children) 进行排序
export function layeredSort(array, compare) {
    // 调用递归函数
    return dfs(array);
    // 递归函数
    function dfs(rows) {
        // 如果不是数组，直接返回
        if (!Array.isArray(array)) {
            return array;
        }
        // 遍历数组，递归处理子节点，并对结果进行排序
        return rows
            .map(function (row) {
            // 如果是叶子节点，直接返回
            if (isLeafNode(row)) {
                return row;
            }
            // 否则，递归处理子节点，并返回新的节点对象
            return __assign(__assign({}, row), { children: dfs(row.children) });
        })
            .sort(compare);
    }
}
// 获取符合条件的父节点
export function getParent(dom, match, maxLevel) {
    // 如果 dom 存在，match 是一个函数，并且 maxLevel 大于 0
    if (dom && isFunction(match) && maxLevel > 0) {
        var level = 0;
        var nowDom = dom;
        // 当层级小于 maxLevel 时
        while (level < maxLevel) {
            // 获取父元素
            nowDom = nowDom === null || nowDom === void 0 ? void 0 : nowDom.parentElement;
            // 如果符合条件，返回该父元素
            if (match(nowDom)) {
                return nowDom;
            }
            // 层级加 1
            level++;
        }
    }
    // 如果没有找到符合条件的父元素，返回原始 dom
    return dom;
}

import { __assign } from "tslib";
import { useFormLayout } from '@/form/cn-form-layout/hooks';
export var useFormGridStyle = function (props) {
    var cols = props.cols, style = props.style, rowGap = props.rowGap, columnGap = props.columnGap;
    var layoutMode = useFormLayout().layoutMode;
    var isSingleLayout = layoutMode === 'single-col' || layoutMode === 'single-left';
    if (isSingleLayout) {
        return style;
    }
    var newStyle = __assign(__assign({ gridTemplateColumns: "repeat(".concat(cols, ", minmax(0, 1fr) [col-start])") }, style), { visibility: cols === undefined ? 'hidden' : 'visible' });
    if (typeof rowGap === 'number') {
        newStyle['--cn-form_row-gap'] = "".concat(rowGap, "px");
    }
    if (typeof columnGap === 'number') {
        newStyle['--cn-form_column-gap'] = "".concat(columnGap, "px");
    }
    return newStyle;
};

import { useFormLayout } from '@/form/cn-form-layout/hooks';
export var useFormResponsive = function (props) {
    var layoutMode = useFormLayout().layoutMode;
    if (layoutMode === 'single-col' || layoutMode === 'single-left') {
        return false;
    }
    if (props.cols) {
        return false;
    }
    if (typeof props.responsive === 'boolean') {
        return props.responsive;
    }
    return true;
};

import { __assign, __awaiter, __generator, __rest } from "tslib";
import React, { useContext, createContext, useRef, useState } from 'react';
import { fileValidator } from './upload/file';
import { useControlled } from '../../hooks/use-controlled';
import { CN_UPLOAD_LIMIT_NUM } from './constant';
var CnUploadStateContext = createContext({});
export var useUploadState = function () {
    return useContext(CnUploadStateContext);
};
export var CnUploadContext = function (props) {
    var defaultValueProps = props.defaultValue, valueProps = props.value, onChangeProps = props.onChange, onError = props.onError, onRemove = props.onRemove, _a = props.limit, limit = _a === void 0 ? CN_UPLOAD_LIMIT_NUM : _a, _b = props.readOnly, readOnly = _b === void 0 ? false : _b, children = props.children, useDetailValue = props.useDetailValue, restProps = __rest(props, ["defaultValue", "value", "onChange", "onError", "onRemove", "limit", "readOnly", "children", "useDetailValue"]);
    // 管理请求实例，当删除正在上传中的文件时，结束请求
    var uploadReqListRef = useRef({});
    // 管理未上传完成的文件列表状态
    var _c = useState([]), innerValue = _c[0], setInnerValue = _c[1];
    var innerValueRef = useRef([]);
    innerValueRef.current = innerValue || [];
    // 管理已上传完成的文件列表状态
    var _d = useControlled(props), outerValue = _d[0], setOuterValue = _d[1];
    // 由于已上传完成的状态外部管理，未完成的状态内部管理，无法保证顺序，故通过临时状态存储用户所选文件顺序
    var fileSortRef = useRef((outerValue === null || outerValue === void 0 ? void 0 : outerValue.map(function (item) { return item.key; })) || []);
    // 通过借助 ref 临时存储文件完成文件合并
    var outerValueRef = useRef(outerValue || []);
    outerValueRef.current = outerValue || [];
    var fileList = innerValueRef.current.filter(function (item) {
        var hasUploaded = outerValueRef.current.some(function (_a) {
            var key = _a.key;
            return item.key === key;
        });
        return !hasUploaded;
    });
    if (fileList.length !== innerValueRef.current.length) {
        setInnerValue(fileList);
    }
    /**
     * 合并内部未完成的文件状态和外部已完成上传的文件状态
     */
    var getValue = function () {
        var list = outerValueRef.current.map(function (item) { return item; });
        innerValueRef.current.forEach(function (item) {
            if (list.some(function (_a) {
                var key = _a.key;
                return key && key === item.key;
            }))
                return;
            list.push(item);
        });
        return list.sort(function (a, b) {
            var aIndex = fileSortRef.current.findIndex(function (item) {
                if (typeof item === 'string')
                    return item === a.key;
                return item === a.originFile;
            });
            var bIndex = fileSortRef.current.findIndex(function (item) {
                if (typeof item === 'string')
                    return item === b.key;
                return item === b.originFile;
            });
            return aIndex - bIndex;
        });
    };
    var _onChange = function (newFileList, currentFile) {
        // 当文件上传完成、或文件被删除时，同步更新内部状态
        if (currentFile.status === 'done' || currentFile.status === 'remove') {
            setInnerValue(function (prev) {
                return prev.filter(function (item) { return item.key !== currentFile.key; });
            });
        }
        if (!newFileList.length) {
            setOuterValue(newFileList);
            return;
        }
        if (useDetailValue) {
            setOuterValue(newFileList, currentFile);
            return;
        }
        /**
         * useDetailValue === false 场景只暴露必要的数据
         */
        var _fileList = newFileList.map(function (item) {
            return {
                key: item.key,
                name: item.name,
                size: item.size,
                url: item.url,
                status: item.status,
            };
        });
        setOuterValue(_fileList, currentFile);
    };
    var limited = getValue().length >= limit;
    return (React.createElement(CnUploadStateContext.Provider, { value: {
            props: __assign(__assign({}, restProps), { limit: limit }),
            readOnly: readOnly,
            getValue: getValue,
            onUploadInit: function (_fileList) {
                var uploadFileList = innerValueRef.current.map(function (item) { return item; });
                _fileList.forEach(function (item) {
                    var _a;
                    uploadFileList.push(item);
                    fileSortRef.current.push(item.originFile);
                    (_a = restProps.onProgress) === null || _a === void 0 ? void 0 : _a.call(restProps, item);
                });
                setInnerValue(uploadFileList);
            },
            onUploadProcess: function (currentFile) {
                var _a;
                var uploadFileList = innerValueRef.current.map(function (item) { return item; });
                var fileIndex = uploadFileList.findIndex(function (file) {
                    if (file.key)
                        return file.key === currentFile.key;
                    return file.originFile === currentFile.originFile;
                });
                if (fileIndex >= 0) {
                    uploadFileList[fileIndex] = currentFile;
                }
                else {
                    uploadFileList.push(currentFile);
                }
                (_a = restProps.onProgress) === null || _a === void 0 ? void 0 : _a.call(restProps, currentFile);
                setInnerValue(uploadFileList);
            },
            onUploadSuccess: function (currentFile) {
                var _a;
                fileSortRef.current = fileSortRef.current.map(function (item) {
                    if (item !== currentFile.originFile)
                        return item;
                    return currentFile.response.key;
                });
                innerValueRef.current = innerValueRef.current.filter(function (item) { return item.key !== currentFile.key; });
                var uploadFileList = outerValueRef.current.map(function (item) { return item; });
                uploadFileList.push(currentFile);
                (_a = restProps.onProgress) === null || _a === void 0 ? void 0 : _a.call(restProps, currentFile);
                _onChange(uploadFileList, currentFile);
            },
            limited: limited,
            fileValidator: fileValidator,
            onRemove: function (_currentFile) { return __awaiter(void 0, void 0, void 0, function () {
                var currentFile, result, uploadFileList;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            currentFile = __assign(__assign({}, _currentFile), { status: 'remove' });
                            (_a = uploadReqListRef.current[currentFile.key]) === null || _a === void 0 ? void 0 : _a.abort();
                            delete uploadReqListRef.current[currentFile.key];
                            if (!onRemove) return [3 /*break*/, 2];
                            return [4 /*yield*/, onRemove(currentFile)];
                        case 1:
                            result = _b.sent();
                            if (result === false)
                                return [2 /*return*/];
                            _b.label = 2;
                        case 2:
                            uploadFileList = outerValueRef.current.filter(function (item) { return item.key !== currentFile.key; });
                            _onChange(uploadFileList, currentFile);
                            return [2 /*return*/];
                    }
                });
            }); },
            onError: function (currentFile) {
                delete uploadReqListRef.current[currentFile.key];
                onError === null || onError === void 0 ? void 0 : onError(currentFile);
                var uploadFileList = innerValueRef.current.filter(function (item) {
                    return currentFile.key
                        ? item.key !== currentFile.key
                        : item.originFile !== currentFile.originFile;
                });
                setInnerValue(uploadFileList);
            },
            setUploadReq: function (key, reqInstance) {
                uploadReqListRef.current[key] = reqInstance;
            },
        } }, children));
};

import './sub-card.scss';
import React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export function CnCardSubCard(props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, _b = props.subTitle, subTitle = _b === void 0 ? '' : _b, subDesc = props.subDesc, action = props.action, children = props.children, style = props.style;
    var actionDom = action;
    if (isFunction(action)) {
        // @ts-ignore
        actionDom = action();
    }
    return withNativeProps(props, React.createElement("div", { className: classNames(['cn-ui-card-sub-card', className]), style: style },
        subTitle && (React.createElement("div", { className: "cn-ui-card-sub-card-header" },
            React.createElement("div", { className: "cn-ui-card-sub-card-header-header" }, subTitle),
            React.createElement("div", { className: "cn-ui-card-sub-card-header-footer" }, actionDom))),
        subDesc && React.createElement("div", { className: "cn-ui-card-sub-card-desc" }, subDesc),
        children && (React.createElement("div", { className: "cn-ui-card-sub-card-content" }, children))));
}
CnCardSubCard.displayName = 'CnCardSubCard';

import { __assign, __extends } from "tslib";
// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { polyfill } from 'react-lifecycles-compat';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import Icon from '@fusion/lib/icon';
import Animate from '@fusion/lib/animate';
import { obj } from '@fusion/lib/util';
var noop = function () { };
/**
 * Message
 */
var Message = /** @class */ (function (_super) {
    __extends(Message, _super);
    function Message() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            visible: typeof _this.props.visible === 'undefined'
                ? _this.props.defaultVisible
                : _this.props.visible,
        };
        _this.onClose = function () {
            if (!('visible' in _this.props)) {
                _this.setState({
                    visible: false,
                });
            }
            _this.props.onClose(false);
        };
        return _this;
    }
    Message.getDerivedStateFromProps = function (props) {
        if ('visible' in props) {
            return {
                visible: props.visible,
            };
        }
        return {};
    };
    Message.prototype.render = function () {
        var _a;
        /* eslint-disable no-unused-vars */
        var _b = this.props, prefix = _b.prefix, className = _b.className, style = _b.style, type = _b.type, shape = _b.shape, size = _b.size, title = _b.title, children = _b.children, icon = _b.iconType, closeable = _b.closeable, afterClose = _b.afterClose, animation = _b.animation, rtl = _b.rtl, locale = _b.locale;
        var others = __assign({}, obj.pickOthers(Object.keys(Message.propTypes), this.props));
        /* eslint-enable */
        var visible = this.state.visible;
        var messagePrefix = "".concat(prefix, "message");
        var classes = classNames(CN_UI_HASH_CLASS_NAME, messagePrefix, className, (_a = {},
            _a["".concat(prefix, "message-").concat(type)] = type,
            _a["".concat(prefix).concat(shape)] = shape,
            _a["".concat(prefix).concat(size)] = size,
            _a["".concat(prefix, "title-content")] = !!title,
            _a["".concat(prefix, "only-content")] = !title && !!children,
            _a));
        var renderIcon = function () {
            if (icon === false) {
                return null;
            }
            if (icon) {
                return (React.createElement(CnIcon, { outerClassName: "".concat(messagePrefix, "-symbol"), type: icon }));
            }
            return (React.createElement(Icon, { className: "".concat(messagePrefix, "-symbol ").concat(messagePrefix, "-symbol-icon") }));
        };
        // 处理换行逻辑
        var wrapChildren = children;
        if (wrapChildren && typeof wrapChildren === 'string') {
            wrapChildren = (React.createElement(React.Fragment, null, wrapChildren.split('\n').map(function (text, i) {
                return i === 0 ? (text) : (React.createElement(React.Fragment, null,
                    React.createElement("br", null),
                    text));
            })));
        }
        var newChildren = visible ? (React.createElement("div", __assign({ role: "alert", style: style }, others, { className: classes, dir: rtl ? 'rtl' : undefined }),
            closeable ? (React.createElement("a", { role: "button", "aria-label": locale === null || locale === void 0 ? void 0 : locale.closeAriaLabel, className: "".concat(messagePrefix, "-close"), onClick: this.onClose },
                React.createElement(CnIcon, { type: "close" }))) : null,
            renderIcon(),
            title ? React.createElement("div", { className: "".concat(messagePrefix, "-title") }, title) : null,
            wrapChildren ? (React.createElement("div", { className: "".concat(messagePrefix, "-content") }, wrapChildren)) : null)) : null;
        if (animation) {
            return (React.createElement(Animate.Expand, { animationAppear: false, afterLeave: afterClose }, newChildren));
        }
        return newChildren;
    };
    Message.propTypes = {
        prefix: PropTypes.string,
        pure: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.object,
        /**
         * 反馈类型
         */
        type: PropTypes.oneOf([
            'success',
            'warning',
            'error',
            'notice',
            'help',
            'loading',
        ]),
        /**
         * 反馈外观
         */
        shape: PropTypes.oneOf(['inline', 'addon', 'toast']),
        /**
         * 反馈大小
         */
        size: PropTypes.oneOf(['medium', 'large']),
        /**
         * 标题
         */
        title: PropTypes.node,
        /**
         * 内容
         */
        children: PropTypes.node,
        /**
         * 默认是否显示
         */
        defaultVisible: PropTypes.bool,
        /**
         * 当前是否显示
         */
        visible: PropTypes.bool,
        /**
         * 显示的图标类型，会覆盖内部设置的IconType，传false不显示图标
         */
        iconType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        /**
         * 显示关闭按钮
         */
        closeable: PropTypes.bool,
        /**
         * 关闭按钮的回调
         */
        onClose: PropTypes.func,
        /**
         * 关闭之后调用的函数
         */
        afterClose: PropTypes.func,
        /**
         * 是否开启展开收起动画
         */
        animation: PropTypes.bool,
        locale: PropTypes.object,
        rtl: PropTypes.bool,
    };
    Message.defaultProps = {
        prefix: 'cn-next-',
        pure: false,
        type: 'success',
        shape: 'inline',
        defaultVisible: true,
        closeable: false,
        onClose: noop,
        afterClose: noop,
        animation: true,
        locale: {},
    };
    return Message;
}(Component));
export default polyfill(Message);

import $i18n from 'panda-i18n';
import React from 'react';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CnBox } from '@/components/cn-box';
import { CnIcon } from '@/components/cn-icon';
import { CnLoading } from '@/components/cn-loading';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnImageViewer } from '@/components/cn-image-viewer';
import { useShowUploadRemoveIcon, useShowUploadPreviewIcon, useUploadState, getFileName, calcFileSize, useShowUploadDownloadIcon, } from '@cainiaofe/cn-ui-common';
import { download } from '@cainiaofe/cn-utils';
export var MiniUploadListItem = function (props) {
    var _a = useUploadState(), rootProps = _a.props, readOnly = _a.readOnly, onRemove = _a.onRemove;
    var file = props.file, fileList = props.fileList;
    var disabled = rootProps.disabled;
    var status = file.status;
    var isUploading = status && ['idle', 'uploading'].includes(status);
    var renderFileFooter = function () {
        if (file.size)
            return calcFileSize(file.size);
        return null;
    };
    var showDownload = useShowUploadDownloadIcon({
        showDownload: !disabled && rootProps.showDownload,
        file: file,
    });
    var onDownload = function () {
        if (rootProps.onDownload)
            rootProps.onDownload(file);
        else
            download(file.url, file.name);
    };
    var showRemove = useShowUploadRemoveIcon({
        showRemove: !disabled && !readOnly && rootProps.showRemove,
        file: file,
    });
    var showPreview = useShowUploadPreviewIcon({
        showPreview: rootProps.showPreview,
        onPreview: rootProps.onPreview,
        file: file,
    });
    var onPreview = function (_file) {
        if (!showPreview)
            return;
        if (rootProps.onPreview) {
            rootProps.onPreview(_file);
            return;
        }
        CnImageViewer.open({
            value: _file.objectUrl || _file.url,
            src: fileList.map(function (item) {
                return item.objectUrl || item.url;
            }),
        });
    };
    return (React.createElement("div", { className: "cn-ui-oss-upload-list-item", onClick: function () { return onPreview(file); } },
        React.createElement("div", { className: "cn-ui-oss-upload-list-item-header" }, isUploading ? (React.createElement(CnLoading, { className: "cn-ui-oss-upload-list-loading", size: "small" })) : (React.createElement("div", { key: file.key || file.name || file.url, className: "cn-ui-oss-upload-list-file-ext" },
            React.createElement("img", { src: file.url })))),
        React.createElement("div", { className: "cn-ui-oss-upload-list-item-info" },
            React.createElement(CnEllipsis, { className: "cn-ui-oss-upload-list-item-info-file-name", ellipsisPosition: "middle", endCharCount: 4 }, getFileName(file)),
            React.createElement("div", { className: "cn-ui-oss-upload-list-item-info-footer" }, renderFileFooter())),
        React.createElement(CnBox, { direction: "row", spacing: 4, align: "center", className: "cn-ui-oss-upload-list-item-actions" },
            showPreview ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onPreview(file);
                }, className: "cn-ui-oss-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "eye", size: 14 }) }, $i18n.get({
                    id: 'Preview',
                    dm: '预览',
                    ns: 'CnOssImageUpload',
                })))) : null,
            showDownload ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onDownload();
                }, className: "cn-ui-oss-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "download", size: 14 }) }, $i18n.get({
                    id: 'Download',
                    dm: '下载',
                    ns: 'CnOssImageUpload',
                })))) : null,
            showRemove ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onRemove(file);
                }, className: "cn-ui-oss-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "close", size: 14 }) }, $i18n.get({
                    id: 'TERM.Delete',
                    dm: '删除',
                    ns: 'CnOssImageUpload',
                })))) : null)));
};

import React from 'react';
import { Tag } from '@/components/fusion';
import { CnTooltip } from '@/components/cn-tooltip';
export var TagItem = function (_a) {
    var item = _a.item, handleClose = _a.handleClose;
    var _b = React.useState(false), isOverflow = _b[0], setIsOverflow = _b[1];
    var tagRef = React.useRef(null);
    var isStringText = typeof item.text === 'string';
    React.useEffect(function () {
        if (!isStringText || !tagRef || !tagRef.current)
            return;
        var tagBody;
        try {
            tagBody = tagRef.current
                .getInstance()
                ._reactInternalFiber.child.stateNode.getInstance()
                .tagNode.querySelector('.cn-next-tag-body');
        }
        catch (e) { /* empty */ }
        if (!tagBody)
            return;
        setIsOverflow(tagBody.scrollWidth > tagBody.offsetWidth);
    }, [item]);
    if (!item.text)
        return null;
    if (isStringText && isOverflow) {
        return (React.createElement(CnTooltip, { v2: true, trigger: React.createElement(Tag.Closeable, { ref: tagRef, key: item.key, type: "primary", onClose: function () { return handleClose(item); } }, item.text) }, item.text));
    }
    return (React.createElement(Tag.Closeable, { ref: tagRef, key: item.key, type: "primary", onClose: function () { return handleClose(item); } }, item.text));
};
